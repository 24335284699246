import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import useRolesAndPrivileges from "hooks/useRolesAndPermissions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { changeClaimStatus } from "services/api/change-claim-status-api-service";
import { presentClaimStage } from "store/claim-details/claim-details-selectors";
import PageAccessDenied from "ui/components/page-access-denied/page-access-denied.component";
import SnackbarMessage from "ui/components/utils/alert-message.component";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import { CLAIM_CHANGE_STAGE_PAGE_ACCESS } from "ui/components/utils/role-constants";

function ChangeClaimStatus() {
  const dispatch = useDispatch();
  const roleAndPrivilageMap = useRolesAndPrivileges();
  const [loading, setLoading] = useState(true);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({ severity: "info", message: "" });

  const params = useParams();
  const claimId = params.claimId;

  console.log("Change claim status params  ", params);
  console.log("Claim id  ", params.claimId);
  const stageList = useSelector((state) => state.configSlice.stages);
  const [userHasWriteAccess, setUserHasWriteAccess] = useState(false);

  const [errorStage, setErrorStage] = useState(false);
  const [stage, setStage] = useState({ id: "", name: "" });

  const [errorRemark, setErrorRemark] = useState(false);
  const [remarks, setRemarks] = useState("");

  // LOADING STATE FOR API CALLS
  const changeClaimStatusLoading = useSelector(
    (state) => state.changeClaimStatusSlice.loading
  );

  const currentClaimStageData = useSelector(presentClaimStage);

  console.log("stageList ", stageList);
  console.log("claimId ", claimId);
  console.log("changeClaimStatusLoading ", changeClaimStatusLoading);

  const changeStageAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIM_CHANGE_STAGE_PAGE_ACCESS
  );

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    console.log("changeStageAccessMap ", changeStageAccessMap);

    if (changeStageAccessMap.size > 0) {
      if (changeStageAccessMap.get("WRITE") === true) {
        setUserHasWriteAccess(true);
      }
    }
    setLoading(false);
  }, [changeStageAccessMap]);

  function displayAlert(type, message) {
    setAlertData((prevState) => ({
      ...prevState,
      severity: type,
      message: message,
    }));
    setAlertOpen(true);
  }

  const onCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleSubmit = async (e) => {
    console.log("handleSubmit", stage);
    console.log("handleSubmit", remarks);

    if (stage.id === "" || stage.id === undefined) {
      setErrorStage(true);
    } else if (remarks === "") {
      setErrorStage(false);
      setErrorRemark(true);
    } else {
      setErrorStage(false);
      setErrorRemark(false);
      changeClaimStatusAPI();
    }
  };

  // SHOW LOADERS FOR API CALL
  useEffect(() => {
    if (changeClaimStatusLoading) {
      setBackdropOpen(true);
    } else {
      setBackdropOpen(false);
    }
  }, [changeClaimStatusLoading]);

  // fetch templates API
  const changeClaimStatusAPI = async () => {
    try {
      console.log("changeClaimStatusAPI: ", claimId);

      const requestBody = {
        stage: stage,
        remarks: remarks,
      };

      console.log("changeClaimStatusAPI: ", requestBody);

      const data = { claimId, requestBody };
      const response = await dispatch(changeClaimStatus(data));
      const responsePayload = response.payload;

      console.log("changeClaimStatusAPI response ", responsePayload);

      if (responsePayload.status === 200) {
        displayAlert("success", "Claim status changed succesfully");
        // 750 msecond delay before reloading the page
        setTimeout(() => {
          window.location.reload();
        }, 750);
      } else {
        displayAlert(
          "error",
          "Unable to change claim status, pls try again later"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (
    currentClaimStageData === "Closure" ||
    currentClaimStageData === "Closed"
  ) {
    return (
      <Box sx={{ m: 2 }}>
        <Typography variant="body1">
          This claim has already been closed; you cannot change the status.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {/* LOADER */}
      <CommonBackDrop backdropOpen={backdropOpen} />

      {alertOpen && (
        <SnackbarMessage
          severity={alertData.severity}
          message={alertData.message}
          onOpen={alertOpen}
          onCloseHandler={onCloseHandler}
          vertical="top"
          horizontal="center"
        />
      )}

      {userHasWriteAccess ? (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormControl fullWidth error={errorStage}>
                <InputLabel id="comm-details--modeofcommunication__inputlabel">
                  Select Status
                </InputLabel>
                <Select
                  labelId="comm-details--modeofcommunication__selectlabel"
                  id="comm-details--modeofcommunication__select"
                  name="commMode"
                  label="Type of Incident"
                  // value={stage}
                  value={stage?.id || ""}
                  onChange={(e, key) => {
                    const data = {
                      id: key.props.id,
                      name: key.props.children,
                    };
                    setStage(data);
                  }}
                >
                  {stageList.map((item) => (
                    <MenuItem key={item.id} value={item.id} id={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errorStage ? "Select Status" : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errorRemark}
                helperText={errorRemark ? "Enter reason for change" : ""}
                id="agentnotes__textfield"
                label="Reason for Change"
                name="agentRemark"
                multiline
                rows={4}
                fullWidth
                value={remarks}
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                onClick={handleSubmit}
                variant="contained"
                size="big"
                sx={{ p: 2, minWidth: 200 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <PageAccessDenied />
      )}
    </>
  );
}

export default ChangeClaimStatus;
