// CLAIMS LIST PAGE
export const CLAIMS_SEARCH_ACCESS = "claims.search.*";
export const CLAIMS_FILTER_ACCESS = "claims.search.filter.*";
export const CLAIMS_DOWNLOAD_ACCESS = "claims.search.download.*";
export const CLAIMS_ASSIGN_ACCESS = "claims.search.assign.*";

export const CLAIM_PAGE_ACCESS = "claims.stage.*";
export const CLAIM_SEARCH_PAGE_ACCESS = "claims.stage.search.*";
export const CLAIM_FILING_PAGE_ACCESS = "claims.stage.create.*";

export const CLAIM_DOCUMENT_PAGE_ACCESS = "claims.stage.document.*";
export const CLAIM_DOCUMENT_SAVE_ACCESS = "claims.stage.document.save.*";
export const CLAIM_DOCUMENT_UPLOAD_ACCESS = "claims.stage.document.upload.*";

export const CLAIM_VERIFICATION_PAGE_ACCESS = "claims.stage.verification.*";
export const CLAIM_VERIFICATION_ACCEPT_ACCESS =
  "claims.stage.verification.accept.*";
export const CLAIM_VERIFICATION_DENY_ACCESS =
  "claims.stage.verification.reject.*";

export const CLAIM_DECISION_PAGEACCESS = "claims.stage.decision.*";

export const CLAIM_COMMUNICATIONON_PAGE_ACCESS = "claims.stage.communication.*";
export const CLAIM_COMMUNICATIONON_SAVE_ACCESS =
  "claims.stage.communication.save.*";
export const CLAIM_COMMUNICATIONON_MESSAGE_ACCESS =
  "claims.stage.communication.message.*";

export const CLAIM_PAYOUT_PAGE_ACCESS = "claims.stage.payout.*";
export const CLAIM_PAYOUT_SAVE_ACCESS = "claims.stage.payout.save.*";
export const CLAIM_PAYOUT_UPLOAD_ACCESS = "claims.stage.payout.upload.*";

export const CLAIMS_CLOSURE_PAGE_ACCESS = "claims.stage.closure.*";

export const CLAIM_CHANGE_STAGE_PAGE_ACCESS = "claims.stage.change.*";
