import { createSlice } from "@reduxjs/toolkit";
import { fetchAgentDetails } from "../../services/api/searchclaims-api-service"; // Import the thunk

const searchClaimGetAgentDetails = createSlice({
  name: "getAgentDetailSlice",
  initialState: {
    agentDetailList: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgentDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAgentDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.agentDetailList = action.payload;
      })
      .addCase(fetchAgentDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default searchClaimGetAgentDetails.reducer;
