import { createSlice } from "@reduxjs/toolkit";
import { getCustomerDetailsAPI } from "../../services/api/search-customer-api-service"; // Import the thunk

const customerSlice = createSlice({
  name: "customerSlice",
  initialState: {
    customerList: null,
    selectedCustomer: null,
    incidentDate: null,
    loading: false,
    error: null,
  },
  reducers: {
    setCustomerList: (currentSlice, action) => {
      currentSlice.customerList = action.payload;
    },
    setSelectedCustomer: (currentSlice, action) => {
      currentSlice.selectedCustomer = action.payload;
    },
    setIncidentDateForCustData: (currentSlice, action) => {
      currentSlice.incidentDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerDetailsAPI.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCustomerDetailsAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.customerList = action.payload;
      })
      .addCase(getCustomerDetailsAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { setCustomerList, setSelectedCustomer, setIncidentDateForCustData } = customerSlice.actions;
export default customerSlice.reducer;
