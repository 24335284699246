import { Button } from "@mui/material";
import React from "react";

const ColumnIdRenderer = React.memo((props) => {
  const value = props.value;
  const rowData = props.data; // Access the rowData prop passed by AgGridReact

  console.log("ColumnIdRenderer value ", value);
  console.log("ColumnIdRenderer rowData", rowData.claim_unique_id);
  const handleClick = () => {
    // Call the onClaimIDClicked function passed as a parameter
    if (props.onClaimIDClicked) {
      // props.onClaimIDClicked(props.value);
      props.onClaimIDClicked(rowData.claim_unique_id);
    }
  };
  return (
    <div>
      <Button
        onClick={handleClick}
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          border: "none",
          background: "none",
          padding: 0,
          minWidth: 0,
          display: "inline",
        }}
      >
        {value}
      </Button>
    </div>
  );
});

export default ColumnIdRenderer;
