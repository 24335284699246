import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const getCommunicationDetails = createAsyncThunk(
  "communicationSlice/getCommunicationDetails",
  async (data, { rejectWithValue }) => {
    try {
      const { claimId } = data;
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/communication`,
      });
      console.log("getCommunicationDetails Service success", response);
      if (response.status === 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitCommunicationDetails = createAsyncThunk(
  "communicationSlice/submitCommunicationDetails",
  async (data, { rejectWithValue }) => {
    try {
      const { requestData, claimId } = data;
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/communication`,
        data: requestData,
      });
      console.log("submitCommunicationDetails Service success", response);
      if (response.status === 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCommunicationTemplate = createAsyncThunk(
  "communicationSlice/fetchCommunicationTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const { commChannelId, claimId } = data;
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/communication/templates/${commChannelId}`,
      });
      console.log("fetchConfigDetails Service success", response);
      if (response.status === 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const renderCommunicationTemplate = createAsyncThunk(
  "communicationSlice/rendererCommunicationTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const { requestData, claimId } = data;
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/communication/templates/render`,
        data: requestData,
      });
      console.log("renderCommunicationTemplate Service success", response);
      if (response.status === 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendCommunicationTemplate = createAsyncThunk(
  "communicationSlice/sendCommunicationTemplate",
  async (data, { rejectWithValue }) => {
    try {
      const { requestData, claimId } = data;
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/communication/templates/send`,
        data: requestData,
      });
      console.log("sendCommunicationTemplate Service success", response);
      if (response.status === 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
