import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const LoginRoute = ({ children }) => {
  const user = useSelector((store) => store.authSlice.user);
  return user && Object.keys(user).length !== 0 ? (
    <Navigate to="/" />
  ) : (
    children
  );
};

export default LoginRoute;
