import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const CommonBackDrop = ({ backdropOpen }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={backdropOpen}
    >
      <CircularProgress style={{ color: "white" }} />
    </Backdrop>
  );
};

export default CommonBackDrop;
