import { createSlice } from "@reduxjs/toolkit";
import { getSubscriptionDetailsAPI } from "services/api/search-subscriptions-api-service"; 

const subscriptionsSlice = createSlice({
  name: "subscriptionsSlice",
  initialState: {
    subscriptionsList: null,
    selectedSubscription: null,
    incidentDate: null,
    loading: false,
    error: null,
  },
  reducers: {
    setSubscriptionsList: (currentSlice, action) => {
      currentSlice.subscriptionsList = action.payload;
    },
    setSelectedSubscription: (currentSlice, action) => {
      currentSlice.selectedSubscription = action.payload;
    },
    setIncidentDateForSubData: (currentSlice, action) => {
      currentSlice.incidentDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionDetailsAPI.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubscriptionDetailsAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptionsList = action.payload;
      })
      .addCase(getSubscriptionDetailsAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { setSubscriptionsList, setSelectedSubscription, setIncidentDateForSubData } = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
