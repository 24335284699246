import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Paper,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchClaimList } from "services/api/searchclaims-api-service";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import SnackbarMessage from "ui/components/utils/alert-message.component";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({ severity: "info", message: "" });
  const [backdropOpen, setBackdropOpen] = useState(false);

  const [claimsCompleted, setClaimsCompleted] = useState(0);
  const [claimsPending, setClaimsPending] = useState(0);

  // CLAIM STATE
  const userDetails = useSelector((state) => state.authSlice.user);

  const searchClaimsError = useSelector(
    (state) => state.searchClaimSlice.error
  );
  const onCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  console.log("userDetails ", userDetails);

  const onPendingClick = () => {
    // navigate("/claims");
    navigate("/claims", {
      state: {
        user_id: userDetails?.id,
        user_name: userDetails?.name,
      },
    });
  };

  // Error handler
  useEffect(() => {
    if (searchClaimsError) {
      setAlertOpen(true);
      setAlertData((prevState) => ({
        ...prevState,
        severity: "error",
        message: "Something went wrong pls try again later",
      }));
    } else {
      setAlertOpen(false);
    }
  }, [searchClaimsError]);

  useEffect(() => {
    if (userDetails) {
      let page = 0;
      let size = 10;
      let officer = userDetails.id;
      var data = { page, size, officer };
      setBackdropOpen(true);
      dispatch(fetchSearchClaimList(data)).then((response) => {
        const searchList = response.payload;
        let searchDetailsResponse = searchList?.content;
        var claimsPendingList = [];
        var claimsCompletedList = [];
        if (searchDetailsResponse) {
          console.log("Dashboard API call ", searchDetailsResponse);

          searchDetailsResponse.forEach((element) => {
            console.log("element.status", element.claim.stage.id);
            if (element.claim.stage.id === "closed") {
              claimsCompletedList.push(element);
            } else {
              claimsPendingList.push(element);
            }
          });

          setClaimsCompleted(claimsCompletedList.length ?? 2);
          setClaimsPending(claimsPendingList.length ?? 0);
        }

        setBackdropOpen(false);
      });
    }
  }, [userDetails]);

  return (
    <div>
      {/* LOADER */}
      <CommonBackDrop backdropOpen={backdropOpen} />

      {/* SnackBar */}
      {alertOpen && (
        <SnackbarMessage
          severity={alertData.severity}
          message={alertData.message}
          onOpen={alertOpen}
          onCloseHandler={onCloseHandler}
          vertical="top"
          horizontal="center"
        />
      )}

      <Box minHeight="95vh" sx={{ bgcolor: "#f2f6fc" }}>
        <Typography
          sx={{ pl: 10, pr: 10, pt: 5 }}
          variant="h5"
          component="div"
          color="primary"
        >
          <strong>Dashboard</strong>
        </Typography>

        <Grid container spacing={5} sx={{ pl: 10, pr: 10, pt: 5 }}>
          <Grid item xs={12} md={6}>
            <Card
              onClick={onPendingClick}
              sx={{
                cursor: "pointer",
                transition: "background-color 0.3s",
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100px" // Adjust height as needed
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ color: "#FBB117", fontWeight: "bold" }}
                    >
                      {claimsPending}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      Claims Pending
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              onClick={onPendingClick}
              sx={{
                cursor: "pointer",
                transition: "background-color 0.3s",
              }}
            >
              <CardActionArea>
                <CardContent>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100px" // Adjust height as needed
                  >
                    <Typography
                      sx={{ fontWeight: "bold" }}
                      variant="h5"
                      component="div"
                      color="primary"
                    >
                      {claimsCompleted}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      Claims Processed
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Dashboard;
