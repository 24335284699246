import { createSlice } from "@reduxjs/toolkit"; // Import the thunk
import { getClaimDetailsAPI } from "services/api/getclaimdetails-api-service";

const claimDetailsSlice = createSlice({
  name: "claimDetailsSlice",
  initialState: {
    claimDetails: null,
    claimId: null,
    claimStage: null,
    loading: false,
    error: null,
  },
  reducers: {
    setClaimDetails: (currentSlice, action) => {
      currentSlice.claimDetails = action.payload;
      currentSlice.claimId = action.payload;
    },
    setClaimStage: (currentSlice, action) => {
      currentSlice.claimStage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClaimDetailsAPI.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getClaimDetailsAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.claimDetails = action.payload;
        state.claimId = action.payload.id;
        state.claimStage = action.payload.claim.stage.name;
      })
      .addCase(getClaimDetailsAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { setClaimDetails, setClaimStage } = claimDetailsSlice.actions;
export default claimDetailsSlice.reducer;
