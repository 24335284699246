import React from "react";
import { ShimmerTable } from "shimmer-effects-react";

function SubscriptionShimmer() {
  return (
    <div>
      <ShimmerTable
        loading={true}
        mode="light"
        row={7}
        col={5}
        border={1}
        borderColor={"#cbd5e1"}
        rounded={1}
        rowGap={16}
        colPadding={[10, 5, 10, 5]}
      >
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <tbody>
            {[...Array(7)].map((_, rowIndex) => (
              <tr key={rowIndex} style={{ height: "40px" }}>
                {[...Array(5)].map((_, colIndex) => (
                  <td
                    key={colIndex}
                    style={{
                      border: "1px solid #cbd5e1",
                      borderRadius: "0.25em",
                      padding: "10px 5px",
                      textAlign: "center",
                    }}
                  >
                    Col {colIndex}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </ShimmerTable>
    </div>
  );
}

export default SubscriptionShimmer;