import { format, parse } from "date-fns";
import moment from "moment";

export class FormatDateUtil {
  static init(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  static displayDate(date) {
    return moment(date).format("DD MMMM, YYYY");
  }

  static searchDisplayDate(date) {
    return parse(date, "yyyy-MM-dd", new Date());
  }

  static displayTimeStampAsDate(timestamp) {
    const date = new Date(timestamp);
    return format(date, "dd MMMM, yyyy");
  }

  static displayMonth(date) {
    return moment(date).format("MMMM");
  }
}
