// Function to make under score for spacing
export const getPreviousDate = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1); // Subtract one day

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // getMonth() returns month index (0-11)
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
