import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const fetchPolicyDetails = createAsyncThunk(
  "getPolicyDetailsSlice/fetchPolicyDetails",
  async (data, { rejectWithValue }) => {
    const { date } = data;
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/search/policy/${date}`,
      });
      console.log("fetchPolicyDetails Service success", response);
      return response.data;
    } catch (error) {
      console.log("fetchPolicyDetails Service error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
