import ClaimsClosure from "ui/layout/create-claim/components/claims-closure.components";
import ClaimsCommunication from "ui/layout/create-claim/components/claims-communication.components";
import ClaimsDecision from "ui/layout/create-claim/components/claims-decision.components";
import ClaimFiling from "ui/layout/create-claim/components/claims-filing.components";
import ClaimsPayout from "ui/layout/create-claim/components/claims-payout.components";
import UploadDocuments from "ui/layout/create-claim/components/upload-documents/upload-documents.components";
import VerifyDocuments from "ui/layout/create-claim/components/verify-documents.components";

export const ClaimStagesMapper = (stages) => {

    function getClassName(stageId) {
        if(stageId === "claim_filing"){
            return ClaimFiling
        } else if(stageId === "document_collection"){
            return UploadDocuments
        } else if(stageId === "verification"){
            return VerifyDocuments
        } else if(stageId === "decision"){
            return ClaimsDecision
        } else if(stageId === "communication"){
            return ClaimsCommunication
        } else if(stageId === "payout"){
            return ClaimsPayout
        } else if (stageId === "closure"){
            return ClaimsClosure;
        }
    }

    const claimStagesData = stages.map((stage) => ({
        id: stage.id,
        name: stage.name,
        description: stage.description,
        className: getClassName(stage.id)
    }));

    return claimStagesData;

}