import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const getVerificationTemplatesApiService = createAsyncThunk(
  "document/getVerificationTemplatesApiService",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/config/claim/verification/notes/templates`,
      });
      console.log("GetVerificationTemplatesApiService Service success", response);
      return response.data;
    } catch (error) {
      console.log("GetVerificationTemplatesApiService Service error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
