import { ChangeCircle, CheckCircle, ViewTimeline } from "@mui/icons-material";
import Route from "@mui/icons-material/Route";
import { Box, Card, Container, CssBaseline, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClaimDetailsAPI } from "services/api/getclaimdetails-api-service";
import { ClaimStagesMapper } from "services/mapper/claim-stages.mapper";
import { CustomerDetailsMapper } from "services/mapper/customer-details.mapper";
import { SubscriptionDetailsMapper } from "services/mapper/subscription-details.mapper";
import {
  setSelectedCustomer,
  setIncidentDateForCustData,
} from "store/customer/customer-slice";
import {
  setSelectedSubscription,
  setIncidentDateForSubData,
} from "store/subscriptions/subscriptions-slice";
import ClaimsPageHeader from "ui/components/breadcrumbs/claims-header.components";
import SnackbarMessage from "ui/components/utils/alert-message.component";
import Spacer from "ui/components/utils/spacer";
import VerticalLinearStepper from "ui/components/utils/vertical-stepper.component";
import ChangeClaimStatus from "./components/change-claim-status.component";
import StaticHeader from "./components/static-header.component";
import { MESSAGES } from "../../components/utils/message-constants";
import useRolesAndPrivileges from "hooks/useRolesAndPermissions";
import { changeClaimStatus } from "services/api/change-claim-status-api-service";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import { setClaimStage } from "store/claim-details/getclaim-details-slice";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2, pb: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    key: `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function iconProperties(stageCompleted) {
  if (stageCompleted) {
    return {
      icon: <CheckCircle color="primary" />,
      iconPosition: "start",
    };
  }
}

const CreateClaim = () => {
  const [value, setValue] = useState(0);
  const [vStepperActiveStep, setVStepperActiveStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleAndPrivilageMap = useRolesAndPrivileges();
  const [showLoader, setShowLoader] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({ severity: "info", message: "" });

  const [value1, setValue1] = useState(0);
  const [hStepperActiveStep, setHStepperActiveStep] = useState(0);
  const [currentClaimsData, setCurrentClaimsData] = useState("");

  /* DEFINE PROPERTIES FOR SETTING STAGE COMPLETED*/
  const [claimsFilingCompleted, setClaimsFilingCompleted] = useState(false);
  const [uploadDocumentsCompleted, setUploadDocumentsCompleted] =
    useState(false);
  const [verifyDocumentsCompleted, setVerifyDocumentsCompleted] =
    useState(false);
  const [claimsDecisionCompleted, setClaimsDecisionCompleted] = useState(false);
  const [claimsCommunicationCompleted, setClaimsCommunicationCompleted] =
    useState(false);
  const [claimsPayoutCompleted, setClaimsPayoutCompleted] = useState(false);
  const [claimsClosureCompleted, setClaimsClosureCompleted] = useState(false);

  let currentLocationPath = useLocation();
  const params = useParams();

  const selectedCustomer = useSelector(
    (state) => state.customerSlice.selectedCustomer
  );

  const selectedSubscription = useSelector(
    (state) => state.subscriptionsSlice.selectedSubscription
  );

  const stagesList = useSelector((state) => state.configSlice.stages);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleShowLoader = (value) => {
    setShowLoader(value);
  };

  useEffect(() => {
    dispatch(setClaimStage(null));
  }, []);

  /* LOAD TAB BASED ON CLAIMS ID*/
  useEffect(() => {
    const currentPath = currentLocationPath;

    /* LOAD CUSTOMER AND SUBSCRIPTION DETAILS INCASE CLAIM ID IS AVAILABLE*/
    if (currentPath.pathname.includes("claim") && params.claimId !== "") {
      dispatch(getClaimDetailsAPI({ claimId: params.claimId })).then(
        async (claimData) => {
          if (claimData) {
            await dispatch(
              setSelectedCustomer(
                CustomerDetailsMapper(claimData.payload.customer)
              )
            );
            await dispatch(
              setSelectedSubscription(
                SubscriptionDetailsMapper(claimData.payload)
              )
            );
            await dispatch(
              setIncidentDateForCustData(claimData.payload.incident.start_date)
            );
            await dispatch(
              setIncidentDateForSubData(claimData.payload.incident.start_date)
            );
            setClaimStageUsingData(claimData);
            setCurrentClaimsData(claimData.payload);
          }
        }
      );
    }
  }, [currentLocationPath]);

  /* WHILE WE NAVIGATE TO ACTIVE TAB, MARK THE STAGES COMPLETED */
  const setClaimStageUsingData = async (claimData) => {
    if (claimData.payload.claim.stage.name === "Claim Filing") {
      await setClaimsFilingCompleted(true);
      setValue(0);
    } else if (claimData.payload.claim.stage.name === "Document Collection") {
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      setValue(1);
    } else if (claimData.payload.claim.stage.name === "Verification") {
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      await setVerifyDocumentsCompleted(true);
      setValue(2);
    } else if (claimData.payload.claim.stage.name === "Decision") {
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      await setVerifyDocumentsCompleted(true);
      await setClaimsDecisionCompleted(true);
      setValue(3);
    } else if (claimData.payload.claim.stage.name === "Communication") {
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      await setVerifyDocumentsCompleted(true);
      await setClaimsDecisionCompleted(true);
      await setClaimsCommunicationCompleted(true);
      setValue(4);
    } else if (claimData.payload.claim.stage.name === "Payout") {
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      await setVerifyDocumentsCompleted(true);
      await setClaimsDecisionCompleted(true);
      await setClaimsCommunicationCompleted(true);
      await setClaimsPayoutCompleted(true);
      setValue(5);
    } else if (
      claimData.payload.claim.stage.name === "Closure" ||
      claimData.payload.claim.stage.name === "Closed"
    ) {
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      await setVerifyDocumentsCompleted(true);
      await setClaimsDecisionCompleted(true);
      await setClaimsCommunicationCompleted(true);
      await setClaimsPayoutCompleted(true);
      await setClaimsClosureCompleted(true);
      setValue(6);
    }
  };

  const setClaimStageUsingID = async (stageId, buttonType) => {
    console.log("setClaimStageUsingID", stageId);
    if (stageId === 0) {
      await setClaimsFilingCompleted(true);
      setValue(0);
    } else if (stageId === 1) {
      if (buttonType === "next")
        displayAlert("success", MESSAGES.CLAIM_FILING_STAGE_SUCCESS);
      await setClaimsFilingCompleted(true);
      setValue(1);
    } else if (stageId === 2) {
      if (buttonType === "next")
        displayAlert("success", MESSAGES.UPLOAD_DOCUMENT_STAGE_SUCCESS);
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      setValue(2);
    } else if (stageId === 3) {
      if (buttonType === "next")
        displayAlert("success", MESSAGES.VERIFICATION_STAGE_SUCCESS);
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      await setVerifyDocumentsCompleted(true);
      setValue(3);
    } else if (stageId === 4) {
      if (buttonType === "next")
        displayAlert("success", MESSAGES.DECISION_STAGE_SUCCESS);
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      await setVerifyDocumentsCompleted(true);
      await setClaimsDecisionCompleted(true);
      setValue(4);
    } else if (stageId === 5) {
      if (buttonType === "next")
        displayAlert("success", MESSAGES.COMMUNICATION_STAGE_SUCCESS);
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      await setVerifyDocumentsCompleted(true);
      await setClaimsDecisionCompleted(true);
      await setClaimsCommunicationCompleted(true);
      setValue(5);
    } else if (stageId === 6) {
      if (buttonType === "next")
        displayAlert("success", MESSAGES.PAYOUT_STAGE_SUCCESS);
      await setClaimsFilingCompleted(true);
      await setUploadDocumentsCompleted(true);
      await setVerifyDocumentsCompleted(true);
      await setClaimsDecisionCompleted(true);
      await setClaimsCommunicationCompleted(true);
      await setClaimsPayoutCompleted(true);
      setValue(6);
    }
  };

  function displayAlert(type, message) {
    setAlertData((prevState) => ({
      ...prevState,
      severity: type,
      message: message,
    }));
    setAlertOpen(true);
  }

  const handleChangeButton = async (pageNumber, buttonType, nextStage) => {
    console.log("handleChangeButton", pageNumber, buttonType, nextStage);
    if (
      buttonType !== null &&
      buttonType !== undefined &&
      buttonType === "next" &&
      nextStage !== null &&
      nextStage !== undefined
    ) {
      const requestBody = {
        stage: nextStage,
      };

      console.log("changeClaimStatusAPI: ", requestBody);
      let claimId = params.claimId;

      const data = { claimId, requestBody };
      const response = await dispatch(changeClaimStatus(data));
      const responsePayload = response.payload;
      console.log("changeClaimStatusAPI response ", responsePayload);

      if (responsePayload.status === 200) {
        setClaimStageUsingID(pageNumber, buttonType);
      } else {
        displayAlert("error", "Something went wrong pls try again later");
      }
    } else {
      setClaimStageUsingID(pageNumber, buttonType);
    }
  };

  const onCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <CssBaseline />
      <Box display="flex">
        <Container maxWidth={false} disableGutters sx={{ bgcolor: "#f2f6fc" }}>
          {/* Breadcrumbs displaying policy no. and buttons to download policy document */}
          <ClaimsPageHeader
            claimData={currentClaimsData}
            displayAlert={displayAlert}
          />
          <Spacer />

          {selectedCustomer !== null && selectedSubscription !== null && (
            <Card sx={{ m: 6, p: 4 }}>
              <StaticHeader />
            </Card>
          )}
          {/* Start of Tab section */}
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="inherit"
                aria-label="basic tabs example"
                variant="fullWidth"
                centered
              >
                <Tab
                  {...iconProperties(claimsFilingCompleted)}
                  label="Claim Filing"
                  {...a11yProps(0)}
                />
                <Tab
                  {...iconProperties(uploadDocumentsCompleted)}
                  label="Document Collection"
                  {...a11yProps(1)}
                />
                <Tab
                  {...iconProperties(verifyDocumentsCompleted)}
                  label="Verification"
                  {...a11yProps(2)}
                />
                <Tab
                  {...iconProperties(claimsDecisionCompleted)}
                  label="Decision"
                  {...a11yProps(3)}
                />
                <Tab
                  {...iconProperties(claimsCommunicationCompleted)}
                  label="Communication"
                  {...a11yProps(4)}
                />
                <Tab
                  {...iconProperties(claimsPayoutCompleted)}
                  label="Payout"
                  {...a11yProps(5)}
                />
                <Tab
                  {...iconProperties(claimsClosureCompleted)}
                  label="Closure"
                  {...a11yProps(6)}
                />
              </Tabs>
            </Box>
            {ClaimStagesMapper(stagesList).map((item, index) => (
              <CustomTabPanel value={value} index={index}>
                <item.className
                  onNextButtonClick={handleChangeButton}
                  displayAlert={displayAlert}
                  roleAndPrivilageMap={roleAndPrivilageMap}
                  showLoadingBackdrop={handleShowLoader}
                />
              </CustomTabPanel>
            ))}
          </Box>
        </Container>
        <Box
          display={{ xs: "none", md: "block" }}
          sx={{ m: 4, width: { md: 250, lg: 300, xl: 350 } }}
        >
          {/* Start of Tab section */}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value1}
              onChange={handleChange1}
              indicatorColor="primary"
              textColor="inherit"
              aria-label="basic tabs example"
              variant="fullWidth"
              centered
            >
              <Tab
                icon={<Route />}
                iconPosition="start"
                label="Claim Activity"
                {...a11yProps(0)}
              />
              <Tab
                icon={<ChangeCircle />}
                iconPosition="start"
                label="Change Claim Status"
                {...a11yProps(1)}
              />
              {/* <Tab
                icon={<ViewTimeline />}
                iconPosition="start"
                label="LOGS"
                {...a11yProps(2)}
              /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value1} index={0}>
            <VerticalLinearStepper stepperActiveStep={value} />
          </CustomTabPanel>
          <CustomTabPanel value={value1} index={1}>
            <ChangeClaimStatus />
          </CustomTabPanel>
        </Box>
        {alertOpen && (
          <SnackbarMessage
            severity={alertData.severity}
            message={alertData.message}
            onOpen={alertOpen}
            onCloseHandler={onCloseHandler}
            vertical="top"
            horizontal="center"
          />
        )}
        <CommonBackDrop backdropOpen={showLoader} />
      </Box>
    </>
  );
};

export default CreateClaim;
