import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const fetchUploadedDocuments = createAsyncThunk(
  "getUploadDocumentSlice/fetchUploadedDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const { claimId } = data;
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/document`,
      });
      console.log("fetchUploadedDocuments Service success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPreSignedDocumentUrl = createAsyncThunk(
  "getPreSignedDocumentURLSlice/fetchPreSignedDocumentUrl",
  async (data, { rejectWithValue }) => {
    try {
      const { claimId, documentID } = data;
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/document/${documentID}/view`,
        // responseType: "blob",
      });
      console.log("fetchPreSignedDocumentUrl Service success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
