import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Home } from '@mui/icons-material';
import Spacer from 'ui/components/utils/spacer';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Box minHeight="95vh" sx={{ bgcolor: "#f2f6fc" }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <Typography variant='h4'>Page Not Found</Typography>
        </Grid>
        <Spacer value={2} />
        <Grid item xs={12}>
          <Button
              variant="contained"
              onClick={() => navigate('/')}
              size="large"
              startIcon={<Home />}
            >
              Go Back to Home
            </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PageNotFound;