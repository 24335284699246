import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const getConfigAPIService = createAsyncThunk(
  "configSlice/getConfigAPIService",
  async (data, { rejectWithValue }) => {
    console.log("fetchConfigDetails: apiService", data);
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/config/get`,
      });
      console.log("fetchConfigDetails Service success", response);
      if (response.status === 200) {
        return { status: response.status, data: response.data };
      } else {
        return rejectWithValue(response?.data || response.message);
      }
    } catch (error) {
      console.log("getConfigAPIService Service error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getCallLogConfigAPIService = createAsyncThunk(
  "configSlice/getCallLogConfigAPIService",
  async (data, { rejectWithValue }) => {
    console.log("fetchConfigDetails: call log apiService", data);
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/config/claim/call/log`,
      });
      console.log("fetchConfigDetails call log Service success", response);
      if (response.status === 200) {
        return { status: response.status, data: response.data };
      } else {
        return rejectWithValue(response?.data || response.message);
      }
    } catch (error) {
      console.log("getCallLogConfigAPIService Service error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getClaimsFormConfigAPIService = createAsyncThunk(
  "configSlice/getClaimsFormConfigAPIService",
  async (data, { rejectWithValue }) => {
    console.log("fetchConfigDetails: claims form apiService", data);
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/config/claim/forms`,
      });
      console.log("fetchConfigDetails claims form Service success", response);
      if (response.status === 200) {
        return { status: response.status, data: response.data };
      } else {
        return rejectWithValue(response?.data || response.message);
      }
    } catch (error) {
      console.log("getConfigAPIService Service error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getFAQConfigAPIService = createAsyncThunk(
  "configSlice/getFAQConfigAPIService",
  async (data, { rejectWithValue }) => {
    console.log("fetchConfigDetails: faq form apiService", data);
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/config/faqs`,
      });
      console.log("fetchConfigDetails faq Service success", response);
      if (response.status === 200) {
        return { status: response.status, data: response.data };
      } else {
        return rejectWithValue(response?.data || response.message);
      }
    } catch (error) {
      console.log("getConfigAPIService Service error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
