import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const fetchLoginDetails = createAsyncThunk(
  "authSlice/fetchLoginDetails",
  async (data, { rejectWithValue }) => {
    console.log("fetchLoginDetails: apiService", data);
    try {
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/auth/login`,
        data,
      });
      console.log("fetchLoginDetails Service success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      console.log("fetchLoginDetails Service error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);