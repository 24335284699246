import axios from "axios";
import JSONBigInt from "json-bigint";
import { store } from "../store/index";
import { clearAuth, setUser } from "store/auth/auth-slice";
import { FormatDateUtil } from "utils/formatdate";
import { saveAs } from "file-saver";

export const commonHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Client-ID": `${process.env.REACT_APP_CLIENT_ID}`,
};

/**
 * This method is a wrapper around axios to do generic calls
 * @param {Object} apiConfig
 * @param {string} apiConfig.method ('get','post','delete','put','patch')
 * @param {Object} apiConfig.headers http headers config
 * @param {string} apiConfig.baseURL baseURL of api, default value will be set by environment variable
 * @param {string} apiConfig.url endpoint of api
 * @param {Object} apiConfig.data data to send in body
 * @param {Object} apiConfig.params data to send as queryParam
 * @return Promise
 */
// Create an Axios instance
const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  //   timeout: 10000, // optional
  headers: commonHeaders,
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.authSlice.token;
    const tokenType = state.authSlice.tokenType;
    console.log("Request Interceptor: token", `${tokenType} ${token}`);
    if (token) {
      config.headers.Authorization = `${tokenType} ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    console.error("Request Interceptor Error:", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    // Do something with response data
    console.log("Response Interceptor:", response);
    return response;
  },
  (error) => {
    // Do something with response error
    console.error("Response Interceptor Error:", error);
    console.error("Response Interceptor Error: status ", error.response.status);
    console.error("Response Interceptor Error:", error);
    if (error.response.status === 401) {
      store.dispatch(setUser(null));
      store.dispatch(clearAuth());
    }
    return Promise.reject(error);
  }
);

export default apiClient;
