import axios from "axios";
import JSONBigInt from "json-bigint";
import { store } from "../store/index";
import { clearAuth, setUser } from "store/auth/auth-slice";
import { FormatDateUtil } from "utils/formatdate";
import { saveAs } from "file-saver";

export const commonHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Client-ID": `${process.env.REACT_APP_CLIENT_ID}`
};

/**
 * This method is a wrapper around axios to do generic calls
 * @param {Object} apiConfig
 * @param {string} apiConfig.method ('get','post','delete','put','patch')
 * @param {Object} apiConfig.headers http headers config
 * @param {string} apiConfig.baseURL baseURL of api, default value will be set by environment variable
 * @param {string} apiConfig.url endpoint of api
 * @param {Object} apiConfig.data data to send in body
 * @param {Object} apiConfig.params data to send as queryParam
 * @return Promise
 */
export default function ApiCall({
  method,
  url,
  data,
  params,
  headers = null,
  responseType = "json",
}) {
  const newHeader = headers ? { ...commonHeaders, ...headers } : commonHeaders;
  const http = axios.create({
    baseURL: url,
    headers: newHeader,
    // timeout: 30000,
    withCredentials: true,
    transformResponse: [
      function (data) {
        try {
          const parsedData = JSONBigInt.parse(data, { storeAsString: false });
          return parsedData;
        } catch (err) {
          console.log(`Response parsing error on url "${url}"`);
        }
      },
    ],
  });
  // Add request interceptor
  http.interceptors.request.use(
    function (config) {
      const state = store.getState();
      const token = state.authSlice.token;
      const tokenType = state.authSlice.tokenType;
      console.log("Request Interceptor: token", `${tokenType} ${token}`);
      if (token) {
        config.headers.Authorization = `${tokenType} ${token}`;
      } else {
        config.headers.Authorization = ``;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Add response interceptor
  http.interceptors.response.use(
    function (response) {
      console.log("Response Interceptor:", response);
      return response;
    },
    function (error) {
      console.error(
        "Response Interceptor Error: status ",
        error.response.status
      );
      console.error("Response Interceptor Error:", error);
      if (error.response.status === 401) {
        store.dispatch(setUser(null));
        store.dispatch(clearAuth());
      }
      return Promise.reject(error);
    }
  );
  const networkRequest = new Promise(async (resolve, reject) => {
    try {
      let response;

      if (data instanceof FormData) {
        // Handle FormData request (file upload)
        response = await http({
          method,
          url,
          data,
          headers: {
            ...newHeader,
            "Content-Type": "multipart/form-data",
          },
          params,
        });
      } else {
        // Handle regular JSON payload request
        response = await http({
          method,
          url,
          data,
          params,
          responseType: responseType === "blob" ? "blob" : "json", // Set responseType based on the parameter
        });
      }

      if (response.status === 200) {
        console.log("API call success response", response);
        if (responseType === "blob") {
          // Create a new Blob from the response data
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // Use FileSaver.js to save the file
          // saveAs(blob, `claims-report-${FormatDateUtil.init(new Date())}.xlsx`);
          console.log("Blob content:", await blob.text());

          saveAs(blob, `claims-report.xlsx`);
          //resolve(response);
        } else {
          resolve(response);
        }
      } else {
        throw new Error({
          status: response.status,
          message: "Something bad happened",
        });
      }
    } catch (error) {
      console.error("error response", error.response);
      let message = "";
      if (error.response) {
        switch (error.response.status) {
          case 400:
            message = error.response?.data?.message;
            break;
          case 401:
            sessionStorage.clear();
            localStorage.clear();
            //dispatch({ type: LOGOUT })
            //createNotification('error', 'Access Denied', 'Please contact admin')
            message = "Access Denied. Please contact admin.";
            break;
          case 403:
            message = "Bad Request";
            break;
          case 404:
            message = "Resource not found";
            break;
          case 500:
          case 501:
          case 502:
          case 503:
          case 504:
            message = "Server Error";
            break;
          default:
            message = "Something bad happens, we will be right back.";
            break;
        }
        reject({
          status: error?.response?.status,
          response: error?.response,
          message,
        });
      } else {
        reject({
          status: "",
          message: "Network Error",
          response: "Are you offline?",
        });
      }
    }
  });
  return networkRequest;
}
