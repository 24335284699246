import React from "react";
import ColumnProgressRenderer from "./column-renderers/column-progress-renderer.components";
import ColumnIdRenderer from "./column-renderers/column-id-renderer.component";
import ColumnEditRenderer from "./column-renderers/column-edit-renderer.components";
export const CategoryList = [
  {
    id: 1,
    name: "Search By Claim Details",
    items: [
      "Claim ID",
      "MSISDN",
      "Current Stage",
      "Policy",
      "Call Outcome",
      "Automatic Claim",
      "Recommendation",
      "Officers",
    ],
  },
  {
    id: 2,
    name: "Search By Date",
    items: ["Start Date", "End Date", "Follow Up Date"],
  },
  {
    id: 3,
    name: "Search By Customer Details",
    items: ["MSISDN", "Customer Name"],
  },
];

export const searchClaimJSON = {
  columns: [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: "",
      field: "claim_unique_id",
      width: 50,
      suppressMenu: true,
      suppressSorting: true,
      default: true,
    },
    {
      headerName: "Claim ID",
      field: "claim_id",
      width: 150,
      cellRenderer: ColumnIdRenderer,
      cellRendererParams: {
        onClaimIDClicked: (claimId) => {},
      },
      default: true,
    },
    {
      headerName: "Customer Name",
      field: "customer_name",
      flex: 1,
      default: true,
    },
    {
      headerName: "Govt ID",
      field: "nic",
      width: 110,
      flex: 1,
      hide: true,
      // default: false,
    },
    {
      headerName: "MSISDN",
      field: "msisdn",
      flex: 1,
      default: true,
      hide: false,
    },
    {
      headerName: "Claimant Name",
      field: "claimant_name",
      flex: 1,
      default: true,
      hide: false,
    },
    // {
    //   headerName: "Progress",
    //   field: "progress",
    //   width: 120,
    //   cellRenderer: ColumnProgressRenderer,
    //   default: false,
    //   hide: true,
    // },

    {
      headerName: "Claimant Phone No",
      field: "contact_no",
      flex: 1,
      default: true,
      hide: false,
    },
    {
      headerName: "Policy",
      field: "policy",
      flex: 1,
      default: true,
      hide: false,
    },
    {
      headerName: "Claim Age",
      field: "claim_age",
      flex: 1,
      default: false,
      hide: true,
    },
    {
      headerName: "Start Date",
      field: "start_date",
      flex: 1,
      default: true,
      hide: false,
    },
    {
      headerName: "Stage",
      field: "stage",
      flex: 1,
      default: true,
      hide: false,
    },
    {
      headerName: "Decision",
      field: "decision",
      flex: 1,
      default: false,
      hide: true,
    },
    {
      headerName: "Recommended Cover",
      field: "decision_payout",
      flex: 1,
      default: false,
      hide: true,
    },
    {
      headerName: "Recommendation",
      field: "recommendation",
      flex: 1,
      default: false,
      hide: true,
    },
    {
      headerName: "Automatic Claim",
      field: "automatic_claim",
      flex: 1,
      default: false,
      hide: true,
    },
    {
      headerName: "Payout Date",
      field: "payout_date",
      flex: 1,
      default: false,
      hide: true,
    },
    {
      headerName: "Claims Officer",
      field: "officers",
      flex: 1,
      default: true,
      hide: false,
    },
    {
      headerName: "Followup date",
      field: "followup_date",
      flex: 1,
      default: false,
      hide: true,
    },
    {
      headerName: "End date",
      field: "end_date",
      flex: 1,
      default: true,
      hide: false,
    },
    {
      headerName: "Call Outcome",
      field: "call_outcome",
      flex: 1,
      default: false,
      hide: true,
    },
    {
      headerName: "No Of Outbound Calls",
      field: "no_of_outbound_calls",
      flex: 1,
      default: false,
      hide: true,
    },
    // {
    //   headerName: "",
    //   field: "actions",
    //   cellRenderer: ColumnEditRenderer,
    //   width: 120,
    //   default: false,
    //   hide: true,
    // },
  ],
  rows: [],
  total: 0,
};

export const searchClaimFormData = [
  {
    api_key: "claim_id",
    api_value: "",
    display_name: "Claim ID",
    display_value: "",
  },
  {
    api_key: "customer_name",
    api_value: "",
    display_name: "Customer Name",
    display_value: "",
  },
  { api_key: "nic", api_value: "", display_name: "Govt ID", display_value: "" },
  {
    api_key: "msisdn",
    api_value: "",
    display_name: "MSISDN",
    display_value: "",
  },
  {
    api_key: "officer",
    api_value: "",
    display_name: "Officer",
    display_value: "",
  },
  { api_key: "stage", api_value: "", display_name: "Stage", display_value: "" },
  {
    api_key: "policy",
    api_value: "",
    display_name: "Policy",
    display_value: "",
  },
  {
    api_key: "start_date",
    api_value: "",
    display_name: "Start Date",
    display_value: null,
  },
  {
    api_key: "end_date",
    api_value: "",
    display_name: "End Date",
    display_value: null,
  },
  {
    api_key: "followup_date",
    api_value: "",
    display_name: "FollowUp Date",
    display_value: null,
  },
  {
    api_key: "call_outcome",
    api_value: "",
    display_name: "Call Outcome",
    display_value: "",
  },
  {
    api_key: "automatic_claim",
    api_value: "",
    display_name: "Automatic Claim",
    display_value: "",
  },
  {
    api_key: "recommendation",
    api_value: "",
    display_name: "Recommendation",
    display_value: "",
  },
];
