import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const fetchUserDetails = createAsyncThunk(
  "fetchUserDetails/userDetailsAPIService",
  async (data, { rejectWithValue }) => {
    console.log("fetchUserDetails", data);
    try {
      const { userId } = data;
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/user/${userId}`,
      });
      console.log("fetchUserDetails success", response);
      if (response.status === 200) {
        return { status: response.status, data: response.data };
      } else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
