import { Box, CardActionArea, Paper, Stack } from "@mui/material";
import React from "react";
import SignInInfoCard from "./signin-infocard.component";

function SignInInfo() {
  return (
    <div>
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack direction="row" spacing={4} justifyContent="space-between">
          <SignInInfoCard
            img={"claim-create"}
            title="Create Claim"
            desc="Submit a new claim quickly and easily. Provide necessary details, attach relevant documents, and describe the incident. Our streamlined process ensures your claim is processed efficiently. Start by filling out the claim form and submitting it online"
          />
          <SignInInfoCard
            img={"claim-mgt"}
            title="Manage Claim"
            desc="Keep track of your existing claims in one convenient place. View the status of each claim, update information if needed, and communicate with our support team. Managing your claims is now easier than ever with our user-friendly interface"
          />
          <SignInInfoCard
            img={"claim-report"}
            title="Generate Report"
            desc="Effortlessly create detailed reports for your claims. Our tool compiles all relevant information, including incident details and supporting documents, into a comprehensive report. Start generating your report now for a clear overview of your claim status."
          />
        </Stack>
      </Box>
    </div>
  );
}

export default SignInInfo;
