import { Button, IconButton, Paper, TableContainer } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import DoneIcon from "@mui/icons-material/Done";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { FormatDateUtil } from "utils/formatdate";
import { fetchPreSignedDocumentUrl } from "services/api/get-document-api-service";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getMIMEIcon } from "ui/components/utils/utils";
import { documentViewAPIService } from "services/api/document-api-service";
import CommonBackDrop from "ui/components/utils/backdrop.component";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary,
}));

const UploadDocumentTable = ({
  documentsList,
  itemsToShow,
  handleShowMore,
  removeFile,
  displayAlert,
  stage,
}) => {
  const dispatch = useDispatch();
  const [backdropOpen, setBackdropOpen] = useState(false);

  const params = useParams();
  const claimId = params.claimId;

  console.log("UploadDocumentTable: ", documentsList);

  // Get Document PreSignedURL API
  const viewDocument = async (documentId) => {
    try {
      setBackdropOpen(true);
      console.log("viewDocument: ", claimId, documentId);
      const data = { claimId, documentId, stage };
      const response = await dispatch(documentViewAPIService(data));
      const responsePayload = response.payload;
      console.log("viewDocument response ", responsePayload);

      if (responsePayload.status === 200) {
        setBackdropOpen(false);
        console.log("viewDocument response ", responsePayload.data.url);

        // Open the image in newtab
        window.open(responsePayload.data.url, "_blank");
      } else {
        setBackdropOpen(false);
        displayAlert("error", "Failed to view document, pls try again later");
      }
    } catch (error) {
      setBackdropOpen(false);
      displayAlert("error", "Failed to view document, pls try again later");
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {/* LOADER */}
      <CommonBackDrop backdropOpen={backdropOpen} />

      <TableContainer component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Channel</StyledTableCell>
              <StyledTableCell align="left">Remarks</StyledTableCell>
              <StyledTableCell align="left">Size</StyledTableCell>
              {/* <StyledTableCell align="left">MIME Type</StyledTableCell> */}
              <StyledTableCell align="left">Uploaded By</StyledTableCell>
              <StyledTableCell align="left">Uploaded Date</StyledTableCell>
              <StyledTableCell align="left">View</StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {documentsList != [] &&
              documentsList.slice(0, itemsToShow).map((document) => (
                <StyledTableRow key={document.id}>
                  <StyledTableCell component="th" scope="row">
                    {document.upload ? (
                      <IconButton
                        xs={2}
                        aria-label="delete"
                        size="small"
                        color="error"
                        sx={{ px: 1, ml: -2 }}
                      >
                        <FileUploadIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        xs={2}
                        aria-label="delete"
                        size="small"
                        color="success"
                        sx={{ px: 1, ml: -2 }}
                      >
                        <DoneIcon />
                      </IconButton>
                    )}
                    {document.name ? document.name : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {document.type?.name ? document.type.name : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {document.channel?.name ? document.channel.name : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {document.remarks ? document.remarks : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {document.filesize ? document.filesize : "NA"}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {document.uploaded_by ? document.uploaded_by : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {document.uploaded_at
                      ? FormatDateUtil.displayDate(document.uploaded_at)
                      : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <IconButton
                      xs={2}
                      aria-label={document.upload ? "delete" : "file"}
                      size={document.upload ? "small" : "large"}
                      color={document.upload ? "error" : "primary"}
                      onClick={() => {
                        if (document.upload) {
                          removeFile(document.tempId);
                        } else {
                          console.log("Clicked on file:", document.id);
                          viewDocument(document.id);
                        }
                      }}
                      sx={{ px: 3, ml: -2 }}
                    >
                      {document.upload ? (
                        <DeleteIcon />
                      ) : (
                        getMIMEIcon(document.mime_type)
                      )}
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
        {itemsToShow < documentsList.length && (
          <Button onClick={handleShowMore} variant="text" sx={{ m: 2 }}>
            Show More
          </Button>
        )}
      </TableContainer>
    </>
  );
};

export default UploadDocumentTable;
