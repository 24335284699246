import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const UploadDocumentConfirmationDialog = ({
  dialogOpen,
  dialogAccept,
  closeDialog,
}) => {
  const [open, setOpen] = useState(dialogOpen);

  useEffect(() => {
    setOpen(dialogOpen);
  }, [dialogOpen]);

  const handleDialogAccept = () => {
    setOpen(false);
    dialogAccept();
  };

  const handleClose = () => {
    setOpen(false);
    closeDialog();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure the documents are correct ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDialogAccept}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadDocumentConfirmationDialog;
