import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Stack,
  Typography,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BeneficiaryDetails from "ui/components/beneficiary-details/beneficiary-details.component";
import PaymentsHistory from "ui/components/payments-history/payments-history.components";
import TitleDesc from "ui/components/utils/titledesc.component";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Spacer from "ui/components/utils/spacer";
import { BackupTable } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FormatDateUtil } from "utils/formatdate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StaticHeader() {
  const navigate = useNavigate();
  const [bdOpen, setBDOpen] = useState(false);
  const [phOpen, setPHOpen] = useState(false);

  const [subscriptionId, setSubscriptionId] = useState("");
  const [platformName, setPlatformName] = useState("");
  const [formattedIncidentDate, setFormattedIncidentDate] = useState("");
  const [policyId, setPolicyId] = useState("");

  const selectedCustomer = useSelector(
    (state) => state.customerSlice.selectedCustomer
  );

  const selectedSubscription = useSelector(
    (state) => state.subscriptionsSlice.selectedSubscription
  );

  const incidentDate = useSelector(
    (state) => state.subscriptionsSlice.incidentDate
  );
  
  useEffect(() => {
    setSubscriptionId(selectedSubscription.subscription_id);
    setPlatformName(selectedSubscription.platform_name);
    if(incidentDate !== null){
      setFormattedIncidentDate(FormatDateUtil.init(incidentDate));
    }
    setPolicyId(selectedSubscription.product_id);
  }, [selectedCustomer, selectedSubscription, incidentDate]);

  const handleClickBDOpen = () => {
    setBDOpen(true);
  };

  const handleClickPHOpen = () => {
    setPHOpen(true);
  };

  const handleClose = () => {
    setBDOpen(false);
    setPHOpen(false);
  };

  return (
    <>
      {selectedCustomer !== null && selectedSubscription !== null && (
        <Box>
          <Grid container spacing={2}>

            {/* CUSTOMER DETAILS */}
            <Grid item xs={12} md={6} sx={{ p: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box display="flex">
                    <AccountCircleIcon fontSize="large" color="primary" />
                    <Spacer value={0.5} />
                    <Typography variant="h6" color="primary">
                      Customer Details
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  textAlign={{ xs: "left", md: "right" }}
                >
                  <Button variant="outlined" onClick={handleClickBDOpen}>
                    View Beneficiary Details
                  </Button>
                </Grid>
              </Grid>
              <Grid container={"true"} item sx={{ pt: 4, pl: 4, pr: 4 }}>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Customer ID"
                    desc={selectedCustomer.customer_id}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Policy Holder Name"
                    desc={
                      selectedCustomer.customer_name
                        ? selectedCustomer.customer_name
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Gender"
                    desc={
                      selectedCustomer.gender
                        ? selectedCustomer.gender === "M"
                          ? "Male"
                          : selectedCustomer.gender === "F"
                          ? "Female"
                          : "Other"
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Age"
                    desc={selectedCustomer.age ? selectedCustomer.age : "NA"}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="DOB"
                    desc={selectedCustomer.dob ? selectedCustomer.dob : "NA"}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Primary Phone No."
                    desc={
                      selectedCustomer.msisdn ? selectedCustomer.msisdn : "NA"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ mr: "-2px" }}
            />

            {/* SUBSCRIPTION DETAILS */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box display="flex">
                    <BackupTable fontSize="large" color="primary" />
                    <Spacer value={0.5} />
                    <Typography variant="h6" color="primary">
                      Subscription Details
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  textAlign={{ xs: "left", md: "right" }}
                >
                  <Button variant="outlined" onClick={handleClickPHOpen}>
                    View Payments History
                  </Button>
                </Grid>
              </Grid>
              <Grid container={"true"} item sx={{ pt: 4, pl: 4, pr: 4 }}>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Subscription ID"
                    desc={selectedSubscription.subscription_id}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Policy Name"
                    desc={selectedSubscription.product_name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Confirmation Date"
                    desc={
                      selectedSubscription.confirmed_date
                        ? FormatDateUtil.displayDate(selectedSubscription.confirmed_date)
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Platform Name"
                    desc={selectedSubscription.platform_name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Policy Status"
                    desc={selectedSubscription.current_policy_status}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TitleDesc
                    title="Incident Status"
                    desc={selectedSubscription.incident_policy_status}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* BENEFICIARY DETAILS DIALOG */}
          {(subscriptionId !== "" && platformName !== "" && formattedIncidentDate !== "") && <Dialog
            open={bdOpen}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth={"lg"}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"List of Beneficiaries"}</DialogTitle>
            <DialogContent>
              <BeneficiaryDetails subscription_id={subscriptionId} platform_name={platformName} incident_date={formattedIncidentDate}  />
            </DialogContent>
          </Dialog>}

          {/* PAYMENTS HISTORY DIALOG */}
          {(subscriptionId !== "" && platformName !== "" && formattedIncidentDate !== "" && policyId !== "") && <Dialog
            open={phOpen}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth={"lg"}
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <PaymentsHistory subscription_id={subscriptionId} policy_id={policyId} platform_name={platformName} incident_date={formattedIncidentDate} />
            </DialogContent>
          </Dialog>}
        </Box>
      )}
    </>
  );
}

export default StaticHeader;
