export function CustomerDetailsMapper(customerData){
    const formattedCustomerData = {};
    formattedCustomerData["customer_id"] = customerData.info.id;
    formattedCustomerData["customer_name"] = customerData.info.name;
    formattedCustomerData["gender"] = customerData.info.gender;
    formattedCustomerData["age"] = customerData.info.age;
    formattedCustomerData["dob"] = customerData.info.dob;
    formattedCustomerData["msisdn"] = customerData.info.msisdn;
    formattedCustomerData["alt_msisdn"] = customerData.info.alt_msisdn;
    formattedCustomerData["identity_number"] = customerData.info.nid;
    formattedCustomerData["platform_name"] = "NA";
    return formattedCustomerData;
}