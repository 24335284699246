import { createSlice } from "@reduxjs/toolkit";
import { fetchLoginDetails } from "services/api/auth-api-service";

export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    user: {},
    token: null,
    tokenType: null,
    roles: [],
    market: {},
    loading: false,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      console.log("authSlice reducers setUser", action.payload);
      if (action.payload !== null && action.payload.user !== null) {
        state.auth.user = action.payload.user;
        state.token = action.payload.token;
        state.tokenType = action.payload.token_type;
        state.roles = action.payload.roles;
      } else {
        state.user = null;
      }
    },
    clearAuth: (state) => {
      console.log("authSlice reducers clearAuth");
      state.user = null;
      state.token = null;
      state.tokenType = null;
      state.roles = [];
      state.market = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLoginDetails.fulfilled, (state, action) => {
        const payloadData = action.payload.data;
        state.loading = false;
        state.user = payloadData.user;
        state.token = payloadData.token;
        state.tokenType = payloadData.token_type;
        state.roles = payloadData.roles;
        state.market = payloadData.market;
      })
      .addCase(fetchLoginDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setUser, clearAuth } = authSlice.actions;
export const authReducer = authSlice.reducer;
