import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parse } from "date-fns";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { addCallLogsApiService } from "services/api/call-logs-api-service";

const dateFormat = "yyyy-MM-dd";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AddCallLogDialog = ({
  dialogOpen,
  onDialogDismiss,
  showLoader,
  dismissLoader,
}) => {
  const dispatch = useDispatch();

  const callOutCome = useSelector((state) => state.configSlice.callOutCome);
  const callType = useSelector((state) => state.configSlice.callType);
  const [callTypeList, setCallTypeList] = useState([]);
  const [callOutComeList, setCallOutComeList] = useState([]);
  const params = useParams();
  const claimId = params.claimId;
  console.log("claimId ", claimId);

  useEffect(() => {
    if (dialogOpen) {
      if (callOutCome) {
        setCallOutComeList(callOutCome);
      }
      if (callType) {
        setCallTypeList(callType);
      }
    }
  }, [dialogOpen, callOutCome, callType]);

  const onDialogCloseClicked = () => {
    console.log("onDialogCloseClicked: in dialog");
    onDialogDismiss();
  };

  const onSubmit = async (addCallData) => {
    onDialogCloseClicked();
    showLoader();
    console.log("Form Data:", addCallData);
    console.log(
      "handleChange  date ",
      format(addCallData.followUpDate, dateFormat)
    );

    const callTypeName = callTypeList.find(
      (item) => item.id === addCallData.callType
    );

    const callOutComeName = callOutComeList.find(
      (item) => item.id === addCallData.callOutcome
    );

    const requestBody = {
      call_type: { id: addCallData.callType, name: callTypeName?.name },
      call_outcome: {
        id: addCallData.callOutcome,
        name: callOutComeName?.name,
      },
      followup_date: format(addCallData.followUpDate, dateFormat),
      call_remarks: addCallData.comments,
    };
    const data = { claimId, requestBody };
    console.log("onSubmit data ", data);

    const response = await dispatch(addCallLogsApiService(data)).then(
      (response) => {
        if (response?.payload?.status === 200) {
          console.log(
            "addCallLogsApiService component response",
            response.payload.data
          );
          dismissLoader(true);
        } else {
          dismissLoader(false);
        }
      }
    );
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        open={dialogOpen}
        onClose={onDialogDismiss}
        sx={{ "& .MuiDialog-paper": { width: "90%", maxWidth: "950px" } }}
      >
        <DialogTitle sx={{ mt: 1, mb: 2 }}>
          <Typography variant="h6" align="center">
            Add Call Log
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onDialogDismiss}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={2}>
              {/* CALL TYPE */}
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="outlined"
                  size="medium"
                  fullWidth
                  error={!!errors.callType}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: errors.callType ? "red" : "", // Change border color on error
                      },
                      "&:hover fieldset": {
                        borderColor: errors.callType ? "red" : "black", // Hover effect for border color
                      },
                    },
                  }}
                >
                  <InputLabel id="call-type">Call Type</InputLabel>
                  <Controller
                    name="callType"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Call Type is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="search-current-stage"
                        label="Call Type"
                      >
                        {callTypeList.length > 0 &&
                          callTypeList.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              id={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                  {errors.callType && (
                    <Typography color="error">
                      {errors.callType.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              {/* CALL OUTCOME */}
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="outlined"
                  size="medium"
                  fullWidth
                  error={!!errors.callOutcome}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: errors.callOutcome ? "red" : "", // Change border color on error
                      },
                      "&:hover fieldset": {
                        borderColor: errors.callOutcome ? "red" : "black", // Hover effect for border color
                      },
                    },
                  }}
                >
                  <InputLabel id="call-outcome">Call Outcome</InputLabel>
                  <Controller
                    name="callOutcome"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Call Outcome is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="call-outcome"
                        label="Call Outcome"
                      >
                        {callOutComeList.length > 0 &&
                          callOutComeList.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              id={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                  {errors.callOutcome && (
                    <Typography color="error">
                      {errors.callOutcome.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              {/* Follow-Up Date */}
              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Controller
                    name="followUpDate"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "Follow-Up Date is required", // Required validation
                      validate: (value) => value !== null || "Invalid date", // Custom validation
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: !!errors.followUpDate,
                            helperText: errors.followUpDate
                              ? errors.followUpDate.message
                              : "",
                          },
                        }}
                        label="Follow-Up Date"
                        value={value}
                        onChange={onChange}
                        format={dateFormat}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              {/* COMMENTS */}
              <Grid item xs={12} md={12}>
                <Controller
                  name="comments"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Comments are required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="medium"
                      label="Comments"
                      fullWidth
                      multiline
                      rows={2}
                      id="comments"
                      error={!!errors.comments}
                      helperText={
                        errors.comments ? errors.comments.message : ""
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: errors.comments ? "red" : "", // Change border color on error
                          },
                          "&:hover fieldset": {
                            borderColor: errors.comments ? "red" : "black", // Hover effect for border color
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          {/* Button Section */}
          <DialogActions>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
              >
                Add
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
