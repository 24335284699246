import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  ListItemText,
  Grid,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SearchClaimByClaimDetails from "./searchclaim-by-claim-details.component";
import SearchClaimByDate from "./searchclaim-by-date.component";
import SearchClaimByCustomerDetails from "./searchclaim-by-customer-details-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchPolicyDetails } from "services/api/getpolicy-details-api-service";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import { getPreviousDate } from "../../../../utils/utility";

export const SearchClaimFilterDialog = ({
  formData,
  onAddOrUpdateDataChange,
  dialogOpen,
  onDialogDismiss,
  onDialogClear,
  categories,
  title,
  agentDetailList,
}) => {
  const [backdropOpen, setBackdropOpen] = useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [tempFormData, setTempFormData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  console.log("Search Filter Dialog - agentDetailList  ", agentDetailList);

  const dispatch = useDispatch();
  const policyDetailsLoading = useSelector(
    (state) => state.getPolicyDetailsSlice.loading
  );
  const policyDetailsList = useSelector(
    (state) => state.getPolicyDetailsSlice.policyDetailsList
  );
  const policyDetailsError = useSelector(
    (state) => state.getPolicyDetailsSlice.error
  );

  // Fetch data from API
  const fetchPolicyData = useCallback(
    (date) => {
      try {
        const data = { date };
        dispatch(fetchPolicyDetails(data));
      } catch (error) {
        console.error("Error fetchPolicyData:", error);
      }
    },
    [dispatch]
  );

  // Show loader when we call API
  useEffect(() => {
    if (policyDetailsLoading) {
      setBackdropOpen(true);
    } else {
      setBackdropOpen(false);
    }
  }, [policyDetailsLoading]);

  // Fetch policy data
  useEffect(() => {
    if (dialogOpen) {
      console.log("Search Header API call", dialogOpen);
      // fetchPolicyData("2024-09-15");
      fetchPolicyData(getPreviousDate());
    }
  }, [dialogOpen]);

  const handleDataChange = (
    api_key,
    api_value,
    display_name,
    display_value
  ) => {
    console.log(
      "handleDataChange: in dialog",
      api_key,
      api_value,
      display_name,
      display_value
    );

    setTempFormData((prevState) => {
      if (!Array.isArray(prevState)) {
        prevState = [];
      }
      const itemExists = prevState.some((item) => item.api_key === api_key);

      if (itemExists) {
        return prevState.map((item) =>
          item.api_key === api_key
            ? { ...item, api_value, display_value }
            : item
        );
      } else {
        return [
          ...prevState,
          {
            api_key,
            api_value,
            display_name,
            display_value,
          },
        ];
      }
    });
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const onDialogFilterClicked = () => {
    console.log("onDialogFilterClicked: in dialog", tempFormData);
    onAddOrUpdateDataChange(tempFormData);
    setTempFormData([]);
    onDialogDismiss();
  };

  const onDialogClosed = () => {
    console.log("On dialog closed");
    setTempFormData([]);
    onDialogDismiss();
  };

  const onDialogClearClicked = () => {
    console.log("On dialog Clear");
    setTempFormData([]);
    onDialogClear();
  };

  return (
    <>
      {/* Loader */}
      <CommonBackDrop backdropOpen={backdropOpen} />
      {!policyDetailsLoading && policyDetailsList !== null && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={dialogOpen}
          onClose={onDialogClosed}
        >
          <DialogTitle id="dialog-title">{title}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {/* First Column */}
              <Grid
                item
                xs={12}
                md={3}
                style={{ borderRight: "1px solid #ccc", overflowY: "auto" }}
              >
                <List>
                  {categories.map((category) => (
                    <ListItem
                      button
                      key={category.id}
                      selected={selectedCategory.id === category.id}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <ListItemText primary={category.name} />
                    </ListItem>
                  ))}
                </List>
              </Grid>

              {/* Second Column */}
              {/* Seacrh By Claim Details Section */}
              {selectedCategory.id === 1 && (
                <SearchClaimByClaimDetails
                  policyDetailsList={policyDetailsList}
                  agentDetailList={agentDetailList}
                  formDataValues={formData}
                  tempFormData={tempFormData}
                  handleDataChange={handleDataChange}
                />
              )}
              {/* Seacrh By Date Section */}
              {selectedCategory.id === 2 && (
                <SearchClaimByDate
                  formDataValues={formData}
                  tempFormData={tempFormData}
                  handleDataChange={handleDataChange}
                />
              )}
              {/* Seacrh By Customer Details Section */}
              {selectedCategory.id === 3 && (
                <SearchClaimByCustomerDetails
                  formDataValues={formData}
                  tempFormData={tempFormData}
                  handleDataChange={handleDataChange}
                />
              )}
            </Grid>
          </DialogContent>
          {/* Button Section */}
          <DialogActions>
            <Button onClick={onDialogClearClicked} color="primary">
              Clear
            </Button>
            <Button onClick={onDialogFilterClicked} color="primary">
              Filter
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
