import React from "react";
import { Button, Grid } from "@mui/material";
import { NavigateBefore, Save } from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { MESSAGES } from "../../../components/utils/message-constants";

const NavigationButtons = ({
  onNextButtonClick,
  handleClick,
  userHasWriteAccess,
  currentStage,
  isCurrentStageInUploadDocuments,
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={4} textAlign="right">
        <Button
          variant="contained"
          size="big"
          sx={{ p: 2, m: 4, minWidth: 200 }}
          onClick={() => onNextButtonClick(0, "previous")}
          startIcon={<NavigateBefore />}
        >
          Previous
        </Button>
      </Grid>
      <Grid item xs={4} textAlign="center">
        <Button
          variant="contained"
          size="big"
          disabled={
            currentStage === MESSAGES.CLAIM_STAGE_CLOSED ||
            currentStage === MESSAGES.CLAIM_STAGE_CLOSURE
              ? true
              : isCurrentStageInUploadDocuments && userHasWriteAccess
              ? false
              : true
          }
          sx={{ p: 2, m: 4, minWidth: 200 }}
          onClick={handleClick(-1)}
          startIcon={<Save />}
        >
          Save
        </Button>
      </Grid>
      <Grid item xs={4} textAlign="left">
        <Button
          variant="contained"
          size="big"
          sx={{ p: 2, m: 4, minWidth: 200 }}
          onClick={handleClick(2)}
          disabled={
            currentStage === MESSAGES.CLAIM_STAGE_CLOSED ||
            currentStage === MESSAGES.CLAIM_STAGE_CLOSURE
              ? true
              : isCurrentStageInUploadDocuments && userHasWriteAccess
              ? false
              : true
          }
          endIcon={<NavigateNextIcon />}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default NavigationButtons;
