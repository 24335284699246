import { createAsyncThunk } from "@reduxjs/toolkit";
import { da } from "date-fns/locale";
import ApiCall from "services/ApiCall";

export const uploadDocumentDetails = createAsyncThunk(
  "uploadDocumentSlice/uploadDocumentDetails",
  async (data, { rejectWithValue }) => {
    const { formData, index, claimId, stage } = data;
    try {
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/${stage}/upload`,
        data: formData,
      });
      console.log("uploadDocumentDetails Service success", response);
      if (response.status == 200)
        return { status: response.status, index: index, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      console.log("uploadDocumentDetails Service error", error);
      return rejectWithValue("Failed to upload files");
    }
  }
);

export const saveUploadDocumentDetails = createAsyncThunk(
  "uploadDocumentSlice/saveUploadDocumentDetails",
  async (data, { rejectWithValue }) => {
    const { requestData, claimId } = data;
    try {
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/document`,
        data: requestData,
      });
      console.log("saveUploadDocumentDetails Service success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      console.log("saveUploadDocumentDetails Service error", error);
      return rejectWithValue("Failed to upload files");
    }
  }
);
