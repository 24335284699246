import { Download, ForwardToInbox } from "@mui/icons-material";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import DownloadClaimFormDialog from "../utils/download-claim-form-dialog";
import { tr } from "date-fns/locale";
import { AddCallLogDialog } from "../utils/add-call-log-dialog";
import CommonBackDrop from "../utils/backdrop.component";
import { MESSAGES } from "../utils/message-constants";
import CallLogList from "../utils/call-log-list.component";

const ClaimsPageHeader = ({ claimData, displayAlert }) => {
  const [open, setOpen] = React.useState(false);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogListOpen, setDialogListOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDialogDismiss = () => {
    console.log("dismiss dialog");
    setDialogOpen(false);
    setDialogListOpen(false);
  };

  const showLoader = () => {
    setBackdropOpen(true);
  };

  const dismissLoader = (apiSuccess) => {
    if (apiSuccess === true) {
      displayAlert("success", MESSAGES.LOGS_ADDED_SUCCESS);
    } else {
      displayAlert("error", MESSAGES.LOGS_ADDED_ERROR);
    }
    setBackdropOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setDialogOpen(false);
    setDialogListOpen(false);
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    console.log(`Selected option: ${option}`);
    setDialogOpen(true);
    if (option === "Add") {
      setDialogListOpen(false);
      setDialogOpen(true);
    } else if (option === "View") {
      setDialogOpen(false);
      setDialogListOpen(true);
    }
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    console.log("Onclick");
    setOpen(true);
    console.log("Onclick", open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={3} justifyContent="space-between">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/claims">
                  CLAIMS
                </Link>
                {claimData !== "" && (
                  <Link
                    underline="hover"
                    color="inherit"
                    to={`/claim/${claimData.id}`}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {claimData.claim.id}
                    </Typography>
                  </Link>
                )}
              </Breadcrumbs>
            </Box>

            <Box display="flex" sx={{ pr: 4 }}>
              {claimData !== "" && (
                <Tooltip title="Add/View Call Logs">
                  <Button
                    aria-label="delete"
                    size="large"
                    color="primary"
                    variant="text"
                    sx={{ px: 2 }}
                    onClick={handleClick}
                  >
                    Call logs
                  </Button>
                </Tooltip>
              )}

              <Tooltip title="Download Claim Form">
                <IconButton
                  aria-label="delete"
                  size="large"
                  color="primary"
                  sx={{ px: 2 }}
                  onClick={handleClickOpen}
                >
                  <Download />
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick("Add")}>
                Add Logs
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick("View")}>
                View Logs
              </MenuItem>
            </Menu>
          </Stack>
        </CardContent>
      </Card>
      {open && (
        <DownloadClaimFormDialog openDialog={open} onClose={handleClose} />
      )}
      {dialogOpen && (
        <AddCallLogDialog
          dialogOpen={dialogOpen}
          onDialogDismiss={handleDialogDismiss}
          showLoader={showLoader}
          dismissLoader={dismissLoader}
        />
      )}
      {dialogListOpen && (
        <CallLogList
          dialogOpen={dialogListOpen}
          onDialogDismiss={handleDialogDismiss}
          // showLoader={showLoader}
          // dismissLoader={dismissLoader}
        />
      )}
      <CommonBackDrop backdropOpen={backdropOpen} />
    </div>
  );
};

export default ClaimsPageHeader;
