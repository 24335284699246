import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const createClaimAPIService = createAsyncThunk(
  "document/createClaimAPIService",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim`,
        data
      });
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const editIncidentDetailsAPIService = createAsyncThunk(
  "document/editIncidentDetailsAPIService",
  async (data, { rejectWithValue }) => {
    try {
      const {claimId, requestBody} = data;
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/incident`,
        data: requestBody
      });
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const editClaimantDetailsAPIService = createAsyncThunk(
  "document/editClaimantDetailsAPIService",
  async (data, { rejectWithValue }) => {
    try {
      const {claimId, requestBody} = data;
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/claimant`,
        data: requestBody
      });
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const editCommunicationDetailsAPIService = createAsyncThunk(
  "document/editCommunicationDetailsAPIService",
  async (data, { rejectWithValue }) => {
    try {
      const {claimId, requestBody} = data;
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/communication/on`,
        data: requestBody
      });
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const editAdditionalNotesAPIService = createAsyncThunk(
  "document/editAdditionalNotesAPIService",
  async (data, { rejectWithValue }) => {
    try {
      const {claimId, requestBody} = data;
      const response = await ApiCall({
        method: "PUT",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/remarks`,
        data: requestBody
      });
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const calculateAPIService = createAsyncThunk(
  "document/calculateAPIService",
  async (data, { rejectWithValue }) => {
    try {
      const {subscriptionId, subscriptionPlatform, noOfNights, incidentId, incidentDate, exGratia, isPrimary} = data;
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/search/payout/${subscriptionId}/${subscriptionPlatform}/${noOfNights}/${incidentId}/${incidentDate}/${exGratia}/${isPrimary}`,
      });
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);