import { createSlice } from "@reduxjs/toolkit";
import { fetchClaimsHistory } from "services/api/claimshistory-api-service"; 

const getClaimsHistorySlice = createSlice({
  name: "getClaimsHistorySlice",
  initialState: {
    claimsHistory: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClaimsHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClaimsHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.claimsHistory = action.payload.data;
      })
      .addCase(fetchClaimsHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default getClaimsHistorySlice.reducer;
