import {
  Home,
  Add,
  Search,
  AssignmentInd,
  DownloadForOffline,
  Sms,
  ManageAccounts,
  LiveHelp,
} from "@mui/icons-material";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = ({ appbarOpen, onAppBarToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  //const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    onAppBarToggle(true);
  };

  const handleDrawerClose = () => {
    onAppBarToggle(false);
  };

  return (
    <>
      <Drawer variant="permanent" open={appbarOpen}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            key="Dashboard"
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: appbarOpen ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: appbarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Home />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: appbarOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="search-customer"
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/search");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: appbarOpen ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: appbarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Add />
              </ListItemIcon>
              <ListItemText
                primary="Create Claims"
                sx={{ opacity: appbarOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="search-claims"
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/claims");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: appbarOpen ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: appbarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Search />
              </ListItemIcon>
              <ListItemText
                primary="Search Claims"
                sx={{ opacity: appbarOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          {/* <ListItem
            key="communication"
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/communication");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: appbarOpen ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: appbarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Sms />
              </ListItemIcon>
              <ListItemText
                primary="Communication"
                sx={{ opacity: appbarOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}
          <ListItem
            key="faqs"
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/faq");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: appbarOpen ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: appbarOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LiveHelp />
              </ListItemIcon>
              <ListItemText
                primary="FAQ's"
                sx={{ opacity: appbarOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
