import { Box, Grid, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchClaimPagination from "ui/layout/search-claim/components/searchclaim-pagination.components";

const ClaimsHistory = ({
  customerName,
  subscriptionName,
  claimHistoryList,
  currentPage,
  pageSize,
  handlePageSizeChange,
  handlePageChange,
  totalRows,
  displayRows,
}) => {
  const navigate = useNavigate();
  const totalPages = Math.ceil(totalRows / pageSize);

  function onClickHandler(claimId) {
    navigate(`/claim/${claimId}`);
  }

  console.log("claimHistoryList", claimHistoryList);

  return (
    <>
      {claimHistoryList && (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color="primary">
                CLAIMS HISTORY
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                align="left"
                color="GrayText"
                sx={{ fontStyle: "italic" }}
              >
                Showing claims for {customerName} for his {subscriptionName}{" "}
                policy
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <SearchClaimPagination
                // totalRows={totalRows}
                // displayRows={displayRows}
                pageSize={pageSize}
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageSizeChange={handlePageSizeChange}
                handlePageChange={handlePageChange}
              />
            </Grid>
          </Grid>
          <Box>
            <TableContainer sx={{ pt: 2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Claim ID</TableCell>
                    <TableCell align="left">Claimant Name</TableCell>
                    <TableCell align="left">Policy Name</TableCell>
                    <TableCell align="left">Claim Status</TableCell>
                    <TableCell align="left">Incident Type</TableCell>
                    <TableCell align="left">Approved Payout</TableCell>
                    <TableCell align="left">Approved Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {claimHistoryList &&
                    claimHistoryList.content.map((claimHistoryItem) => (
                      <TableRow
                        key={claimHistoryItem.claim.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          <Link
                            onClick={() => onClickHandler(claimHistoryItem.id)}
                            href="#"
                          >
                            {claimHistoryItem.claim.id}
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          {claimHistoryItem.customer.info.name}
                        </TableCell>
                        <TableCell align="left">
                          {claimHistoryItem.policy.name}
                        </TableCell>
                        <TableCell align="left">
                          {claimHistoryItem.claim?.stage?.name}
                        </TableCell>
                        <TableCell align="left">
                          {claimHistoryItem.incident.type.name}
                        </TableCell>
                        <TableCell align="left">
                          {claimHistoryItem.decision
                            ? claimHistoryItem.decision?.approved?.payout
                            : "NA"}
                        </TableCell>
                        <TableCell align="left">
                          {claimHistoryItem.decision
                            ? claimHistoryItem.decision?.decision_status?.name
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ClaimsHistory;
