import { Box, Grid, Radio, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedCustomer, setIncidentDateForCustData } from "store/customer/customer-slice";

const CustomerDetails = ({
  msisdn,
  dateOfIncident,
  customerDetails,
  onCustomerSelectHandler,
  customerIdChangeHandler,
}) => {
  const [radioButtonValue, setRadioButtonValue] = useState("");
  const dispatch = useDispatch();

  const handleRadioButtonChange = (event) => {
    var selectedCustomer = customerDetails.filter((obj) => {
      return obj.customer_id === event.target.value;
    });
    dispatch(setSelectedCustomer(selectedCustomer[0]));
    dispatch(setIncidentDateForCustData(dateOfIncident));
    onCustomerSelectHandler(selectedCustomer[0].platform_name);
    customerIdChangeHandler(selectedCustomer[0].customer_id);
    setRadioButtonValue(event.target.value);
  };

  return (
    <>
      {customerDetails && (
        <Box sx={{ p: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" color="primary">
                CUSTOMER DETAILS
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <TableContainer sx={{ pt: 2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left">ID</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Primary Phone No.</TableCell>
                    <TableCell align="left">NID</TableCell>
                    <TableCell align="left">Gender</TableCell>
                    <TableCell align="left">DOB</TableCell>
                    <TableCell align="left">Age</TableCell>
                    <TableCell align="left">Platform</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerDetails &&
                    customerDetails.map((customerItem) => (
                      <TableRow
                        key={customerItem.customer_name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={{ width: "5%" }}>
                          <Radio
                            checked={
                              radioButtonValue === `${customerItem.customer_id}`
                            }
                            onChange={handleRadioButtonChange}
                            value={`${customerItem.customer_id}`}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                          />
                        </TableCell>
                        <TableCell align="left" style={{ width: "12%" }}>
                          {customerItem.customer_id}
                        </TableCell>
                        <TableCell align="left" style={{ width: "12%" }}>
                          {customerItem.customer_name
                            ? customerItem.customer_name
                            : "NA"}
                        </TableCell>
                        <TableCell align="left" style={{ width: "12%" }}>
                          {customerItem.msisdn ? customerItem.msisdn : "NA"}
                        </TableCell>
                        <TableCell align="left" style={{ width: "12%" }}>
                          {customerItem.identity_number
                            ? customerItem.identity_number
                            : "NA"}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ width: "12%" }}
                          component="th"
                          scope="row"
                        >
                          {customerItem.gender ? customerItem.gender : "NA"}
                        </TableCell>
                        <TableCell align="left" style={{ width: "12%" }}>
                          {customerItem.dob ? customerItem.dob : "NA"}
                        </TableCell>
                        <TableCell align="left" style={{ width: "12%" }}>
                          {customerItem.age ? customerItem.age : "NA"}
                        </TableCell>
                        <TableCell align="left" style={{ width: "12%" }}>
                          {customerItem.platform_name
                            ? customerItem.platform_name
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CustomerDetails;