import { useLocation } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export function calculateAge(dob) {
  var today = new Date();
  var birthDate = new Date(dob); // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  console.log(age_now);
  return age_now;
}

// GET FILE TYPE
export function getFileType(fileName, fileType) {
  if (fileType.includes("image")) {
    return "image";
  } else if (fileType.includes("pdf")) {
    return "pdf";
  } else if (
    fileName.endsWith(".xls") ||
    fileName.endsWith(".xlsx") ||
    fileType.includes("excel")
  ) {
    return "excel";
  } else {
    return "unknown";
  }
}

// FORMAT FILE SIZE IN READABLE FORMAT
export function formatFileSize(sizeInBytes) {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let size = sizeInBytes;
  let unitIndex = 0;
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex += 1;
  }
  return `${size.toFixed(2)} ${units[unitIndex]}`;
}

// Get icons for uploaded documents
export function getMIMEIcon(type) {
  if (type === "image") {
    return <ImageIcon />;
  } else if (type === "pdf") {
    return <PictureAsPdfIcon />;
  } else if (type === ".xls" || type === ".xlsx" || type === ".excel") {
    return <FilePresentIcon />;
  } else {
    return <ImageIcon />;
  }
}

// Get object from list matching id field
export function getValueForID(list, uniqueId) {
  const filteredObject = list.filter((item) => item.id === uniqueId);
  console.log("filteredObject", filteredObject);
  return filteredObject[0].name;
}

// Get object from list matching id field
export function getIDForValue(list, value) {
  console.log(list);
  console.log(value);
}

// Calculate date between 2 dates
export function getNoOfDays(startDate, endDate) {
  let differenceInTime = endDate.getTime() - startDate.getTime();
  return Math.round(differenceInTime / (1000 * 3600 * 24));
}

// Capitalize First Letter
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function validateEmail(email) {
  // Regular expression for basic email validation
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
}

// Get backdated date
export function getMaxDateForPreviousDate(days) {
  return new Date(Date.now() - days * 24 * 60 * 60 * 1000);
}

export const calculatePagination = (totalElements, pageSize, currentPage) => {
  const totalPages = Math.ceil(totalElements / pageSize);
  const startIndex = currentPage * pageSize;

  console.log("calculatePagination I/P ", totalElements, pageSize, currentPage);
  console.log("calculatePagination O/P ", totalPages, startIndex);

  const endIndex = Math.min(startIndex + pageSize - 1, totalElements - 1);

  console.log(
    "calculatePagination O/P endIndex ",
    endIndex,
    typeof endIndex,
    typeof startIndex,
    typeof pageSize,
    typeof totalElements,
    startIndex + pageSize - 1,
    totalElements - 1
  );

  return {
    totalPages,
    startIndex,
    endIndex,
  };
};
