import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette:{
    primary:{
      main: "#315DA8",
      light: "#5291d8",
      dark: "#2F579F",
      tint90: "#3969c1",
      tint80: "#3d6ec8",
      tint70: "#5291d8",
      tint50: "#83b0e3",
      tint20: "#cee0f4",
      tint5: "#f2f7fc",
    },
    secondary:{
      main: '#FF4081',
    },
    grey:{
      dark:"#777",
      dark1:"#999",
      dark2:"#333",
      light:"#f7f7f7",
      light1:"#eee"
    },
    white: {
      light: "#ffffff"
    }
  }
})