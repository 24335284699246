import { Edit, Publish } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Spacer from "ui/components/utils/spacer";
import TitleDesc from "ui/components/utils/titledesc.component";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateAPIService,
  createClaimAPIService,
  editAdditionalNotesAPIService,
  editClaimantDetailsAPIService,
  editCommunicationDetailsAPIService,
  editIncidentDetailsAPIService,
} from "services/api/createclaim-api-service";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  calculateAge,
  getIDForValue,
  getNoOfDays,
} from "ui/components/utils/utils";
import { DevTool } from "@hookform/devtools";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getClaimDetailsAPI } from "services/api/getclaimdetails-api-service";
import { FormatDateUtil } from "utils/formatdate";
import axios from "axios";
import { selectedCustomer } from "store/customer/customer-selectors";
import { selectedSubscription } from "store/subscriptions/subscription-selectors";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import { CLAIM_FILING_PAGE_ACCESS } from "ui/components/utils/role-constants";
import debuglog from "utils/debuglog";
import { presentClaimStage } from "store/claim-details/claim-details-selectors";
import PageAccessDenied from "ui/components/page-access-denied/page-access-denied.component";
import { MESSAGES } from "ui/components/utils/message-constants";
import CommunicationDetailsCard from "ui/components/utils/communication-detail-card.component";

const ClaimFiling = ({
  onNextButtonClick,
  displayAlert,
  roleAndPrivilageMap,
  showLoadingBackdrop,
}) => {
  const dispatch = useDispatch();
  const currentLocationPath = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const claimFilingPageAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIM_FILING_PAGE_ACCESS
  );

  const [userHasViewAccess, setUserHasViewAccess] = useState(false);
  const [userHasEditAccess, setUserHasEditAccess] = useState(false);
  const [userHasWriteAccess, setUserHasWriteAccess] = useState(false);

  const [incidentNoOfNight, setIncidentNoOfNight] = useState("");
  const [incidentCoverEarned, setIncidentCoverEarned] = useState("");
  const [incidentPotentialPayout, setIncidentPotentialPayout] = useState("");
  const [incidentPayoutRemarks, setIncidentPayoutRemarks] = useState("");

  const [exGratiaChecked, setExGratiaChecked] = React.useState(false);
  const [isRcc, setRcc] = React.useState(false);
  const [isDuplicate, setIsDuplicate] = React.useState(false);
  const [isAutoClaim, setIsAutoClaim] = React.useState(false);

  const handleExGratiaChange = (event) => {
    setExGratiaChecked(event.target.checked);
  };

  const handleDuplicateTicketEvent = (event) => {
    setIsDuplicate(event.target.checked);
  };

  const handleRCCEvent = (event) => {
    setRcc(event.target.checked);
  };

  const handleAutoClaimEvent = (event) => {
    setIsAutoClaim(event.target.checked);
  };

  const form = useForm({
    defaultValues: {
      incidentType: "",
      incidentDisabilityType: "",
      incidentOtherCauseDetails: "",
      incidentExpenseIncurred: "",
      incidentHospitalDetail: "",
      incidentLocation: "",
      incidentOtherCity: "",
      claimantType: "",
      claimantRelationship: "",
      claimantName: "",
      claimantGender: "",
      claimantTypeOfID: "",
      claimantNid: "",
      claimantLocation: "",
      claimantPrimaryNo: "",
      claimantAlternateNo: "",
      claimantOtherCity: "",
      commMode: "",
      commDetails: "",
      additionalNotes: "",
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    getValues,
    control,
  } = form;

  const [filteredIncidentData, setFilteredIncidentData] = useState([]);
  const [filteredRelationshipData, setFilteredRelationshipData] = useState([]);

  const [causeOfIncident, setCauseOfIncident] = useState({});
  const [causeOfIncidentOptionsList, setCauseOfIncidentOptionsList] = useState(
    []
  );
  const [incidentStartDate, setIncidentStartDate] = useState(null);
  const [incidentEndDate, setIncidentEndDate] = useState(null);
  const [incidentLocationDetail, setIncidentLocationDetail] = useState("");
  const [displayIncidentPayoutDetails, setDisplayIncidentPayoutDetails] =
    useState(false);
  const [calculatePayoutButtonEnabled, setCalculatePayoutButtonEnabled] =
    useState(false);

  const [claimantRelationshipList, setClaimantRelationshipList] = useState([]);
  const [claimantCityDetail, setClaimantCityDetail] = useState("");
  const [claimantDob, setClaimantDob] = useState(null);
  const [claimantAge, setClaimantAge] = useState("");
  const [claimantAgeError, setClaimantAgeError] = useState("");

  const [commFormValues, setCommFormValues] = useState([
    { mode: { id: "", name: "" }, contact: "" },
  ]);

  const [displayingOldClaims, setDisplayingOldClaims] = useState(false);
  const [incidentDataExists, setIncidentDataExists] = useState(false);
  const [claimantDataExists, setClaimantDataExists] = useState(false);
  const [communicationDataExists, setCommunicationDataExists] = useState(false);
  const [additionalNotesDataExists, setAdditionalNotesDataExists] =
    useState(false);

  const [editIncidentDetailsInProgress, setEditIncidentDetailsInProgress] =
    useState(false);
  const [editClaimantDetailsInProgress, setEditClaimantDetailsInProgress] =
    useState(false);
  const [
    editCommunicationDetailsInProgress,
    setEditCommunicationDetailsInProgress,
  ] = useState(false);
  const [editAdditionalNotesInProgress, setEditAdditionalNotesInProgress] =
    useState(false);

  const selectedCustomerData = useSelector(selectedCustomer);
  const selectedSubscriptionData = useSelector(selectedSubscription);
  const currentClaimStageData = useSelector(presentClaimStage);
  const [isCurrentStageInClaimsFiling, setIsCurrentStageInClaimsFiling] =
    useState(false);

  const [disableGovtIdField, setDisableGovtIdField] = useState(false);

  // META DATA DETAILS
  const [metaDataList, setMetaDataList] = useState([]);
  const [defaultMetaDataList, setDefaultMetaDataList] = useState([]);

  // META DATA
  const allMetaData = useSelector((state) => state.configSlice.metadata);
  // Filter for payout stage
  const filteredMetaData = allMetaData.filter((stages) =>
    stages.filter.some((stage) => stage === MESSAGES.CLAIM_STAGE_FILING_ID)
  );

  /* GET FIELD DETAILS FROM CONFIG */
  const incidentTypeList = useSelector(
    (state) => state.configSlice.incidentType
  );
  const causeOfIncidentList = useSelector(
    (state) => state.configSlice.causeOfIncident
  );
  const cityList = useSelector((state) => state.configSlice.location);
  const claimantTypeList = useSelector(
    (state) => state.configSlice.claimantType
  );
  var defaultClaimantRelationshipList = useSelector(
    (state) => state.configSlice.claimantRelationship
  );
  const genderList = useSelector((state) => state.configSlice.gender);
  const nationalIdTypeList = useSelector(
    (state) => state.configSlice.nationalIdType
  );
  const communicationModeList = useSelector(
    (state) => state.configSlice.communicationMode
  );

  /* Check if stage is Claim Filings stage*/
  useEffect(() => {
    if (
      currentClaimStageData !== null &&
      currentClaimStageData === "Claim Filing"
    ) {
      setIsCurrentStageInClaimsFiling(true);
    }
  }, [currentClaimStageData]);

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    if (claimFilingPageAccessMap.size > 0) {
      if (claimFilingPageAccessMap.get("READ") === true) {
        setUserHasViewAccess(true);
      }
      if (claimFilingPageAccessMap.get("EDIT") === true) {
        setUserHasEditAccess(true);
      }
      if (claimFilingPageAccessMap.get("WRITE") === true) {
        setUserHasWriteAccess(true);
      }
    }
  }, [claimFilingPageAccessMap]);

  /* WATCH REACT HOOK FIELD VALUES AND SET OTHER VALUES ACCORDINGLY */
  useEffect(() => {
    watch((value, { name, type }) => {
      debuglog(value, name, type);
      if (name === "incidentType") {
        setCauseOfIncidentOptionsList({});
        const selectedIncidentType = value.incidentType;
        var filteredCauseOfIncidentList = causeOfIncidentList.filter(function (
          item
        ) {
          return (
            item.filter === null || item.filter.includes(selectedIncidentType)
          );
        });
        setCauseOfIncidentOptionsList(filteredCauseOfIncidentList);
      } else if (name === "claimantType") {
        const selectedClaimantType = value.claimantType;
        if (selectedClaimantType !== "" || selectedClaimantType !== null) {
          if (selectedClaimantType === "policyholder") {
            const filteredList = defaultClaimantRelationshipList.filter(
              (item) => item.id === "self"
            );

            // Set Relationship
            setClaimantRelationshipList(filteredList);
            if (filteredList.length === 1) {
              setValue("claimantRelationship", filteredList[0].id);
            }

            // Set Name
            if (selectedCustomerData !== null)
              setValue("claimantName", selectedCustomerData.customer_name);

            // Set Gender
            setValue(
              "claimantGender",
              selectedCustomerData?.gender !== ""
                ? selectedCustomerData?.gender === "M"
                  ? "male"
                  : "female"
                : "",
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            );
          } else {
            if (selectedCustomerData !== null) {
              const filteredList = defaultClaimantRelationshipList.filter(
                (item) =>
                  item.id !== "self" &&
                  item.filter.includes(selectedSubscriptionData.product_code)
              );
              setClaimantRelationshipList(filteredList);
              setValue("claimantName", "");
              setValue("claimantGender", "");
            }
          }
        }
      } else if (name === "claimantTypeOfID") {
        var claimantIDType = getValues("claimantTypeOfID");
        setValue("claimantNid", "");
        if (claimantIDType === "no_government_id") {
          setDisableGovtIdField(true);
        } else {
          setDisableGovtIdField(false);
        }
      }
    });
  }, [watch]);

  /* IF CLAIM ID EXISTS IN URL, LOAD DATA FROM API */
  useEffect(() => {
    const currentPath = currentLocationPath;
    if (currentPath?.pathname?.includes("claim") && params.claimId !== "") {
      showLoadingBackdrop(true);
      dispatch(getClaimDetailsAPI({ claimId: params.claimId })).then(
        async (claimData) => {
          const {
            claim,
            incident,
            claimant,
            customer,
            policy,
            communication_on,
          } = claimData.payload;

          setDisplayingOldClaims(true);
          setIncidentDataExists(true);
          setClaimantDataExists(true);
          setCommunicationDataExists(true);
          setAdditionalNotesDataExists(true);

          debuglog("claim", claim);
          debuglog("incident", incident);
          debuglog("claimant", claimant);
          debuglog("customer", customer);
          debuglog("policy", policy);

          debuglog(incident?.start_date);
          debuglog(incident?.end_date);

          setValue("incidentType", incident?.type.id, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          });
          setCauseOfIncident(
            getObjectForID("causeOfIncident", incident?.cause?.id)
          );
          setValue("incidentOtherCauseDetails", incident?.other_cause);
          setIncidentStartDate(new Date(incident?.start_date));
          setIncidentEndDate(new Date(incident?.end_date));
          setIncidentLocationDetail(incident?.location);
          setValue("incidentOtherCity", incident?.other_location);
          setValue("incidentExpenseIncurred", incident?.expense_incurred);
          setValue("incidentHospitalDetail", incident?.hospital);
          setIncidentNoOfNight(incident?.no_of_nights);
          setIncidentCoverEarned(incident?.cover_earned);
          setIncidentPayoutRemarks(incident?.remarks);
          setIncidentPotentialPayout(incident?.potential_payout);
          setExGratiaChecked(claim?.is_ex_gratia);
          setRcc(claim?.is_rcc);
          setIsDuplicate(claim?.is_duplicate);
          setIsAutoClaim(claim?.is_automatic);

          setDisplayIncidentPayoutDetails(true);
          setIncidentNoOfNight(parseInt(incident?.no_of_nights));
          setIncidentCoverEarned(parseInt(incident?.cover_earned));
          setIncidentPotentialPayout(parseInt(incident?.potential_payout));
          setIncidentPayoutRemarks(incident?.reamrks);

          setValue("claimantType", claimant?.type?.id);
          setValue("claimantRelationship", claimant?.relationship?.id);
          setValue("claimantName", claimant?.info?.name);
          setValue("claimantGender", claimant?.info?.gender, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          });
          setValue("claimantTypeOfID", claimant?.info?.nid_type?.id, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          });
          setClaimantDob(new Date(claimant?.info?.dob));
          setClaimantAge(claimant?.info?.age);
          setValue("claimantNid", claimant?.info?.nid, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          });
          setClaimantCityDetail(
            getObjectForID("claimantCityDetail", claimant?.location?.id)
          );
          setValue("claimantOtherCity", claimant?.other_location);
          setValue("claimantPrimaryNo", claimant?.info?.msisdn);
          setValue("claimantAlternateNo", claimant?.info?.alt_msisdn);
          setCommFormValues(communication_on);
          setValue("additionalNotes", claim?.remarks, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          });

          if (incident?.metadata && incident?.metadata.length > 0) {
            const metaDataDetails = incident?.metadata.map((item) => {
              return {
                mode: item.id,
                contact: item.value,
              };
            });
            setDefaultMetaDataList(metaDataDetails ?? []);
          }

          showLoadingBackdrop(false);
        }
      );
    }
  }, [currentLocationPath, params]);

  useEffect(() => {
    if (
      incidentTypeList.length > 0 &&
      selectedSubscriptionData?.product_code != "undefined"
    ) {
      var incidentDataResults = incidentTypeList.filter(filterListBySubProduct);
      setFilteredIncidentData(incidentDataResults);
    }
  }, [selectedSubscriptionData]);

  function filterListBySubProduct(item) {
    return (
      item.filter.includes(selectedSubscriptionData?.product_code) ||
      item.filter.length === 0
    );
  }

  /* Listener for Calculate Payout details API*/
  useEffect(() => {
    var noOfNights;
    const incidentType = getValues("incidentType");
    const claimantType = getValues("claimantType");

    const exGratiaValue = exGratiaChecked ? 1 : 0;
    const isPrimary = claimantType === "policyholder" ? 1 : 0;

    if (incidentType === "") return;

    if (Object.keys(causeOfIncident).length === 0) return;

    if (incidentStartDate != null && incidentEndDate !== null) {
      noOfNights = getNoOfDays(incidentStartDate, incidentEndDate);
    } else {
      return;
    }
    
    if (
      selectedSubscriptionData !== undefined &&
      incidentType !== "" &&
      Object.keys(causeOfIncident).length !== 0 &&
      noOfNights >= 0 &&
      (isCurrentStageInClaimsFiling || params.claimId === undefined)
    ) {
      fetchPayoutDetails(
        selectedSubscriptionData,
        incidentType,
        noOfNights,
        incidentStartDate,
        exGratiaValue,
        isPrimary
      );
      setCalculatePayoutButtonEnabled(true);
    }
  }, [
    selectedSubscriptionData,
    getValues("incidentType"),
    getValues("claimantType"),
    causeOfIncident,
    incidentStartDate,
    incidentEndDate,
    exGratiaChecked,
  ]);

  /* ------------ ONSUBMIT CALL HANDLERS --------------- */
  const onSubmit = async (data) => {
    showLoadingBackdrop(true);
    console.log(data);
    console.log("incidentDetail", causeOfIncident);
    console.log("claimantDob", claimantDob);
    console.log("incidentStartDate", incidentStartDate);
    console.log("incidentEndDate", incidentEndDate);
    console.log("selectedCustomerData", selectedCustomerData);
    console.log("selectedSubscriptionData", selectedSubscriptionData);

    const requestBody = {};
    requestBody.customer = {};
    requestBody.customer.info = {};
    requestBody.customer.info.id = selectedCustomerData.customer_id;
    requestBody.customer.info.name = selectedCustomerData.customer_name;
    requestBody.customer.info.gender = selectedCustomerData.gender;
    requestBody.customer.info.age = selectedCustomerData.age;
    requestBody.customer.info.dob = selectedCustomerData.dob;
    requestBody.customer.info.nid = selectedCustomerData.identity_number;
    requestBody.customer.info.msisdn = selectedCustomerData.msisdn;

    requestBody.customer.spouse = [];
    requestBody.customer.children = [];

    requestBody.policy = {};
    requestBody.policy.id = selectedSubscriptionData.product_id;
    requestBody.policy.subs_id = selectedSubscriptionData.subscription_id;
    requestBody.policy.name = selectedSubscriptionData.product_name;
    requestBody.policy.code = selectedSubscriptionData.product_code;
    requestBody.policy.payment_channel =
      selectedSubscriptionData.payment_channel;
    requestBody.policy.current_status =
      selectedSubscriptionData.current_policy_status;
    requestBody.policy.incident_status =
      selectedSubscriptionData.incident_policy_status;
    requestBody.policy.confirmed_date = selectedSubscriptionData.confirmed_date;
    requestBody.policy.last_payment_date =
      selectedSubscriptionData.incident_last_deduction_date;
    requestBody.policy.confirmed_date = selectedSubscriptionData.confirmed_date;
    requestBody.platform = selectedSubscriptionData.platform_name;

    requestBody.claimant = {};
    requestBody.claimant.customer = {};
    requestBody.claimant.type = {};
    requestBody.claimant.customer.name = data.claimantName;
    requestBody.claimant.customer.gender = data.claimantGender;
    requestBody.claimant.customer.age = claimantAge;
    requestBody.claimant.customer.dob = claimantDob;

    requestBody.claimant.customer.nid_type = {};
    requestBody.claimant.customer.nid_type.id = data.claimantTypeOfID;
    requestBody.claimant.customer.nid_type.name = getValueForID(
      nationalIdTypeList,
      data.claimantTypeOfID
    );

    requestBody.claimant.customer.nid = data.claimantNid;
    requestBody.claimant.other_location = data.claimantOtherCity;
    requestBody.claimant.customer.msisdn = data.claimantPrimaryNo;
    requestBody.claimant.customer.alt_msisdn = data.claimantAlternateNo;

    requestBody.claimant.type = {};
    requestBody.claimant.type.id = data.claimantType;
    requestBody.claimant.type.name = getValueForID(
      claimantTypeList,
      data.claimantType
    );

    requestBody.claimant.relationship = {};
    requestBody.claimant.relationship.id = data.claimantRelationship;
    requestBody.claimant.relationship.name = getValueForID(
      claimantRelationshipList,
      data.claimantRelationship
    );

    requestBody.claimant.location = {};
    requestBody.claimant.location.id = claimantCityDetail.id;
    requestBody.claimant.location.name = claimantCityDetail.name;

    requestBody.incident = {};
    requestBody.incident.type = {};
    requestBody.incident.type.id = data.incidentType;
    requestBody.incident.type.name = getValueForID(
      incidentTypeList,
      data.incidentType
    );

    requestBody.incident.other_cause = data.incidentOtherCauseDetails;
    requestBody.incident.cause = {};
    requestBody.incident.cause.id = causeOfIncident.id;
    requestBody.incident.cause.name = causeOfIncident.name;
    requestBody.incident.start_date = incidentStartDate;
    requestBody.incident.end_date = incidentEndDate;
    requestBody.incident.hospital = data.incidentHospitalDetail;
    requestBody.incident.location = {};
    requestBody.incident.location.id = incidentLocationDetail.id;
    requestBody.incident.location.name = incidentLocationDetail.name;
    requestBody.incident.other_location = data.incidentOtherCity;
    requestBody.incident.expense_incurred = parseInt(
      data.incidentExpenseIncurred
    );
    requestBody.incident.no_of_nights = parseInt(incidentNoOfNight);
    requestBody.incident.cover_earned = parseInt(incidentCoverEarned);
    requestBody.incident.potential_payout = parseInt(incidentPotentialPayout);

    var metaDataDetails = null;
    if (metaDataList && metaDataList.length > 0) {
      metaDataDetails = metaDataList.map((item) => {
        const metaDataName = filteredMetaData.find(
          (opt) => opt.id === item.mode
        );
        return {
          id: item.mode,
          name: metaDataName?.name || "",
          value: item.contact,
        };
      });
    }
    requestBody.incident.metadata = metaDataDetails;

    requestBody.communication = [...commFormValues];

    requestBody.remarks = data.additionalNotes;
    requestBody.is_ex_gratia = exGratiaChecked;
    requestBody.is_rcc = isRcc;
    requestBody.is_duplicate = isDuplicate;
    requestBody.is_automatic = isAutoClaim;

    const response = await dispatch(createClaimAPIService(requestBody));
    if (response.payload.status == 200) {
      displayAlert(
        "success",
        `Claim with ID: ${response.payload.data.claim.id} created successfully`
      );
      showLoadingBackdrop(false);
      navigate(`/claim/${response.payload.data.id}`);
    } else {
      showLoadingBackdrop(false);
      displayAlert(
        "error",
        `Unable to update incident details. Please try after sometime.`
      );
    }
  };

  const handleIncidentDetailsSubmit = async (event) => {
    showLoadingBackdrop(true);
    const requestBody = {};
    requestBody.type = {};
    requestBody.type.id = getValues("incidentType");
    requestBody.type.name = getValueForID(
      incidentTypeList,
      getValues("incidentType")
    );

    requestBody.other_cause = getValues("incidentOtherCauseDetails");

    requestBody.cause = {};
    requestBody.cause.id = causeOfIncident.id;
    requestBody.cause.name = causeOfIncident.name;

    requestBody.start_date = incidentStartDate;
    requestBody.end_date = incidentEndDate;
    requestBody.hospital = getValues("incidentHospitalDetail");

    requestBody.location = {};
    requestBody.location.id = incidentLocationDetail.id;
    requestBody.location.name = incidentLocationDetail.name;

    requestBody.expense_incurred = getValues("incidentExpenseIncurred");
    requestBody.no_of_nights = parseInt(incidentNoOfNight);
    requestBody.cover_earned = parseInt(incidentCoverEarned);
    requestBody.potential_payout = parseInt(incidentPotentialPayout);
    requestBody.is_ex_gratia = exGratiaChecked;
    requestBody.is_rcc = isRcc;
    requestBody.is_duplicate = isDuplicate;
    requestBody.is_automatic = isAutoClaim;
    requestBody.other_location = getValues("incidentOtherCity");

    var metaDataDetails = null;
    if (metaDataList && metaDataList.length > 0) {
      metaDataDetails = metaDataList.map((item) => {
        const metaDataName = filteredMetaData.find(
          (opt) => opt.id === item.mode
        );
        return {
          id: item.mode,
          name: metaDataName?.name || "",
          value: item.contact,
        };
      });
    }
    if (metaDataDetails !== null) {
      requestBody.metadata = metaDataDetails;
    }

    const response = await dispatch(
      editIncidentDetailsAPIService({
        claimId: params.claimId,
        requestBody: requestBody,
      })
    );
    if (response.payload.status == 200) {
      showLoadingBackdrop(false);
      displayAlert("success", `Incident details updated successfully`);
      debuglog("INCIDENT UPDATE RESPONSE: ", response);
      setEditIncidentDetailsInProgress(false);
      setIncidentDataExists(true);
    } else {
      showLoadingBackdrop(false);
      displayAlert(
        "error",
        `Unable to update incident details. Please try after sometime.`
      );
    }
  };

  const handleClaimantDetailsSubmit = async (event) => {
    showLoadingBackdrop(true);
    const requestBody = {};
    requestBody.customer = {};
    requestBody.type = {};
    requestBody.customer.name = getValues("claimantName");
    requestBody.customer.gender = getValues("claimantGender");
    requestBody.customer.age = claimantAge;
    requestBody.customer.dob = claimantDob;

    requestBody.customer.nid_type = {};
    requestBody.customer.nid_type.id = getValues("claimantTypeOfID");
    requestBody.customer.nid_type.name = getValueForID(
      nationalIdTypeList,
      getValues("claimantTypeOfID")
    );

    requestBody.customer.nid = getValues("claimantNid");
    requestBody.customer.msisdn = getValues("claimantPrimaryNo");
    requestBody.customer.alt_msisdn = getValues("claimantAlternateNo");

    requestBody.type = {};
    requestBody.type.id = getValues("claimantType");
    requestBody.type.name = getValueForID(
      claimantTypeList,
      getValues("claimantType")
    );

    requestBody.relationship = {};
    requestBody.relationship.id = getValues("claimantRelationship");
    requestBody.relationship.name = getValueForID(
      claimantRelationshipList,
      getValues("claimantRelationship")
    );

    requestBody.location = {};
    requestBody.location.id = claimantCityDetail.id;
    requestBody.location.name = claimantCityDetail.name;

    requestBody.other_location = getValues("claimantOtherCity");

    const response = await dispatch(
      editClaimantDetailsAPIService({
        claimId: params.claimId,
        requestBody: requestBody,
      })
    );
    if (response.payload.status == 200) {
      showLoadingBackdrop(false);
      displayAlert("success", `Claimant details updated successfully`);
      debuglog("INCIDENT UPDATE RESPONSE: ", response);
      setEditClaimantDetailsInProgress(false);
      setClaimantDataExists(true);
    } else {
      showLoadingBackdrop(false);
      displayAlert(
        "error",
        `Unable to update claimant details. Please try after sometime.`
      );
    }
  };

  const handleAdditionalMetaDataSubmit = (data) => {
    // Handle form submission
    console.log("Submitted Data: ", data);
  };

  // Handle data changes in the form in real-time
  const handleAdditionalInfoChange = (data) => {
    console.log("Real-time Form Data: ", data);
    setMetaDataList(data);
  };

  const handleCommunicationDetailsSubmit = async (event) => {
    showLoadingBackdrop(true);
    const requestBody = [...commFormValues];

    const response = await dispatch(
      editCommunicationDetailsAPIService({
        claimId: params.claimId,
        requestBody: requestBody,
      })
    );
    if (response.payload.status == 200) {
      showLoadingBackdrop(false);
      displayAlert("success", `Communication details updated successfully`);
      debuglog("INCIDENT UPDATE RESPONSE: ", response);
      setEditCommunicationDetailsInProgress(false);
      setCommunicationDataExists(true);
    } else {
      showLoadingBackdrop(false);
      displayAlert(
        "error",
        `Unable to update communication details. Please try after sometime.`
      );
    }
  };

  const handleAdditionalDetailsSubmit = async (event) => {
    showLoadingBackdrop(true);
    const requestBody = {};
    requestBody.remarks = getValues("additionalNotes");

    const response = await dispatch(
      editAdditionalNotesAPIService({
        claimId: params.claimId,
        requestBody: requestBody,
      })
    );
    if (response.payload.status == 200) {
      showLoadingBackdrop(false);
      displayAlert("success", `Claimant details updated successfully`);
      debuglog("INCIDENT UPDATE RESPONSE: ", response);
      setEditAdditionalNotesInProgress(false);
      setAdditionalNotesDataExists(true);
    } else {
      showLoadingBackdrop(false);
      displayAlert(
        "error",
        `Unable to update claimant details. Please try after sometime.`
      );
    }
  };

  async function fetchPayoutDetails(
    incidentTypeselectedSubscriptionData,
    incidentId,
    noOfNights,
    incidentDate,
    exGratiaValue,
    isPrimary
  ) {
    showLoadingBackdrop(true);

    const requestBody = {};
    requestBody.subscriptionId = selectedSubscriptionData?.subscription_id;
    requestBody.subscriptionPlatform = selectedSubscriptionData?.platform_name;
    requestBody.noOfNights = noOfNights;
    requestBody.incidentId = incidentId;
    requestBody.incidentDate = FormatDateUtil.init(incidentStartDate);
    requestBody.exGratia = exGratiaValue;
    requestBody.isPrimary = isPrimary;

    const response = await dispatch(calculateAPIService(requestBody));

    if (response.payload.status == 200 && response.payload.data.length > 0) {
      await setIncidentNoOfNight(noOfNights);
      setIncidentCoverEarned(
        response.payload.data[0]?.cover_provided_incident_month
          ? parseFloat(
              response.payload.data[0]?.cover_provided_incident_month
            ).toFixed(2)
          : "NA"
      );
      setIncidentPotentialPayout(
        response.payload.data[0]?.payout_amount
          ? parseFloat(response.payload.data[0].payout_amount).toFixed(2)
          : "NA"
      );
      setIncidentPayoutRemarks(response.payload.data[0]?.remarks);
      setDisplayIncidentPayoutDetails(true);
      setCalculatePayoutButtonEnabled(false);
      displayAlert("info", `Payout details updated successfully`);
    }
    showLoadingBackdrop(false);
  }

  async function handleCalculatePayout() {
    setDisplayIncidentPayoutDetails(true);
  }

  /* ------------ END OF API CALL HANDLERS --------------- */

  function onErrors(data) {
    debuglog("ON ERRORS >>>>>>>> ", data);
  }

  function setClaimantDOBHandler(value) {
    setClaimantDob(value);
    setClaimantAge(calculateAge(value));
  }

  function handleNextButtonClick() {
    onNextButtonClick(1, "next", {
      id: MESSAGES.CLAIM_STAGE_UPLOAD_DOC_ID,
      name: MESSAGES.CLAIM_STAGE_UPLOAD_DOC,
    });
  }

  const handleAgeChange = (e) => {
    setClaimantAge(e.target.value);
    if (e.target.value > 0) {
      setClaimantAgeError(false);
    } else {
      setClaimantAgeError(true);
    }
  };

  /* COMMUNICATION SECTION MODE AND DETAILS CHANGE HANDLERS */
  let handleCommModeChange = (index, event) => {
    const inputData = JSON.parse(JSON.stringify(commFormValues));
    inputData[index].mode.id = event.target.value;
    inputData[index].mode.name = getValueForID(
      communicationModeList,
      event.target.value
    );
    setCommFormValues(inputData);
  };

  let handleCommDetailsChange = (event) => {
    const clone = JSON.parse(JSON.stringify(commFormValues));
    //const inputData = [...commFormValues];
    clone[event.target.id].contact = event.target.value;
    setCommFormValues(clone);
  };

  let addFormFields = () => {
    setCommFormValues([
      ...commFormValues,
      { mode: { id: "", name: "" }, contact: "" },
    ]);
  };

  async function handleCommunicationDelete(index) {
    const inputData = JSON.parse(JSON.stringify(commFormValues));
    if (index === 0 && inputData.length === 1) {
      setCommFormValues([{ mode: { id: "", name: "" }, contact: "" }]);
    } else {
      inputData.splice(index, 1);
      await setCommFormValues(inputData);
    }
  }

  /* --------------- END ---------------- */

  function getValueForID(list, uniqueId) {
    const filteredObject = list.filter((item) => item.id === uniqueId);
    debuglog("filteredObject", filteredObject);
    return filteredObject[0].name;
  }

  function getObjectForID(itemName, uniqueId) {
    var filteredObject = {};
    if (itemName === "causeOfIncident") {
      filteredObject = causeOfIncidentList.filter(
        (item) => item.id === uniqueId
      );
    } else if (itemName === "claimantType") {
      filteredObject = claimantTypeList.filter((item) => item.id === uniqueId);
    } else if (itemName === "claimantRelationship") {
      filteredObject = claimantRelationshipList.filter(
        (item) => item.id === uniqueId
      );
    } else if (itemName === "claimantCityDetail") {
      filteredObject = cityList.filter((item) => item.id === uniqueId);
    }
    return filteredObject[0];
  }

  return (
    <div id="div-claims-filing">
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        {userHasViewAccess ? (
          <>
            {/* INCIDENT DETAILS */}
            <Card id="claimantinfo--main__card" sx={{ m: 4, p: 2 }}>
              <Box display="flex">
                <Typography variant="h6">Incident Details</Typography>
                <Spacer value={1} />
                {displayingOldClaims &&
                  !editIncidentDetailsInProgress &&
                  userHasEditAccess &&
                  isCurrentStageInClaimsFiling && (
                    <Tooltip title="Edit Incident Details">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={(e) => {
                          setEditIncidentDetailsInProgress(true);
                          setIncidentDataExists(false);
                        }}
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )}
              </Box>
              <Box sx={{ m: 2, p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControl fullWidth>
                      <InputLabel id="incidentinfo--typeofincident__inputlabel">
                        Type of Incident *
                      </InputLabel>
                      <Controller
                        name="incidentType"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Select
                            {...register("incidentType", {
                              required: "Type of Incident is required",
                            })}
                            name="incidentType"
                            error={!!errors.incidentType}
                            helperText={errors.incidentType?.message}
                            label="Type of Incident"
                            onChange={onChange}
                            value={value}
                            disabled={incidentDataExists ? true : false}
                            required
                          >
                            {filteredIncidentData &&
                              filteredIncidentData.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              ))}
                            ;
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/*  Load this view only for KH market */}
                  {process.env.REACT_APP_COUNTRY_CODE === "KH" &&
                    getValues("incidentType") === "4" && (
                      <Grid item xs={12} md={6} lg={4} xl={3}>
                        <FormControl fullWidth>
                          <InputLabel id="incidentinfo--typeofincident__inputlabel">
                            Type of Disability *
                          </InputLabel>
                          <Controller
                            name="incidentDisabilityType"
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <Select
                                {...register("incidentDisabilityType", {
                                  required: "Type of disability is required",
                                })}
                                name="incidentDisabilityType"
                                error={!!errors.incidentType}
                                helperText={errors.incidentType?.message}
                                label="Type of Disability"
                                onChange={onChange}
                                value={value}
                                disabled={incidentDataExists ? true : false}
                              >
                                <MenuItem value="partial_disability">
                                  Partial Disability
                                </MenuItem>
                                <MenuItem value="complete_disability">
                                  Complete Disability
                                </MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      disablePortal
                      id="incidentinfo--detailsofproblem__autocomplete"
                      name="incidentDetail"
                      value={causeOfIncident}
                      onChange={(event, newValue) => {
                        setCauseOfIncident(newValue);
                      }}
                      options={causeOfIncidentOptionsList}
                      getOptionLabel={(option) =>
                        typeof option.name === "string" ||
                        option.name instanceof String
                          ? option.name
                          : ""
                      }
                      fullWidth
                      disabled={
                        (getValues("incidentType") !== "" ? false : true) ||
                        (incidentDataExists ? true : false)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cause of Incident"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  {causeOfIncident && causeOfIncident.id === "other" && (
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <Controller
                        name="incidentOtherCauseDetails"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            {...register("incidentOtherCauseDetails")}
                            label="Details of Other Causes"
                            name="incidentOtherCauseDetails"
                            variant="outlined"
                            error={!!errors.incidentOtherCauseDetails}
                            helperText={
                              errors.incidentOtherCauseDetails?.message
                            }
                            onChange={onChange}
                            value={value}
                            fullWidth
                            disabled={incidentDataExists ? true : false}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Box fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          slotProps={{
                            textField: { fullWidth: true, required: true },
                          }}
                          name="incidentStartDate"
                          format="dd/MM/yyyy"
                          views={["year", "month", "day"]}
                          disableFuture
                          disabled={incidentDataExists ? true : false}
                          value={incidentStartDate}
                          onChange={(newValue) =>
                            setIncidentStartDate(newValue)
                          }
                          onChangeRaw={(e) => this.handleDatePickerChangeRaw(e)}
                          label="Start Date"
                          required
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Box fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          slotProps={{
                            textField: { fullWidth: true, required: true },
                          }}
                          name="incidentEndDate"
                          format="dd/MM/yyyy"
                          views={["year", "month", "day"]}
                          disableFuture
                          disabled={incidentDataExists ? true : false}
                          value={incidentEndDate}
                          onChange={(newValue) => setIncidentEndDate(newValue)}
                          label="End Date"
                          required
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Controller
                      name="incidentExpenseIncurred"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          {...register("incidentExpenseIncurred", {
                            required: "Expense Incurred is required",
                          })}
                          label="Expense Incurred"
                          name="incidentExpenseIncurred"
                          variant="outlined"
                          error={!!errors.incidentExpenseIncurred}
                          helperText={errors.incidentExpenseIncurred?.message}
                          onChange={onChange}
                          value={value}
                          disabled={incidentDataExists ? true : false}
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Controller
                      name="incidentHospitalDetail"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          {...register("incidentHospitalDetail", {
                            required: "Hospital Details is required",
                          })}
                          label="Hospital Details"
                          name="incidentHospitalDetail"
                          variant="outlined"
                          error={!!errors.incidentHospitalDetail}
                          helperText={errors.incidentHospitalDetail?.message}
                          onChange={onChange}
                          value={value}
                          disabled={incidentDataExists ? true : false}
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      disablePortal
                      id="incidentinfo--detailsofproblem__autocomplete"
                      name="incidentLocationDetail"
                      value={incidentLocationDetail}
                      onChange={(event, newValue) => {
                        setValue("incidentOtherCity", "");
                        setIncidentLocationDetail(newValue);
                      }}
                      options={cityList}
                      getOptionLabel={(option) =>
                        typeof option.name === "string" ||
                        option.name instanceof String
                          ? option.name
                          : ""
                      }
                      fullWidth
                      disabled={incidentDataExists ? true : false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          label="City"
                          required
                        />
                      )}
                    />
                  </Grid>
                  {incidentLocationDetail.id === "other" && (
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <Controller
                        name="incidentOtherCity"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            {...register("incidentOtherCity")}
                            id="claimantinfo--nid__textfield"
                            label="City Name"
                            name="incidentOtherCity"
                            variant="outlined"
                            onChange={onChange}
                            value={value}
                            fullWidth
                            disabled={incidentDataExists ? true : false}
                            error={!!errors.incidentOtherCity}
                            helperText={errors.incidentOtherCity?.message}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={4} sx={{ pt: 4 }}>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={exGratiaChecked}
                          onChange={handleExGratiaChange}
                          inputProps={{ "aria-label": "controlled" }}
                          name="Ex-Gratia"
                          disabled={incidentDataExists ? true : false}
                        />
                      }
                      label="Ex-Gratia"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDuplicate}
                          onChange={handleDuplicateTicketEvent}
                          inputProps={{ "aria-label": "controlled" }}
                          name="Duplicate"
                          disabled={incidentDataExists ? true : false}
                        />
                      }
                      label="Duplicate"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isRcc}
                          onChange={handleRCCEvent}
                          inputProps={{ "aria-label": "controlled" }}
                          name="RCC"
                          disabled={incidentDataExists ? true : false}
                        />
                      }
                      label="RCC"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAutoClaim}
                          onChange={handleAutoClaimEvent}
                          inputProps={{ "aria-label": "controlled" }}
                          name="Auto-Claim"
                          disabled={incidentDataExists ? true : false}
                        />
                      }
                      label="Auto-Claim"
                    />
                  </Grid>
                </Grid>

                {!displayIncidentPayoutDetails && (
                  <Grid container spacing={10} sx={{ pt: 4 }}>
                    <Grid item xs={12} textAlign="center">
                      <Button
                        variant="outlined"
                        size="big"
                        sx={{ p: 1, m: 1, minWidth: 200 }}
                        onClick={handleCalculatePayout}
                        disabled={calculatePayoutButtonEnabled ? false : true}
                      >
                        Calculate Payout
                      </Button>
                    </Grid>
                  </Grid>
                )}

                {displayIncidentPayoutDetails && (
                  <Box sx={{ pt: 4, pb: 2 }}>
                    <Typography variant="h6">Payout Details</Typography>
                    <Typography variant="caption" style={{ color: "red" }}>
                      <i>
                        <b>
                          * This is a system generated value. Kindly verify the
                          payout calculations manually before handing over the
                          payout
                        </b>
                      </i>
                    </Typography>
                    <Spacer value={1} />
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6} lg={3}>
                        <TitleDesc
                          title="No. of Nights"
                          desc={incidentNoOfNight}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <TitleDesc
                          title="Cover Earned"
                          desc={incidentCoverEarned}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <TitleDesc
                          title="Potential Payout"
                          desc={incidentPotentialPayout}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <TitleDesc
                          title="Remarks"
                          desc={
                            incidentPayoutRemarks ? incidentPayoutRemarks : "NA"
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* COMMUNICATION DETAILS */}
                {filteredMetaData && filteredMetaData.length > 0 && (
                  <Box sx={{ m: 0 }}>
                    <CommunicationDetailsCard
                      title="Incident Details Additional Information"
                      defaultMetaDataList={defaultMetaDataList}
                      optionsList={filteredMetaData}
                      enable={incidentDataExists ? false : true}
                      onSubmit={handleAdditionalMetaDataSubmit}
                      onDataChange={handleAdditionalInfoChange}
                    />
                  </Box>
                )}

                {editIncidentDetailsInProgress && (
                  <Grid container spacing={10} sx={{ pt: 4 }}>
                    <Grid item xs={6} textAlign="right">
                      <Button
                        variant="outlined"
                        size="big"
                        sx={{ p: 1, m: 1, minWidth: 200 }}
                        onClick={handleIncidentDetailsSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        size="big"
                        sx={{ p: 1, m: 1, minWidth: 200 }}
                        onClick={(e) => {
                          setEditIncidentDetailsInProgress(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Card>

            {/* CLAIMANT DETAILS */}
            <Card id="claimantinfo--main__card" sx={{ m: 4, p: 2 }}>
              <Box display="flex">
                <Typography variant="h6">Claimant Details</Typography>
                <Spacer value={1} />
                {displayingOldClaims &&
                  !editClaimantDetailsInProgress &&
                  userHasEditAccess &&
                  isCurrentStageInClaimsFiling && (
                    <Tooltip title="Edit Claimant Details">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={(e) => {
                          setEditClaimantDetailsInProgress(true);
                          setClaimantDataExists(false);
                        }}
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )}
              </Box>
              <Box sx={{ m: 2, p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControl fullWidth>
                      <InputLabel id="claimantinfo--claimanttype__inputlabel">
                        Type of Claimant *
                      </InputLabel>
                      <Controller
                        name="claimantType"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Select
                            {...register("claimantType", {
                              required: "Hospital Details is required",
                            })}
                            name="claimantType"
                            label="Type of Claimant *"
                            onChange={onChange}
                            value={value}
                            error={!!errors.claimantType}
                            helperText={errors.claimantType?.message}
                            disabled={claimantDataExists ? true : false}
                            required
                          >
                            {claimantTypeList &&
                              claimantTypeList.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              ))}
                            ;
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControl fullWidth>
                      <InputLabel id="claimantinfo--relationship__inputlabel">
                        Relationship *
                      </InputLabel>
                      <Controller
                        name="claimantRelationship"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Select
                            {...register("claimantRelationship", {
                              required: "Type of Relationship is required",
                            })}
                            labelId="claimantinfo--relationship__selectlabel"
                            id="claimantinfo--relationship__select"
                            name="claimantRelationship"
                            label="Relationship"
                            value={value}
                            onChange={onChange}
                            error={!!errors.claimantRelationship}
                            helperText={errors.claimantRelationship?.message}
                            disabled={claimantDataExists ? true : false}
                            required
                          >
                            {claimantRelationshipList.map((value) => (
                              <MenuItem value={value.id}>{value.name}</MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Controller
                      name="claimantName"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          {...register("claimantName", {
                            required: "Claimant name is required",
                          })}
                          name="claimantName"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          error={!!errors.claimantName}
                          helperText={errors.claimantName?.message}
                          fullWidth
                          label="Name"
                          disabled={claimantDataExists ? true : false}
                          //disabled={getValues("claimantName") !== "" ? true : false}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControl fullWidth>
                      <InputLabel id="claimantinfo--gender__inputlabel">
                        Gender *
                      </InputLabel>
                      <Controller
                        name="claimantGender"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Select
                            {...register("claimantGender", {
                              required: "Gender is required",
                            })}
                            labelId="demo-simple-select-autowidth-label"
                            id="claimantinfo--gender__select"
                            name="claimantGender"
                            label="Gender"
                            onChange={onChange}
                            value={value}
                            disabled={claimantDataExists ? true : false}
                            error={!!errors.claimantGender}
                            helperText={errors.claimantGender?.message}
                            required
                          >
                            {genderList &&
                              genderList.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              ))}
                            ;
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Box id="claimantinfo--dob__box" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          slotProps={{ textField: { fullWidth: true } }}
                          name="claimantDob"
                          format="dd/MM/yyyy"
                          views={["year", "month", "day"]}
                          disableFuture
                          value={claimantDob}
                          disabled={claimantDataExists ? true : false}
                          onChange={setClaimantDOBHandler}
                          label="Select Date of Birth"
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <TextField
                      id="outlined-basic"
                      label="Age"
                      name="claimantAge"
                      variant="outlined"
                      value={claimantAge}
                      onChange={handleAgeChange}
                      fullWidth
                      disabled={claimantDataExists ? true : false}
                      autoComplete="new-password"
                      error={claimantAgeError}
                      helperText={claimantAgeError ? "Invalid Age" : ""}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <FormControl fullWidth>
                      <InputLabel id="claimantinfo--gender__inputlabel">
                        Type of Govt. ID. *
                      </InputLabel>
                      <Controller
                        name="claimantTypeOfID"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <Select
                            {...register("claimantTypeOfID", {
                              required: "Type of ID is required",
                            })}
                            labelId="demo-simple-select-autowidth-label"
                            id="claimantinfo--gender__select"
                            name="claimantTypeOfID"
                            label="Type of Govt. ID. *"
                            onChange={onChange}
                            value={value}
                            disabled={claimantDataExists ? true : false}
                            error={!!errors.claimantTypeOfID}
                            helperText={errors.claimantTypeOfID?.message}
                            required
                          >
                            {nationalIdTypeList &&
                              nationalIdTypeList.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              ))}
                            ;
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Controller
                      name="claimantNid"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          {...register("claimantNid")}
                          id="claimantinfo--nid__textfield"
                          label="National/Government ID No."
                          name="claimantNid"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          disabled={
                            disableGovtIdField || claimantDataExists
                              ? true
                              : false
                          }
                          error={!!errors.claimantNid}
                          helperText={errors.claimantNid?.message}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      disablePortal
                      id="incidentinfo--detailsofproblem__autocomplete"
                      name="claimantCityDetail"
                      value={claimantCityDetail}
                      onChange={(event, newValue) => {
                        setValue("claimantOtherCity", "");
                        setClaimantCityDetail(newValue);
                      }}
                      options={cityList}
                      getOptionLabel={(option) =>
                        typeof option.name === "string" ||
                        option.name instanceof String
                          ? option.name
                          : ""
                      }
                      fullWidth
                      disabled={claimantDataExists ? true : false}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          label="City"
                          required
                        />
                      )}
                    />
                  </Grid>
                  {claimantCityDetail.id === "other" && (
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      <Controller
                        name="claimantOtherCity"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            {...register("claimantOtherCity")}
                            id="claimantinfo--nid__textfield"
                            label="City Name"
                            name="claimantOtherCity"
                            variant="outlined"
                            onChange={onChange}
                            value={value}
                            fullWidth
                            disabled={incidentDataExists ? true : false}
                            error={!!errors.claimantOtherCity}
                            helperText={errors.claimantOtherCity?.message}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Controller
                      name="claimantPrimaryNo"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          {...register("claimantPrimaryNo", {
                            required: "Primary phone number is required",
                            minLength: 9,
                            maxLength: 10,
                          })}
                          id="claimantinfo--primaryno__textfield"
                          label="Primary Phone No."
                          name="claimantPrimaryNo"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          disabled={claimantDataExists ? true : false}
                          error={!!errors.claimantPrimaryNo}
                          helperText={errors.claimantPrimaryNo?.message}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <Controller
                      name="claimantAlternateNo"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          {...register("claimantAlternateNo", {
                            minLength: 9,
                            maxLength: 10,
                          })}
                          id="claimantinfo--alternate-no__textfield"
                          label="Alternate Phone No."
                          name="claimantAlternateNo"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          disabled={claimantDataExists ? true : false}
                          error={!!errors.claimantAlternateNo}
                          helperText={errors.claimantAlternateNo?.message}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {editClaimantDetailsInProgress && (
                  <Grid container spacing={10} sx={{ pt: 4 }}>
                    <Grid item xs={6} textAlign="right">
                      <Button
                        variant="outlined"
                        size="big"
                        sx={{ p: 1, m: 1, minWidth: 200 }}
                        onClick={handleClaimantDetailsSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        size="big"
                        sx={{ p: 1, m: 1, minWidth: 200 }}
                        onClick={(e) => {
                          setEditClaimantDetailsInProgress(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Card>

            {/* COMMUNICATION DETAILS */}
            <Card id="claimantinfo--main__card" sx={{ m: 4, p: 2 }}>
              <Box display="flex">
                <Typography variant="h6">Communication Details</Typography>
                <Spacer value={1} />
                {displayingOldClaims &&
                  !editCommunicationDetailsInProgress &&
                  userHasEditAccess &&
                  isCurrentStageInClaimsFiling && (
                    <Tooltip title="Edit Communication Details">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={(e) => {
                          setEditCommunicationDetailsInProgress(true);
                          setCommunicationDataExists(false);
                        }}
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )}
              </Box>
              <Box sx={{ m: 2, p: 2 }}>
                {commFormValues?.map((element, index) => {
                  return (
                    <Box sx={{ p: 1 }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md={editCommunicationDetailsInProgress ? 5.5 : 6}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="comm-details--modeofcommunication__inputlabel">
                              Mode of Communication
                            </InputLabel>
                            <Select
                              name="mode"
                              label="Type of Incident"
                              onChange={(event) =>
                                handleCommModeChange(index, event)
                              }
                              value={commFormValues[index].mode.id}
                              id={index}
                              disabled={communicationDataExists ? true : false}
                            >
                              {communicationModeList &&
                                communicationModeList.map((item) => (
                                  <MenuItem value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              ;
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={editCommunicationDetailsInProgress ? 5.5 : 6}
                        >
                          <TextField
                            label="Phone No./Email"
                            name="contact"
                            variant="outlined"
                            onChange={handleCommDetailsChange}
                            value={commFormValues[index].contact}
                            id={index}
                            disabled={
                              communicationDataExists
                                ? true
                                : false ||
                                  commFormValues[index].mode.id ===
                                    "no_communication"
                                ? true
                                : false
                            }
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        </Grid>
                        {editCommunicationDetailsInProgress && (
                          <Grid item xs={12} md={1}>
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => handleCommunicationDelete(index)}
                                sx={{ "&:hover": { color: "red" } }}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  );
                })}

                {(editCommunicationDetailsInProgress ||
                  !displayingOldClaims) && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} textAlign="center">
                      <Button
                        variant="outlined"
                        size="big"
                        sx={{ p: 1, m: 1, minWidth: 200 }}
                        onClick={() => addFormFields()}
                      >
                        Add New
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {editCommunicationDetailsInProgress && (
                  <Grid container spacing={10} sx={{ pt: 4 }}>
                    <Grid item xs={6} textAlign="right">
                      <Button
                        variant="outlined"
                        size="big"
                        sx={{ p: 1, m: 1, minWidth: 200 }}
                        onClick={handleCommunicationDetailsSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        size="big"
                        sx={{ p: 1, m: 1, minWidth: 200 }}
                        onClick={(e) => {
                          setEditCommunicationDetailsInProgress(false);
                          setCommunicationDataExists(true);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Card>

            {/* AGENT REMARKS */}
            <Card id="claimantinfo--main__card" sx={{ m: 4, p: 2 }}>
              <Box display="flex">
                <Typography variant="h6">Additional Notes</Typography>
                <Spacer value={1} />
                {displayingOldClaims &&
                  !editAdditionalNotesInProgress &&
                  userHasEditAccess &&
                  isCurrentStageInClaimsFiling && (
                    <Tooltip title="Edit Communication Details">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={(e) => {
                          setEditAdditionalNotesInProgress(true);
                          setAdditionalNotesDataExists(false);
                        }}
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )}
              </Box>
              <Box sx={{ m: 2, p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="additionalNotes"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          {...register("additionalNotes")}
                          id="agentnotes__textfield"
                          label="Additional Notes"
                          name="additionalNotes"
                          multiline
                          rows={4}
                          value={value}
                          fullWidth
                          disabled={additionalNotesDataExists ? true : false}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              {editAdditionalNotesInProgress && (
                <Grid container spacing={10}>
                  <Grid item xs={6} textAlign="right">
                    <Button
                      variant="outlined"
                      size="big"
                      sx={{ p: 1, m: 1, minWidth: 200 }}
                      onClick={handleAdditionalDetailsSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      size="big"
                      sx={{ p: 1, m: 1, minWidth: 200 }}
                      onClick={(e) => {
                        setEditAdditionalNotesInProgress(false);
                        setAdditionalNotesDataExists(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Card>

            <Box textAlign="center">
              <Grid container spacing={10} sx={{ pt: 4 }}>
                <Grid item xs={4} textAlign="center">
                  <Spacer />
                </Grid>
                {userHasWriteAccess ? (
                  <Grid item xs={4} textAlign="center">
                    <Button
                      variant="contained"
                      size="big"
                      sx={{ p: 2, m: 4, minWidth: 200 }}
                      type="Submit"
                      endIcon={<Publish />}
                      disabled={displayingOldClaims ? true : false}
                    >
                      Create Claim
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={4} textAlign="center">
                    <Spacer />
                  </Grid>
                )}
                <Grid item xs={4} textAlign="left">
                  <Button
                    variant="contained"
                    size="big"
                    sx={{ p: 2, m: 4, minWidth: 200 }}
                    onClick={handleNextButtonClick}
                    endIcon={<NavigateNextIcon />}
                    disabled={displayingOldClaims ? false : true}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <PageAccessDenied />
        )}
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default ClaimFiling;