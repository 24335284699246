import {
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect } from "react";
import TableHead from "@mui/material/TableHead";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerTable } from "shimmer-effects-react";
import CommonBackDrop from "./backdrop.component";
import { getCallLogsApiService } from "services/api/call-logs-api-service";
import { useParams } from "react-router-dom";
import { FormatDateUtil } from "utils/formatdate";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CallLogList = ({ dialogOpen, onDialogDismiss }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [callLogList, setCallLogList] = React.useState([]);

  const claimId = params.claimId;
  console.log("claimId ", claimId);

  React.useEffect(() => {
    if (dialogOpen && claimId) {
      setBackdropOpen(true);
      dispatch(getCallLogsApiService(claimId)).then((responseData) => {
        if (responseData?.payload?.status === 200) {
          setBackdropOpen(false);
          console.log(
            "getCallLogsApiService response ",
            responseData?.payload?.data
          );
          setCallLogList(responseData?.payload?.data);
        } else {
          setBackdropOpen(false);
          setCallLogList([]);
        }
      });
    }
  }, [dialogOpen, claimId]);

  return (
    <>
      {/* LOADER */}
      <CommonBackDrop backdropOpen={backdropOpen} />
      {!backdropOpen && callLogList && (
        <Dialog
          TransitionComponent={Transition}
          keepMounted
          maxWidth="md"
          fullWidth
          open={dialogOpen}
          onClose={onDialogDismiss}
          sx={{ "& .MuiDialog-paper": { width: "90%", maxWidth: "950px" } }}
        >
          <DialogTitle sx={{ mt: 1, mb: 1 }}>
            <Typography variant="h6" align="center">
              Call Logs
            </Typography>
          </DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table
                stickyHeader
                sx={{ minWidth: 700 }}
                aria-label="customized table"
              >
                <StyledTableHeader>
                  <TableRow>
                    <StyledTableCell align="left">Call Type</StyledTableCell>
                    <StyledTableCell align="left">Call Outcome</StyledTableCell>
                    <StyledTableCell align="left">
                      Call Comments
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      Follow up Date
                    </StyledTableCell>
                    <StyledTableCell align="left">Call Date</StyledTableCell>
                    <StyledTableCell align="left">Log Added By</StyledTableCell>
                    <StyledTableCell align="left">Log Added At</StyledTableCell>
                  </TableRow>
                </StyledTableHeader>
                <TableBody>
                  {callLogList && callLogList.length > 0 ? (
                    callLogList.map((callLogItem) => (
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {callLogItem?.call_type?.name
                            ? callLogItem?.call_type?.name
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {callLogItem?.call_outcome?.name
                            ? callLogItem?.call_outcome?.name
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {callLogItem?.remarks ? callLogItem?.remarks : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {callLogItem?.followup_date
                            ? FormatDateUtil.displayDate(
                                callLogItem?.followup_date
                              )
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {callLogItem?.call_date
                            ? FormatDateUtil.displayDate(callLogItem?.call_date)
                            : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {callLogItem?.added_by ? callLogItem?.added_by : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {callLogItem?.added_at
                            ? FormatDateUtil.displayDate(callLogItem?.added_at)
                            : "NA"}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="body2" color="textSecondary">
                          No Data Available
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CallLogList;
