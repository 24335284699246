import { createSlice } from "@reduxjs/toolkit";
import { documentViewAPIService } from "../../services/api/document-api-service"; // Import the thunk

const getPreSignedDocumentURLSlice = createSlice({
  name: "getPreSignedDocumentURLSlice",
  initialState: {
    url: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(documentViewAPIService.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(documentViewAPIService.fulfilled, (state, action) => {
        state.loading = false;
        state.url = action.payload;
      })
      .addCase(documentViewAPIService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default getPreSignedDocumentURLSlice.reducer;
