import { Box, CssBaseline, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import Sidebar from "ui/components/sidebar/sidebar.components";
import Navbar from "ui/components/navbar/narbar.component";
import { useDispatch } from "react-redux";
import {
  getCallLogConfigAPIService,
  getConfigAPIService,
} from "services/api/getconfig-api-service";

import { Outlet } from "react-router-dom";

const Home = () => {
  const dispatch = useDispatch();
  const [appbarOpen, setAppbarOpen] = useState(false);

  function onAppBarToggle(status) {
    setAppbarOpen(status);
  }

  // Fetch Config data from API
  const fetchConfigData = async () => {
    try {
      const response = await dispatch(getConfigAPIService());
      const configDetails = response.payload;
      console.log("ConfigData Response ", configDetails);
      const callLogResponse = await dispatch(getCallLogConfigAPIService());
      console.log("ConfigData callLogResponse ", callLogResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchConfigData();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Navbar appbarOpen={appbarOpen} onAppBarToggle={onAppBarToggle} />
      <Sidebar appbarOpen={appbarOpen} onAppBarToggle={onAppBarToggle} />
      <Box component="main" sx={{ flexGrow: 1, p: 0, m: 0 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Home;
