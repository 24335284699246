import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/auth/auth-selectors";
import { clearAuth, setUser } from "store/auth/auth-slice";
import BIMALOGO from "../../../assets/img/bima-logo-white.png";
import { capitalizeFirstLetter } from "../utils/utils";
import { useNavigate } from "react-router-dom";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const Navbar = ({ appbarOpen, onAppBarToggle }) => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  console.log("user ", user);
  const dispatch = useDispatch();

  const signout = () => {
    dispatch(setUser(null));
    dispatch(clearAuth());
  };

  const viewProfile = () => {
    setOpen(false);
    navigate("/profile");
  };

  const [open, setOpen] = useState(false);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundImage:
          "linear-gradient(90deg, rgba(47,87,159,1) 0%, rgba(49,93,168,1) 50%, rgba(82,145,216,1) 100%);",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <StyledToolbar>
        <Toolbar>
          {user !== null && Object.keys(user).length !== 0 && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => onAppBarToggle(!appbarOpen)}
              edge="start"
              sx={{
                marginRight: 2,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img
            srcSet={`${BIMALOGO}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${BIMALOGO}?w=164&h=164&fit=crop&auto=format`}
            alt={"BIMA"}
            loading="lazy"
            height={40}
          />
        </Toolbar>
        {user !== null && Object.keys(user).length !== 0 && (
          <div>
            <Icons>
              <Typography variant="span">
                Hello, <strong>{capitalizeFirstLetter(user.name)}</strong>
              </Typography>
              {/* <Badge badgeContent={2} color="error">
                <Notifications onClick={(e) => setOpen(true)} />
              </Badge> */}
              <Avatar
                sx={{ width: 40, height: 40 }}
                onClick={(e) => setOpen(true)}
              >
                {user.name.charAt(0).toUpperCase()}
              </Avatar>
            </Icons>
            <UserBox onClick={(e) => setOpen(true)}>
              <Avatar sx={{ width: 40, height: 40 }}>
                {user.name.charAt(0).toUpperCase()}
              </Avatar>
            </UserBox>
          </div>
        )}
      </StyledToolbar>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={(e) => setOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          mt: 5,
        }}
      >
        <MenuItem onClick={viewProfile}>View Profile</MenuItem>
        <MenuItem onClick={signout}>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Navbar;
