import { Search } from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import React, { useEffect, useState } from "react";
import CustomerDetails from "ui/components/customer-details/customer-details.components";
import SubscriptionDetails from "ui/components/subscription-details/subscription-details.components";
import SnackbarMessage from "ui/components/utils/alert-message.component";
import Spacer from "ui/components/utils/spacer";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerList,
  setSelectedCustomer,
} from "store/customer/customer-slice";
import {
  setSelectedSubscription,
  setSubscriptionsList,
} from "store/subscriptions/subscriptions-slice";
import dayjs from "dayjs";
import ClaimsHistory from "ui/components/claims-history/claims-history.components";
import moment from "moment";
import { getCustomerDetailsAPI } from "services/api/search-customer-api-service";
import { getSubscriptionDetailsAPI } from "services/api/search-subscriptions-api-service";
import CustomerShimmer from "ui/components/customer-details/customer-shimmer.components";
import SubscriptionShimmer from "ui/components/subscription-details/subscription-shimmer.components";
import { fetchClaimsHistory } from "services/api/claimshistory-api-service";
import ClaimsHistoryShimmer from "ui/components/claims-history/claims-history-shimmer.component";
import { FormatDateUtil } from "utils/formatdate";
import { useNavigate } from "react-router-dom";
import {
  setClaimDetails,
  setClaimStage,
} from "store/claim-details/getclaim-details-slice";
import { CLAIM_SEARCH_PAGE_ACCESS } from "ui/components/utils/role-constants";
import useRoleAndPrivileges from "hooks/useRolesAndPermissions";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import PageAccessDenied from "ui/components/page-access-denied/page-access-denied.component";

const SearchCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleAndPrivilageMap = useRoleAndPrivileges();
  const searchClaimPageAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIM_SEARCH_PAGE_ACCESS
  );

  const [searchMsisdn, setSearchMsisdn] = useState("");
  const [claimIncidentDate, setClaimIncidentDate] = useState(new Date());
  const [displaySections, setDisplaySections] = useState(false);
  const [searchDataChanged, setSearchDataChanged] = useState(true);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({ severity: "info", message: "" });
  const [backdropOpen, setBackdropOpen] = useState(false);

  const [customerPlatform, setCustomerPlatform] = useState("");
  const [currentCustomerId, setCurrentCustomerId] = useState("");
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState("");

  const [pageSize, setPageSize] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [displayRows, setDisplayRows] = useState(0);

  // STATE TO CONTROL PREVILAGES
  const [userHasViewAccess, setuserHasViewAccess] = useState(false);
  const [userHasEditAccess, setuserHasEditAccess] = useState(false);

  // PAGINATION
  const handlePageSizeChange = (event) => {
    const newSize = event.target.value;
    setPageSize(newSize);
    setCurrentPage(0); // Reset to first page
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < Math.ceil(totalRows / pageSize)) {
      setCurrentPage(newPage);
    }
  };

  // CUSTOMER DETAILS STATE
  const customerDetails = useSelector(
    (state) => state.customerSlice.customerList
  );
  const customerDetailsLoading = useSelector(
    (state) => state.customerSlice.loading
  );
  const customerDetailsError = useSelector(
    (state) => state.customerSlice.error
  );

  // SUBSCRIPTION DETAILS STATE
  const subscriptionDetails = useSelector(
    (state) => state.subscriptionsSlice.subscriptionsList
  );
  const subscriptionDetailsLoading = useSelector(
    (state) => state.subscriptionsSlice.loading
  );
  const subscriptionDetailsError = useSelector(
    (state) => state.subscriptionsSlice.error
  );

  // CLAIMS HISTORY STATE
  const claimHistoryList = useSelector(
    (state) => state.getClaimsHistorySlice.claimsHistory
  );
  const claimHistoryLoading = useSelector(
    (state) => state.getClaimsHistorySlice.loading
  );
  const claimHistoryError = useSelector(
    (state) => state.getClaimsHistorySlice.error
  );

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    if (searchClaimPageAccessMap.size > 0) {
      if (searchClaimPageAccessMap.get("READ") === true) {
        setuserHasViewAccess(true);
      }
      if (searchClaimPageAccessMap.get("WRITE") === true) {
        setuserHasEditAccess(true);
      }
    }
  }, [searchClaimPageAccessMap]);

  console.log("Roles & Privileges");
  console.log(roleAndPrivilageMap);
  console.log(searchClaimPageAccessMap);

  useEffect(() => {
    // CLEAR EXISTING DATA
    dispatch(setCustomerList(null));
    dispatch(setSelectedCustomer(null));
    dispatch(setSubscriptionsList(null));
    dispatch(setSelectedSubscription(null));
    dispatch(setClaimDetails(null));
    dispatch(setClaimStage(null));
    //const rolesAndPrivileges = getRoleAndPrivilage(CLAIM_SEARCH_PAGE_ACCESS);
  }, []);

  useEffect(() => {
    console.log("customerId", currentCustomerId);
    console.log("subscriptionId", currentSubscriptionId);
    console.log("currentPage", currentPage);
    console.log("pageSize", pageSize);

    const requestBody = {};
    requestBody["customer_id"] = currentCustomerId;
    requestBody["subscription_id"] = currentSubscriptionId;
    requestBody["page"] = currentPage;
    requestBody["size"] = pageSize;

    console.log("currentCustomerId", currentCustomerId);
    console.log("currentSubscriptionId", currentSubscriptionId);
    if (currentCustomerId !== "" && currentSubscriptionId !== "") {
      console.log("MAKING CLAIMS HISTORY CALL >>>>>>>>");
      dispatch(fetchClaimsHistory(requestBody)).then((data) => {
        if (data.payload !== undefined) {
          console.log(
            "CLAIMS HISTORY DATA >>>>>>>>",
            data.payload.totalElements
          );
          setTotalRows(data.payload.data.totalElements);
          setDisplayRows(data.payload.data.numberOfElements);
        }
      });
    }
  }, [
    currentCustomerId,
    currentSubscriptionId,
    claimIncidentDate,
    currentPage,
    pageSize,
  ]);

  function getCustomerForId(customerId) {
    return customerDetails?.find((obj) => {
      return obj.customer_id === customerId;
    });
  }

  function getSubscriptionForId(subscriptionId) {
    return subscriptionDetails.find((obj) => {
      return obj.subscription_id === subscriptionId;
    });
  }

  const handleChange = (e) => {
    setSearchMsisdn(e.target.value);
  };

  function searchCustomerDetails() {
    if (searchMsisdn === "") {
      setAlertData((prevState) => ({
        ...prevState,
        severity: "error",
        message: "MSISDN cannot be blank",
      }));
      setAlertOpen(true);
      setDisplaySections(false);
    } else if (claimIncidentDate === null) {
      setAlertData((prevState) => ({
        ...prevState,
        severity: "error",
        message: "Incident Date cannot be blank",
      }));
      setAlertOpen(true);
      setDisplaySections(false);
    } else {
      // CLEAR EXISTING DATA
      dispatch(setCustomerList(null));
      dispatch(setSelectedCustomer(null));
      dispatch(setSubscriptionsList(null));
      dispatch(setSelectedSubscription(null));
      dispatch(setClaimDetails(null));

      // LOAD NEW DATA
      const incidentDate = FormatDateUtil.init(claimIncidentDate);
      // FETCH CUSTOMER DETAILS
      dispatch(getCustomerDetailsAPI({ searchMsisdn, incidentDate }));
      // FETCH CUSTOMER DETAILS
      dispatch(getSubscriptionDetailsAPI({ searchMsisdn, incidentDate }));

      setSearchDataChanged(false);
      setDisplaySections(true);
    }
  }

  function clearCustomerDetails() {
    setSearchMsisdn("");
    setClaimIncidentDate(null);
    setDisplaySections(false);
    dispatch(setCustomerList(null));
    dispatch(setSelectedCustomer(null));
    dispatch(setSubscriptionsList(null));
    dispatch(setSelectedSubscription(null));
    dispatch(setSelectedSubscription(null));
    dispatch(setClaimDetails(null));
    dispatch(setClaimStage(null));
  }

  const onCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleSubmit = () => {
    navigate("/create");
  };

  const setPlatform = (name) => {
    setCustomerPlatform(name);
  };

  function customerIdChangeHandler(customerId) {
    setCurrentCustomerId(customerId);
  }

  function subscriptionIdChangeHandler(subscriptionId) {
    setCurrentSubscriptionId(subscriptionId);
  }

  function incidentDateChangeHandler(newValue) {
    setSearchDataChanged(true);
    setClaimIncidentDate(newValue);
  }

  return (
    <div>
      {userHasViewAccess ? (
        <Box minHeight="95vh" sx={{ bgcolor: "#f2f6fc" }}>
          {/* Breadcrumbs displaying policy no. */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ pt: 6 }}
          >
            <TextField
              id="searchMsisdn"
              value={searchMsisdn}
              label="Enter MSISDN"
              onChange={handleChange}
              variant="outlined"
              size="large"
            />
            <Spacer value={2} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="dd/MM/yyyy"
                label="Incident Date"
                views={["year", "month", "day"]}
                value={claimIncidentDate}
                disableFuture
                onChange={incidentDateChangeHandler}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Spacer value={2} />
            {(customerDetails === null ||
              subscriptionDetails === null ||
              searchDataChanged === true) && (
              <Button
                variant="contained"
                onClick={searchCustomerDetails}
                size="large"
                startIcon={<Search />}
              >
                Search Details
              </Button>
            )}
            {customerDetails !== null &&
              subscriptionDetails !== null &&
              searchDataChanged === false && (
                <Button
                  variant="contained"
                  onClick={clearCustomerDetails}
                  size="large"
                >
                  Clear
                </Button>
              )}
          </Box>

          {displaySections && (
            <Box>
              {/* Customer Details Section */}
              <Card sx={{ m: 4, p: 2 }}>
                {customerDetails !== "" && (
                  <CustomerDetails
                    msisdn={searchMsisdn}
                    dateOfIncident={claimIncidentDate}
                    customerDetails={customerDetails}
                    onCustomerSelectHandler={setPlatform}
                    customerIdChangeHandler={customerIdChangeHandler}
                  />
                )}
                {customerDetailsLoading && <CustomerShimmer />}
              </Card>
              {/* Subscription Details Section */}
              <Card sx={{ m: 4, p: 2 }}>
                {subscriptionDetails && (
                  <SubscriptionDetails
                    msisdn={searchMsisdn}
                    dateOfIncident={claimIncidentDate}
                    subscriptionDetails={subscriptionDetails}
                    customerPlatform={customerPlatform}
                    currentCustomerId={currentCustomerId}
                    subscriptionIdChangeHandler={subscriptionIdChangeHandler}
                  />
                )}
                {subscriptionDetailsLoading && <SubscriptionShimmer />}
              </Card>
              {currentCustomerId !== "" &&
                currentSubscriptionId !== "" &&
                claimHistoryList && (
                  <>
                    <Card sx={{ m: 4, p: 2 }}>
                      {claimHistoryList.content.length > 0 && (
                        <ClaimsHistory
                          customerName={
                            getCustomerForId(currentCustomerId).customer_name
                          }
                          subscriptionName={
                            getSubscriptionForId(currentSubscriptionId)
                              .product_name
                          }
                          claimHistoryList={claimHistoryList}
                          currentPage={currentPage}
                          pageSize={pageSize}
                          handlePageSizeChange={handlePageSizeChange}
                          handlePageChange={handlePageChange}
                          totalRows={totalRows}
                          displayRows={displayRows}
                        />
                      )}
                      {claimHistoryList.content.length === 0 && (
                        <Typography
                          variant="body1"
                          align="center"
                          color="GrayText"
                        >
                          No Claim Records Found !!
                        </Typography>
                      )}
                      {claimHistoryLoading && <ClaimsHistoryShimmer />}
                    </Card>
                    <Box textAlign="center" sx={{ mx: 8 }}>
                      <Button
                        variant="contained"
                        size="big"
                        sx={{ p: 2, mt: 2, mb: 10, minWidth: 200 }}
                        onClick={handleSubmit}
                        endIcon={<NavigateNextIcon />}
                        disabled={userHasEditAccess ? false : true}
                      >
                        Proceed to create claim
                      </Button>
                    </Box>
                  </>
                )}
            </Box>
          )}
        </Box>
      ) : (
        <PageAccessDenied />
      )}
      {alertOpen && (
        <SnackbarMessage
          severity={alertData.severity}
          message={alertData.message}
          onOpen={alertOpen}
          onCloseHandler={onCloseHandler}
        />
      )}
    </div>
  );
};

export default SearchCustomer;
