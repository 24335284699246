import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TitleDesc from "ui/components/utils/titledesc.component";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { NavigateBefore, Save } from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import { getClaimDetailsAPI } from "services/api/getclaimdetails-api-service";
import { FormatDateUtil } from "utils/formatdate";
import { verifyCompletionAPIService } from "services/api/verify-completion-api-service";
import {
  documentAcceptAPIService,
  documentRejectAPIService,
  documentViewAPIService,
} from "services/api/document-api-service";
import { capitalizeFirstLetter, getMIMEIcon } from "ui/components/utils/utils";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import { MESSAGES } from "../../../components/utils/message-constants";
import Spacer from "ui/components/utils/spacer";
import AgentRemarks from "./agent-remarks";
import { CLAIM_VERIFICATION_PAGE_ACCESS } from "ui/components/utils/role-constants";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import PageAccessDenied from "ui/components/page-access-denied/page-access-denied.component";
import ClaimNotReached from "ui/components/utils/claim-not-reached.component";
import { getVerificationTemplatesApiService } from "services/api/get-verification-templates-api-service";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.common.black,
  },
}));

const VerifyDocuments = ({
  onNextButtonClick,
  displayAlert,
  roleAndPrivilageMap,
  showLoadingBackdrop,
}) => {
  const dispatch = useDispatch();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [currentStage, setCurrentStage] = useState(null);
  const params = useParams();
  const [claimDetails, setClaimDetails] = useState("");
  const [refreshState, setRefreshState] = useState(false);
  const [recommendedPayout, setRecommendedPayout] = useState(0);
  const [agentRemarks, setAgentRemarks] = useState("");
  const [recommendation, setRecommendation] = useState({ id: "", name: "" });
  const [addNotesTemplates, setAddNotesTemplates] = useState(null);

  const [recommendedPayoutError, setRecommendedPayoutError] = useState(false);
  const [recommendationError, setRecommendationError] = useState(false);

  const verifyStageAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIM_VERIFICATION_PAGE_ACCESS
  );

  const [userHasViewAccess, setUserHasViewAccess] = useState(false);
  const [userHasListAccess, setUserHasListAccess] = useState(false);
  const [userHasEditAccess, setUserHasEditAccess] = useState(false);
  const [userHasWriteAccess, setUserHasWriteAccess] = useState(false);
  const [userHasImportAccess, setUserHasImportAccess] = useState(false);

  const [isCurrentStageInVerification, setIsCurrentStageInVerification] =
    useState(false);

  const [verifyDocumentDataExists, setVerifyDocumentDataExists] =
    useState(false);
  const [
    editVerifyDocumentDetailsInProgress,
    setEditVerifyDocumentDetailsInProgress,
  ] = useState(false);

  // LOADING STATE FOR API CALLS
  const claimDetailsLoading = useSelector(
    (state) => state.claimDetailsSlice.loading
  );
  const getVerificationDetailsLoading = useSelector(
    (state) => state.claimDetailsSlice.loading
  );
  const getUploadedDocumentLoading = useSelector(
    (state) => state.getUploadDocumentSlice.loading
  );
  // CONFIG STATE
  const recommendationList = useSelector(
    (state) => state.configSlice.recommendations
  );

  useEffect(() => {
    dispatch(getVerificationTemplatesApiService()).then((data) => {
      setAddNotesTemplates(data.payload);
    });
  }, []);

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    console.log("verifyStageAccessMap ", verifyStageAccessMap);

    if (verifyStageAccessMap.size > 0) {
      if (verifyStageAccessMap.get("READ") === true) {
        setUserHasViewAccess(true);
      }
      if (verifyStageAccessMap.get("LIST") === true) {
        setUserHasListAccess(true);
      }
      if (verifyStageAccessMap.get("EDIT") === true) {
        setUserHasEditAccess(true);
      }
      if (verifyStageAccessMap.get("WRITE") === true) {
        setUserHasWriteAccess(true);
      }
      if (verifyStageAccessMap.get("EDIT") === true) {
        setUserHasImportAccess(true);
      }
    }
  }, [verifyStageAccessMap]);

  var claimAPIDetails = {};
  var claimantDetails = {};
  var incidentDetails = {};
  var documentDetails = [];
  var verificationDetails = {};

  // SHOW LOADERS FOR API CALL
  useEffect(() => {
    if (
      claimDetailsLoading ||
      getVerificationDetailsLoading ||
      getUploadedDocumentLoading
    ) {
      setBackdropOpen(true);
    } else {
      setBackdropOpen(false);
    }
  }, [
    claimDetailsLoading,
    getVerificationDetailsLoading,
    getUploadedDocumentLoading,
  ]);

  useEffect(() => {
    console.log("refreshState API call", refreshState);
    dispatch(getClaimDetailsAPI({ claimId: params.claimId })).then((data) => {
      setClaimDetails(data);
      setRefreshState(false);

      setCurrentStage(data.payload?.claim?.stage.name ?? null);

      setEditVerifyDocumentDetailsInProgress(false);

      if (data.payload?.verification) {
        setVerifyDocumentDataExists(true);
      } else {
        setVerifyDocumentDataExists(false);
      }

      if (data.payload?.claim?.stage.name === "Verification") {
        setIsCurrentStageInVerification(true);
      } else {
        setIsCurrentStageInVerification(false);
      }
    });
  }, [refreshState]);

  if (claimDetails !== "") {
    claimAPIDetails = claimDetails.payload.claim;
    claimantDetails = claimDetails.payload.claimant;
    incidentDetails = claimDetails.payload.incident;
    documentDetails = claimDetails.payload.document
      ? claimDetails.payload.document.documents
      : [];
    verificationDetails = claimDetails.payload.verification;

    console.log("claimDetails", claimAPIDetails);
    console.log("claimantDetails", claimantDetails);
    console.log("incidentDetails", incidentDetails);
    console.log("documentDetails", documentDetails);
    console.log("verificationDetails", verificationDetails);
  }

  useEffect(() => {
    console.log("set verificationDetails ", verificationDetails);
    if (verificationDetails != {}) {
      if (verificationDetails?.recommendation) {
        setRecommendation(verificationDetails?.recommendation ?? "");
      }
      if (verificationDetails?.recommended_payout) {
        setRecommendedPayout(verificationDetails.recommended_payout ?? 0);
      }
      if (verificationDetails?.remarks) {
        setAgentRemarks(verificationDetails.remarks ?? "");
      }
    }
  }, [verificationDetails]);

  // NEXT/PREVIOUS/SAVE CLICK HANDLER
  const onClickHandler = (claimStage) => async (e) => {
    if (
      recommendedPayout === null ||
      recommendedPayout === "" ||
      recommendedPayout === undefined
    ) {
      setRecommendedPayoutError(true);
      setRecommendationError(true);
    } else if (recommendation.id === "" || recommendation.id === undefined) {
      setRecommendedPayoutError(false);
      setRecommendationError(true);
    } else {
      setRecommendedPayoutError(false);
      setRecommendationError(false);
      setBackdropOpen(true);
      const requestData = {
        claimId: params.claimId,
        requestBody: {
          potential_payout: 0,
          recommended_payout: recommendedPayout,
          recommendation: recommendation,
          remarks: agentRemarks,
        },
      };
      await dispatch(verifyCompletionAPIService(requestData)).then((data) => {
        console.log("VERIFY API RESPONSE", data.payload.status);
        setBackdropOpen(false);
        if (data.payload.status === 200) {
          setEditVerifyDocumentDetailsInProgress(false);
          setVerifyDocumentDataExists(true);
          if (claimStage != -1) {
            onNextButtonClick(claimStage, "next", {
              id: MESSAGES.CLAIM_STAGE_DECISION_ID,
              name: MESSAGES.CLAIM_STAGE_DECISION,
            });
          } else {
            displayAlert("success", MESSAGES.SAVED_SUCCESFULLY);
          }
        } else {
          displayAlert("error", MESSAGES.SAVE_FAILED);
        }
      });
    }
  };

  // DOCUMENT ACCEPT/REJECT/VIEW CLICK HANDLER
  const onDocumentActionClickHandler = (action, documentId) => async (e) => {
    console.log("action & documentId ", action, documentId, params.claimId);
    const requestData = {
      claimId: params.claimId,
      documentId: documentId,
      stage: "document",
    };
    if (action === "accept") {
      setBackdropOpen(true);
      await dispatch(documentAcceptAPIService(requestData)).then((data) => {
        console.log("ACCEPT API RESPONSE", data.payload.status);
        setBackdropOpen(false);
        if (data.payload.status === 200) {
          setRefreshState(true);
          displayAlert("success", MESSAGES.DOCUMENT_ACCPET_SUCCESS);
        } else {
          displayAlert("error", MESSAGES.ERROR_PLS_TRY_AGAIN);
        }
      });
    } else if (action === "reject") {
      setBackdropOpen(true);
      await dispatch(documentRejectAPIService(requestData)).then((data) => {
        console.log("REJECT API RESPONSE", data.payload.status);
        if (data.payload.status === 200) {
          setBackdropOpen(false);
          setRefreshState(true);
          displayAlert("success", MESSAGES.DOCUMENT_REJECT_SUCCESS);
        } else {
          displayAlert("error", MESSAGES.ERROR_PLS_TRY_AGAIN);
        }
      });
    } else if (action === "view") {
      setBackdropOpen(true);
      await dispatch(documentViewAPIService(requestData)).then((data) => {
        console.log("VIEW API RESPONSE", data.payload.status);
        setBackdropOpen(false);
        if (data.payload.status === 200) {
          window.open(data.payload.data.url, "_blank");
        } else {
          displayAlert("error", MESSAGES.ERROR_PLS_TRY_AGAIN);
        }
      });
    }
  };

  if (
    params.claimId !== null &&
    params.claimId !== undefined &&
    !claimDetailsLoading &&
    !backdropOpen &&
    !userHasViewAccess
  ) {
    return <PageAccessDenied />;
  }

  if (
    params.claimId === null ||
    params.claimId === undefined ||
    (!claimDetailsLoading &&
      currentStage !== null &&
      (currentStage === MESSAGES.CLAIM_STAGE_FILING ||
        currentStage === MESSAGES.CLAIM_STAGE_UPLOAD_DOC))
  ) {
    return <ClaimNotReached />;
  }

  if (claimDetailsLoading) {
    return <CommonBackDrop backdropOpen={backdropOpen} />;
  }

  return (
    <>
      {/* LOADER */}
      <CommonBackDrop backdropOpen={backdropOpen} />
      {claimDetails && (
        <div>
          <Card id="claimantinfo--main__card" sx={{ m: 3, p: 4 }}>
            <Typography variant="h6">Incident Details</Typography>
            <Box sx={{ m: 2, p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Type of Incident"
                    desc={
                      incidentDetails?.type?.name
                        ? incidentDetails.type?.name
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Cause of Incident"
                    desc={
                      incidentDetails?.cause?.name
                        ? incidentDetails.cause?.name
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc title="Hospital Details" desc="NA" />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Location"
                    desc={
                      incidentDetails?.location?.name
                        ? incidentDetails.location?.name
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Start Date"
                    desc={
                      incidentDetails?.start_date
                        ? FormatDateUtil.displayDate(incidentDetails.start_date)
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="End Date"
                    desc={
                      incidentDetails?.end_date
                        ? FormatDateUtil.displayDate(incidentDetails.end_date)
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="No. of Nights"
                    desc={
                      incidentDetails?.no_of_nights
                        ? incidentDetails?.no_of_nights
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Cover Earned"
                    desc={
                      incidentDetails?.cover_earned
                        ? incidentDetails?.cover_earned
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Potential Payout"
                    desc={
                      incidentDetails?.potential_payout
                        ? incidentDetails?.potential_payout
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Ex-Gratia"
                    desc={
                      claimAPIDetails?.is_ex_gratia
                        ? claimAPIDetails?.is_ex_gratia
                          ? "Yes"
                          : "No"
                        : "NA"
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>

          <Card id="claimantinfo--main__card" sx={{ m: 3, p: 4 }}>
            <Typography variant="h6">Claimant Details</Typography>
            <Box sx={{ m: 2, p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Claimant ID"
                    desc={
                      claimantDetails?.info?.id ? claimantDetails.info.id : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Claimant Name"
                    desc={
                      claimantDetails?.info?.name
                        ? claimantDetails.info.name
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Type"
                    desc={
                      claimantDetails?.type?.name
                        ? claimantDetails.type?.name
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Relationship"
                    desc={
                      claimantDetails?.relationship?.name
                        ? claimantDetails.relationship?.name
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Gender"
                    desc={
                      claimantDetails?.info?.gender
                        ? capitalizeFirstLetter(claimantDetails.info.gender)
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="DOB"
                    desc={
                      claimantDetails?.info?.dob
                        ? FormatDateUtil.displayDate(claimantDetails.info.dob)
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Age"
                    desc={
                      claimantDetails?.info?.age
                        ? claimantDetails.info.age
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="National ID Type"
                    desc={
                      claimantDetails?.info?.nid_type?.name
                        ? claimantDetails.info?.nid_type?.name
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="National ID No."
                    desc={
                      claimantDetails?.info?.nid
                        ? claimantDetails?.info.nid
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Location"
                    desc={
                      claimantDetails?.location?.name
                        ? claimantDetails.location?.name
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Primary Phone No."
                    desc={
                      claimantDetails?.info?.msisdn
                        ? claimantDetails.info.msisdn
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3} xl={2}>
                  <TitleDesc
                    title="Alternate Phone No."
                    desc={
                      claimantDetails?.info?.alt_msisdn
                        ? claimantDetails.info.alt_msisdn
                        : "NA"
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>

          {userHasListAccess &&
            documentDetails !== null &&
            documentDetails !== [] &&
            documentDetails.length > 0 && (
              <>
                <Typography sx={{ ml: 2, pl: 1 }} variant="h6">
                  Documents Uploaded
                </Typography>
                <Box sx={{ ml: 3, mt: 2, mr: 1, p: 2 }}>
                  <Grid container spacing={2}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell style={{ width: "10%" }}>
                              Name
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "10%" }}
                            >
                              Type
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "10%" }}
                            >
                              Channel
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "10%" }}
                            >
                              Remarks
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "10%" }}
                            >
                              Size
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "10%" }}
                            >
                              Uploaded By
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "10%" }}
                            >
                              Uploaded Date
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "10%" }}
                            >
                              View
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ width: "20%" }}
                            >
                              Options
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {documentDetails !== null &&
                            documentDetails !== [] &&
                            documentDetails.map((document) => (
                              <TableRow
                                key={document.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  style={{ width: "10%" }}
                                >
                                  {document.name}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  style={{ width: "10%" }}
                                >
                                  {document.type.name}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  style={{ width: "10%" }}
                                >
                                  {document.channel.name}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  style={{ width: "10%" }}
                                >
                                  {document.remarks ? document.remarks : "NA"}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  style={{ width: "10%" }}
                                >
                                  {document.filesize}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  style={{ width: "10%" }}
                                >
                                  {document.uploaded_by}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  style={{ width: "15%" }}
                                >
                                  {FormatDateUtil.init(document.uploaded_at)}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  style={{ width: "10%" }}
                                >
                                  <Tooltip title="View">
                                    <IconButton
                                      aria-label={"file"}
                                      size={"small"}
                                      color={"primary"}
                                      onClick={onDocumentActionClickHandler(
                                        "view",
                                        document.id
                                      )}
                                    >
                                      {getMIMEIcon(document.mime_type)}
                                    </IconButton>
                                  </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell
                                  align="left"
                                  style={{ width: "25%" }}
                                >
                                  {document.status ? (
                                    document.status === "ACCEPT" ? (
                                      <>
                                        <Tooltip title="Accepted">
                                          <IconButton
                                            aria-label="accept"
                                            size="medium"
                                            color="success"
                                          >
                                            <DoneIcon fontSize="inherit" />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <>
                                        <Tooltip title="Rejected">
                                          <IconButton
                                            aria-label="reject"
                                            size="medium"
                                            color="error"
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    )
                                  ) : (
                                    <>
                                      <Tooltip title="Accept">
                                        <IconButton
                                          aria-label="accept"
                                          size="medium"
                                          color="success"
                                          onClick={onDocumentActionClickHandler(
                                            "accept",
                                            document.id
                                          )}
                                        >
                                          <DoneIcon fontSize="inherit" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Reject">
                                        <IconButton
                                          aria-label="accept"
                                          size="medium"
                                          color="error"
                                          onClick={onDocumentActionClickHandler(
                                            "reject",
                                            document.id
                                          )}
                                        >
                                          <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </StyledTableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Box>
              </>
            )}

          <Card id="claimantinfo--main__card" sx={{ m: 3, p: 2 }}>
            <Box display="flex">
              <Typography variant="h6" component="div">
                Verification Details
              </Typography>
              <Spacer value={1} />
              {userHasEditAccess &&
                isCurrentStageInVerification &&
                verifyDocumentDataExists &&
                !editVerifyDocumentDetailsInProgress && (
                  <Tooltip title="Edit Verify Document Details">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={(e) => {
                        setEditVerifyDocumentDetailsInProgress(true);
                        setVerifyDocumentDataExists(false);
                      }}
                    >
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
            </Box>

            <Box sx={{ m: 2, p: 2 }}>
              <Grid container spacing={4}>
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled={
                        isCurrentStageInVerification
                          ? verifyDocumentDataExists
                            ? true
                            : false
                          : true
                      }
                      id="claimantinfo--primaryno__textfield"
                      label="Recommended Payout *"
                      name="approved-payout"
                      variant="outlined"
                      fullWidth
                      name="amount"
                      value={recommendedPayout}
                      error={recommendedPayoutError}
                      helperText={
                        recommendedPayoutError
                          ? "Enter Recommended Payout Amount"
                          : ""
                      }
                      onChange={(e) => {
                        // Allow only numbers
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        setRecommendedPayout(numericValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      error={recommendationError}
                      disabled={
                        isCurrentStageInVerification
                          ? verifyDocumentDataExists
                            ? true
                            : false
                          : true
                      }
                      fullWidth
                    >
                      <InputLabel id="claimantinfo--relationship__inputlabel">
                        Recommendation *
                      </InputLabel>
                      <Select
                        labelId="claimantinfo--relationship__selectlabel"
                        id="claimantinfo--relationship__select"
                        label="Recommendation *"
                        name="recommendation"
                        value={recommendation?.id || ""}
                        onChange={(e, key) => {
                          const data = {
                            id: key.props.id,
                            name: key.props.children,
                          };
                          console.log("Data ", data);
                          setRecommendation(data);
                        }}
                      >
                        {recommendationList.map((item) => (
                          <MenuItem name={item.id} value={item.id} id={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {recommendationError ? "Select Recommendation" : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {editVerifyDocumentDetailsInProgress && (
                    <Grid container spacing={10} sx={{ pt: 4 }}>
                      <Grid item xs={6} textAlign="right">
                        <Button
                          variant="outlined"
                          size="big"
                          sx={{ p: 1, m: 1, minWidth: 200 }}
                          onClick={onClickHandler(-1)}
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          size="big"
                          sx={{ p: 1, m: 1, minWidth: 200 }}
                          onClick={(e) => {
                            setEditVerifyDocumentDetailsInProgress(false);
                            setVerifyDocumentDataExists(true);
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </>
              </Grid>
            </Box>
          </Card>

          {/* AGENT REMARKS */}
          {addNotesTemplates !== null && (
            <Box sx={{ m: 3 }}>
              <AgentRemarks
                agentRemarks={agentRemarks}
                setAgentRemarks={setAgentRemarks}
                buttonStatus={isCurrentStageInVerification ? false : true}
                stageName="verification"
                recommendation={recommendation}
                addNotesTemplates={addNotesTemplates}
              />
            </Box>
          )}

          {/* NEXT AND PREVIOUS BUTTONS */}
          <Grid container spacing={4}>
            <Grid item xs={4} textAlign="right">
              <Button
                variant="contained"
                size="big"
                sx={{ p: 2, m: 4, minWidth: 200 }}
                onClick={() => onNextButtonClick(1, "previous")}
                startIcon={<NavigateBefore />}
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Button
                variant="contained"
                size="big"
                disabled={
                  currentStage === MESSAGES.CLAIM_STAGE_CLOSED ||
                  currentStage === MESSAGES.CLAIM_STAGE_CLOSURE
                    ? true
                    : isCurrentStageInVerification && userHasWriteAccess
                    ? false
                    : true
                }
                sx={{ p: 2, m: 4, minWidth: 200 }}
                onClick={onClickHandler(-1)}
                startIcon={<Save />}
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={4} textAlign="left">
              <Button
                variant="contained"
                size="big"
                disabled={
                  currentStage === MESSAGES.CLAIM_STAGE_CLOSED ||
                  currentStage === MESSAGES.CLAIM_STAGE_CLOSURE
                    ? true
                    : isCurrentStageInVerification && userHasWriteAccess
                    ? false
                    : true
                }
                sx={{ p: 2, m: 4, minWidth: 200 }}
                onClick={onClickHandler(3, "next")}
                endIcon={<NavigateNextIcon />}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default VerifyDocuments;
