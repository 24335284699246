import React from "react";
import { Alert, Snackbar } from "@mui/material";
import PropTypes from "prop-types";

const SnackbarMessage = ({
  severity,
  message,
  onOpen,
  onCloseHandler,
  vertical,
  horizontal,
}) => {

  if(vertical === ""){
    vertical = "top"
  }
  if(horizontal === ""){
    vertical = "center"
  }

  return (
    <div id="snack-bar-message">
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={onOpen}
        onClose={onCloseHandler}
        key={vertical + horizontal}
      >
        <Alert
          severity={severity}
          variant="filled"
          sx={{ width: "100%", mt: 10, mb: 10 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

SnackbarMessage.propTypes = {
  severity: PropTypes.string,
  message: PropTypes.string,
  onOpen: PropTypes.bool,
  onClose: PropTypes.func,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
};

SnackbarMessage.defaultProps = {
  severity: "info",
  message: "",
  vertical: "bottom",
  horizontal: "center",
};

export default SnackbarMessage;
