import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  TextField,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WorkIcon from "@mui/icons-material/Work";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "store/auth/auth-selectors";
import { fetchUserDetails } from "services/api/user-details-api-service";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import SnackbarMessage from "ui/components/utils/alert-message.component";

const roles = [
  {
    title: "Module A",
    responsibilities: [
      "Develop and maintain user interface",
      "Ensure cross-browser compatibility",
      "Collaborate with back-end developers for API integration",
    ],
  },
  {
    title: "Module B",
    responsibilities: [
      "Create responsive design",
      "Optimize application for performance",
      "Implement unit tests",
    ],
  },
];

export default function UserProfile() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({ severity: "info", message: "" });

  function displayAlert(type, message) {
    setAlertData((prevState) => ({
      ...prevState,
      severity: type,
      message: message,
    }));
    setAlertOpen(true);
  }

  const onCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  console.log("user ", user);

  useEffect(() => {
    if (user !== null && user.id !== null && user.id !== undefined) {
      setBackdropOpen(true);
      let userId = user.id;
      dispatch(fetchUserDetails({ userId })).then((data) => {
        if (data?.payload?.status === 200) {
          console.log("fetchUserDetails component response", data.payload.data);

          setBackdropOpen(false);
          setUserDetails(data?.payload?.data ?? null);
        } else {
          setBackdropOpen(false);
          displayAlert("error", "Failed to get info, pls try again later");
        }
      });
    }
  }, [user]);

  return (
    <>
      {/* Loader */}
      <CommonBackDrop backdropOpen={backdropOpen} />

      {alertOpen && (
        <SnackbarMessage
          severity={alertData.severity}
          message={alertData.message}
          onOpen={alertOpen}
          onCloseHandler={onCloseHandler}
          vertical="top"
          horizontal="center"
        />
      )}

      {userDetails !== null && (
        <Box sx={{ flexGrow: 1, p: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Box p={2}>
                <Avatar
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "0 auto",
                    marginBottom: "1rem",
                  }}
                >
                  <AccountCircleIcon style={{ fontSize: "80px" }} />
                </Avatar>
                {/* <Avatar
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "0 auto",
                    marginBottom: "1rem",
                  }}
                >
                  {user?.name?.charAt(0).toUpperCase()}
                </Avatar> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box p={2}>
                <Typography variant="h4">My Profile</Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="name"
                      label="Name *"
                      name="name"
                      fullWidth
                      disabled
                      value={userDetails?.user?.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="user_name"
                      label="User Name *"
                      name="user_name"
                      fullWidth
                      disabled
                      value={userDetails?.user?.username}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="phone"
                      label="Phone *"
                      name="phone"
                      fullWidth
                      disabled
                      value={userDetails?.user?.phone}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="email"
                      label="Email *"
                      name="email"
                      fullWidth
                      disabled
                      value={userDetails?.user?.email}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography sx={{ p: 2 }} variant="h4">
                Roles & Authentication
              </Typography>
              <Typography sx={{ pl: 2 }} color="grey">
                {userDetails?.user?.role_desc?.join(",")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
