import React from "react";
import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parse } from "date-fns";

const SearchClaimByDate = ({
  formDataValues,
  tempFormData,
  handleDataChange,
}) => {
  // const dateFormat = "dd/MM/yyyy";
  const dateFormat = "yyyy-MM-dd";

  const getValue = (key) => {
    const formData = formDataValues.find((item) => item.api_key === key);
    const formDataTemp = tempFormData.find((item) => item.api_key === key);
    if (
      formDataTemp !== null &&
      formDataTemp !== undefined &&
      formDataTemp.display_value !== "" &&
      formDataTemp.display_value !== null
    ) {
      return parse(formDataTemp.display_value, dateFormat, new Date());
    } else if (
      formData !== null &&
      formData !== undefined &&
      formData.display_value !== "" &&
      formData.display_value !== null
    ) {
      return parse(formData.display_value, dateFormat, new Date());
    } else {
      return null;
    }
  };

  const handleChange = (date) => {
    console.log("handleChange  date ", format(date, dateFormat));
    handleDataChange(
      "start_date",
      format(date, dateFormat),
      "Start Date",
      format(date, dateFormat)
    );
  };

  const handleEndDateChange = (date) => {
    console.log("handleChange  date ", format(date, dateFormat));
    handleDataChange(
      "end_date",
      format(date, dateFormat),
      "End Date",
      format(date, dateFormat)
    );
  };

  const handleFollowUpDateChange = (date) => {
    console.log("handleChange  date ", format(date, dateFormat));
    handleDataChange(
      "followup_date",
      format(date, dateFormat),
      "FollowUp Date",
      format(date, dateFormat)
    );
  };

  return (
    <>
      <Grid
        item
        xs={12}
        md={9}
        container
        spacing={2}
        style={{ padding: "16px" }}
      >
        <Grid container spacing={2} sx={{ pt: 3, pl: 2 }}>
          {/* First Row */}
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { fullWidth: true } }}
                format={dateFormat}
                label="Select Date"
                value={getValue("start_date")}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { fullWidth: true } }}
                format={dateFormat}
                label="End Date"
                value={getValue("end_date")}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { fullWidth: true } }}
                format={dateFormat}
                label="Follow Up Date"
                value={getValue("followup_date")}
                onChange={handleFollowUpDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchClaimByDate;
