import { CloudUpload, FileUpload, Edit } from "@mui/icons-material";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Spacer from "ui/components/utils/spacer";
import {
  saveUploadDocumentDetails,
  uploadDocumentDetails,
} from "services/api/upload-document-api-service";
import { useDispatch, useSelector } from "react-redux";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import { fetchUploadedDocuments } from "services/api/get-document-api-service";
import UploadDocumentTable from "./upload-document-table.component";
import UploadDocumentConfirmationDialog from "./upload-document-confirmation-dialog.component";
import { formatFileSize, getFileType } from "ui/components/utils/utils";
import AgentRemarks from "../agent-remarks";
import NavigationButtons from "../navigation-buttons";
import { useParams } from "react-router-dom";
import { MESSAGES } from "../../../../components/utils/message-constants";
import { getClaimDetailsAPI } from "services/api/getclaimdetails-api-service";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import { CLAIM_DOCUMENT_PAGE_ACCESS } from "ui/components/utils/role-constants";
import PageAccessDenied from "ui/components/page-access-denied/page-access-denied.component";
import ClaimNotReached from "ui/components/utils/claim-not-reached.component";

const UploadDocuments = ({
  onNextButtonClick,
  displayAlert,
  roleAndPrivilageMap,
  showLoadingBackdrop,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const claimId = params.claimId;

  const [files, setFiles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const [documentsList, setDocumentsList] = useState([]);
  const [documentName, setDocumentName] = useState("");

  const [documentType, setDocumentType] = useState({ id: "", name: "" });
  const [documentChannel, setDocumentChannel] = useState({ id: "", name: "" });

  const [documentRemarks, setDocumentRemarks] = useState("");
  const [disableSelectFiles, setDisableSelectFiles] = useState(true);

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(5);

  const [agentRemarks, setAgentRemarks] = useState("");

  const [currentStage, setCurrentStage] = useState(null);
  const [isCurrentStageInUploadDocuments, setIsCurrentStageInUploadDocuments] =
    useState(false);
  const [uploadDocumentDataExists, setUploadDocumentDataExists] =
    useState(false);
  const [
    editUploadDocumentDetailsInProgress,
    setEditUploadDocumentDetailsInProgress,
  ] = useState(false);

  const uploadDocumentStageAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIM_DOCUMENT_PAGE_ACCESS
  );

  const [userHasViewAccess, setUserHasViewAccess] = useState(false);
  const [userHasListAccess, setUserHasListAccess] = useState(false);
  const [userHasEditAccess, setUserHasEditAccess] = useState(false);
  const [userHasWriteAccess, setUserHasWriteAccess] = useState(false);
  const [userHasImportAccess, setUserHasImportAccess] = useState(false);

  // LOADING STATE FOR API CALLS
  const getUploadedDocumentLoading = useSelector(
    (state) => state.getUploadDocumentSlice.loading
  );
  const claimDetailsLoading = useSelector(
    (state) => state.claimDetailsSlice.loading
  );
  const uploadDocumentLoading = useSelector(
    (state) => state.uploadDocumentSlice.loading
  );

  // CONFIG STATE
  const documentTypeList = useSelector(
    (state) => state.configSlice.documentType
  );
  const documentChannelList = useSelector(
    (state) => state.configSlice.documentChannel
  );

  const fileObject = {
    id: "",
    type: "",
    link: "",
    name: "",
    filename: "",
    mime_type: "",
    channel: "",
    filesize: "",
    remarks: "",
    uploaded_by: "",
    uploaded_at: null,
    upload: true,
    tempId: null,
  };

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    console.log("uploadDocumentStageAccessMap ", uploadDocumentStageAccessMap);
    setBackdropOpen(true);
    if (uploadDocumentStageAccessMap.size > 0) {
      if (uploadDocumentStageAccessMap.get("READ") === true) {
        setUserHasViewAccess(true);
      }
      if (uploadDocumentStageAccessMap.get("LIST") === true) {
        setUserHasListAccess(true);
      }
      if (uploadDocumentStageAccessMap.get("EDIT") === true) {
        setUserHasEditAccess(true);
      }
      if (uploadDocumentStageAccessMap.get("WRITE") === true) {
        setUserHasWriteAccess(true);
      }
      if (uploadDocumentStageAccessMap.get("EDIT") === true) {
        setUserHasImportAccess(true);
      }
    }
  }, [uploadDocumentStageAccessMap]);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // UPLOAD DOCUMENT REQUEST DATA
  const handleDialogAccept = async () => {
    handleDialogClose();
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      var formData = new FormData();
      formData.append("document_name", file.name);
      formData.append("document_type", file.type.name);
      formData.append("channel", file.channel.name);
      formData.append("remarks", file.remarks);
      formData.append("filesize", file.filesize);
      formData.append("filename", file.filename);
      formData.append("file", file.file);

      formData.append("document_type_id", file.type.id);
      formData.append("channel_id", file.channel.id);

      // //TODO
      formData.append("other_document_type", "");

      console.log("formData", formData);

      // Call uploadDocumentAPI for each file
      await uploadDocumentAPI(formData, index, claimId);
    }

    setFiles([]);
    setDocumentsList([]);
    getUploadedDocumentsAPI(claimId);
  };

  const handleShowMore = () => {
    setItemsToShow((prev) => prev + 5);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDocumentName(value);
  };

  // ENABLE/DISABLE FILE SELECTION BASED ON VALIDATION
  useEffect(() => {
    if (
      documentType.name === "" ||
      documentName === "" ||
      documentChannel.name === ""
    ) {
      setDisableSelectFiles(true);
    } else {
      setDisableSelectFiles(false);
    }
  }, [documentType, documentName, documentChannel]);

  // NEXT/PREVIOUS/SAVE  BUTTON CLICK
  const handleClick = (claimStage) => async (e) => {
    e.preventDefault();
    saveUploadDocumentAPI(claimStage);
  };

  // NEXT/PREVIOUS  API CALL
  const saveUploadDocumentAPI = async (claimStage) => {
    try {
      console.log("saveUploadDocumentStageAPI: ");
      setBackdropOpen(true);

      let requestData = {
        remarks: agentRemarks,
      };

      const data = { requestData, claimId };
      const response = await dispatch(saveUploadDocumentDetails(data));
      const responsePayload = response.payload;
      console.log("saveUploadDocumentStageAPI response ", responsePayload);
      if (responsePayload.status === 200) {
        setBackdropOpen(false);

        if (claimStage !== -1) {
          onNextButtonClick(claimStage, "next", {
            id: MESSAGES.CLAIM_STAGE_VERIFY_DOC_ID,
            name: MESSAGES.CLAIM_STAGE_VERIFY_DOC,
          });
        } else {
          displayAlert("success", MESSAGES.SAVED_SUCCESFULLY);
        }
      } else {
        setBackdropOpen(false);
        displayAlert("error", MESSAGES.SAVE_FAILED);
      }
    } catch (error) {
      setBackdropOpen(false);

      console.error("Error fetching data:", error);
      displayAlert("error", MESSAGES.SAVE_FAILED);
    }
  };
  // GET UPLOADED DOCUMENTS API CALL METHOD
  const getUploadedDocumentsAPI = async (claimId) => {
    try {
      console.log("getUploadedDocumentsData: ", claimId);
      const data = { claimId };
      const response = await dispatch(fetchUploadedDocuments(data));
      const responsePayload = response.payload;
      console.log("getUploadedDocumentsData response ", responsePayload);

      if (responsePayload.status === 200) {
        console.log("getUploadedDocumentsData response ", responsePayload.data);
        setDocumentsList(
          responsePayload.data?.documents ? responsePayload.data.documents : []
        );
      } else {
        displayAlert("error", MESSAGES.ERROR_FETCHING);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      displayAlert("error", MESSAGES.ERROR_FETCHING);
    } finally {
      setBackdropOpen(false);
    }
  };
  // UPLOAD DOCUMENTS API CALL METHOD
  const uploadDocumentAPI = async (formData, index, claimId) => {
    try {
      console.log("uploadDocumentAPI: ", formData, index, claimId);
      let stage = "document";
      const data = { formData, index, claimId, stage };
      const response = await dispatch(uploadDocumentDetails(data));
      const responsePayload = response.payload;
      console.log("uploadDocumentAPI response ", responsePayload);

      if (responsePayload.status === 200) {
        console.log(
          "Document upload success for index ",
          responsePayload.index
        );
        displayAlert("success", MESSAGES.UPLOAD_SUCCESS);
      } else {
        displayAlert("error", MESSAGES.UPLOAD_FAILED);
        console.log("Document upload failed for index ", responsePayload.index);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      displayAlert("error", MESSAGES.UPLOAD_FAILED);
    }
  };

  // GET CLAIM DETAILS API CALL
  useEffect(() => {
    if (claimId !== null && claimId !== undefined) {
      dispatch(getClaimDetailsAPI({ claimId: claimId })).then((data) => {
        console.log("getClaimDetailsAPI ", data);
        console.log("getClaimDetailsAPI ", data.payload);

        setCurrentStage(data.payload?.claim?.stage.name ?? null);

        setAgentRemarks(data.payload?.document?.remarks ?? "");
        setEditUploadDocumentDetailsInProgress(false);

        if (data.payload?.document) {
          setUploadDocumentDataExists(true);
        } else {
          setUploadDocumentDataExists(false);
        }

        if (data.payload?.claim?.stage.name === "Document Collection") {
          setIsCurrentStageInUploadDocuments(true);
        } else {
          setIsCurrentStageInUploadDocuments(false);
        }
        setBackdropOpen(false);
      });
    }
  }, [claimId]);

  // GET UPLOADED DOCUMENTS API CALL
  useEffect(() => {
    console.log("userHasListAccess ", userHasListAccess);
    if (userHasListAccess && claimId !== null && claimId !== undefined) {
      getUploadedDocumentsAPI(claimId);
    }
  }, [userHasListAccess]);

  // SHOW LOADERS FOR API CALL
  useEffect(() => {
    if (
      claimDetailsLoading ||
      uploadDocumentLoading ||
      getUploadedDocumentLoading
    ) {
      setBackdropOpen(true);
    } else {
      setBackdropOpen(false);
    }
  }, [claimDetailsLoading, uploadDocumentLoading, getUploadedDocumentLoading]);

  // CALLED WHEN IMAGE IS SELECTED
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles?.length) {
        const tempDateID = Date.now();
        setFiles((previousFiles) => [
          ...previousFiles,
          ...acceptedFiles.map((file) => ({
            file, // store the original file
            preview: URL.createObjectURL(file),
            type: documentType,
            link: "",
            name: documentName,
            filename: file.name,
            mime_type: getFileType(
              file.name.toLowerCase(),
              file.type.toLowerCase()
            ),
            channel: documentChannel,
            filesize: formatFileSize(file.size),
            remarks: documentRemarks,
            uploaded_by: "",
            uploaded_at: "",
            tempId: tempDateID,
          })),
        ]);

        setDocumentsList((previousFiles) => [
          ...previousFiles,
          ...acceptedFiles.map((file) => {
            console.log("onDrop Selected file ", file);
            console.log("onDrop Selected file documentType ", documentType);
            console.log(
              "onDrop Selected file documentChannel ",
              documentChannel
            );

            return {
              ...fileObject,
              type: documentType,
              link: "",
              name: documentName,
              filename: file.name,
              mime_type: getFileType(
                file.name.toLowerCase(),
                file.type.toLowerCase()
              ),
              channel: documentChannel,
              filesize: formatFileSize(file.size),
              remarks: documentRemarks,
              uploaded_by: "",
              uploaded_at: "",
              upload: true,
              tempId: tempDateID,
            };
          }),
        ]);
      }
      if (rejectedFiles?.length) {
        displayAlert(
          "error",
          "Kindly check ur file size if it is less than 5 MB and format"
        );
      }
    },
    [documentName, documentType, documentChannel, documentRemarks, setFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [],
      "application/pdf": [],
    },
    // TODO need to remove in future
    maxFiles: 1,
    maxSize: 1024 * 5000,
    onDrop,
  });

  // Revoke the data uris to avoid memory leaks
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  // ON DELETE BUTTON CLICK
  const removeFile = (tempId) => {
    console.log("tempId", tempId);
    setFiles((files) => files.filter((file) => file.tempId !== tempId));
    setDocumentsList((previousFiles) =>
      previousFiles.filter((file) => file.tempId !== tempId)
    );
  };

  // ON UPLOAD BUTTON CLICK
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (documentType === "") {
      displayAlert("info", "Please select document type");
      return;
    } else if (documentName === "") {
      displayAlert("info", "Please enter a document name");
      return;
    } else if (documentChannel === "") {
      displayAlert("info", "Please select document channel");
      return;
    }
    if (files.length === 0) return;
    setOpenDialog(true);
  };

  // SHOW WHEN NO ACCESS
  if (
    claimId !== null &&
    claimId !== undefined &&
    !claimDetailsLoading &&
    !getUploadedDocumentLoading &&
    !backdropOpen &&
    !userHasViewAccess
  ) {
    return <PageAccessDenied />;
  }

  // SHOW WHEN CLAIM STAGE IS NOT REACHED
  if (
    claimId === null ||
    claimId === undefined ||
    (!claimDetailsLoading &&
      !getUploadedDocumentLoading &&
      currentStage !== null &&
      currentStage === MESSAGES.CLAIM_STAGE_FILING)
  ) {
    return <ClaimNotReached />;
  }

  // SHOW LOADERS FOR API CALL
  if (claimDetailsLoading && getUploadedDocumentLoading) {
    return <CommonBackDrop backdropOpen={backdropOpen} />;
  }

  // VIEW DISPLAY
  if (!claimDetailsLoading && !getUploadedDocumentLoading) {
    return (
      <Box sx={{ ml: 2.5, mr: 2.5 }}>
        {/* LOADER */}
        <CommonBackDrop backdropOpen={backdropOpen} />

        {/* Confirmation Dialog */}
        <UploadDocumentConfirmationDialog
          dialogOpen={openDialog}
          dialogAccept={handleDialogAccept}
          closeDialog={handleDialogClose}
        />

        {/* UPLOADED DOCUMENTS TABLE  */}
        {!getUploadedDocumentLoading &&
          documentsList !== null &&
          documentsList.length > 0 && (
            <>
              <Typography variant="h6" component="div" sx={{ pb: 2 }}>
                Uploaded Documents
              </Typography>
              <UploadDocumentTable
                documentsList={documentsList}
                handleShowMore={handleShowMore}
                itemsToShow={itemsToShow}
                removeFile={removeFile}
                displayAlert={displayAlert}
                stage={"document"}
              />
            </>
          )}

        {files.length !== 0 && (
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              startIcon={<FileUpload />}
            >
              Upload Files
            </Button>
          </Grid>
        )}

        {/* UPLOAD DOCUMENTS FORM*/}
        {userHasWriteAccess && userHasImportAccess && (
          <Box sx={{ mt: 3, mb: 2, p: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="left">
                <Card sx={{ p: 2 }}>
                  <CardContent>
                    <Box display="flex">
                      <Typography variant="h6" component="div">
                        Upload Documents
                      </Typography>
                      <Spacer value={1} />
                      {uploadDocumentDataExists &&
                        isCurrentStageInUploadDocuments &&
                        userHasEditAccess &&
                        !editUploadDocumentDetailsInProgress && (
                          <Tooltip title="Edit Upload Document Details">
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={(e) => {
                                setEditUploadDocumentDetailsInProgress(true);
                                setUploadDocumentDataExists(false);
                              }}
                            >
                              <Edit fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Box>

                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid item xs={12} md={4}>
                        <FormControl
                          disabled={
                            isCurrentStageInUploadDocuments
                              ? uploadDocumentDataExists
                                ? true
                                : false
                              : true
                          }
                          fullWidth
                        >
                          <InputLabel id="claimantinfo--relationship__inputlabel">
                            Document Type *
                          </InputLabel>
                          <Select
                            labelId="claimantinfo--relationship__selectlabel"
                            id="claimantinfo--relationship__select"
                            label="Document Type"
                            name="document_type"
                            value={documentType?.id || ""}
                            onChange={(e, key) => {
                              const data = {
                                id: key.props.id,
                                name: key.props.children,
                              };
                              setDocumentType(data);
                            }}
                          >
                            {documentTypeList.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                id={item.id}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* <Spacer value={1} /> */}
                      <Grid item xs={12} md={4}>
                        <TextField
                          // disabled={uploadDocumentDataExists ? true : false}
                          disabled={
                            isCurrentStageInUploadDocuments
                              ? uploadDocumentDataExists
                                ? true
                                : false
                              : true
                          }
                          id="claimantinfo--location__textfield"
                          label="Document Name *"
                          name="document_name"
                          variant="outlined"
                          value={documentName}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl
                          disabled={
                            isCurrentStageInUploadDocuments
                              ? uploadDocumentDataExists
                                ? true
                                : false
                              : true
                          }
                          fullWidth
                        >
                          <InputLabel id="claimantinfo--relationship__inputlabel">
                            Document Channel *
                          </InputLabel>
                          <Select
                            labelId="claimantinfo--relationship__selectlabel"
                            id="claimantinfo--relationship__select"
                            label="Document Channel"
                            name="document_channel"
                            // value={documentChannel}
                            // onChange={handleChange}
                            value={documentChannel?.id || ""}
                            onChange={(e, key) => {
                              const data = {
                                id: key.props.id,
                                name: key.props.children,
                              };
                              setDocumentChannel(data);
                            }}
                          >
                            {documentChannelList.map((item) => (
                              <MenuItem
                                key={item.id}
                                value={item.id}
                                id={item.id}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <TextField
                          // disabled={uploadDocumentDataExists ? true : false}
                          disabled={
                            isCurrentStageInUploadDocuments
                              ? uploadDocumentDataExists
                                ? true
                                : false
                              : true
                          }
                          id="claimantinfo--location__textfield"
                          label="Document Remarks"
                          name="fileName"
                          variant="outlined"
                          value={documentRemarks}
                          onChange={(e) => setDocumentRemarks(e.target.value)}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Card sx={{ backgroundColor: "#f7f7f7", p: 4 }}>
                          <div
                            {...getRootProps({
                              className: "dropzone",
                              ...(disableSelectFiles && {
                                style: { pointerEvents: "none", opacity: 0.5 },
                              }),
                            })}
                          >
                            <input
                              {...getInputProps()}
                              disabled={disableSelectFiles}
                            />
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <CloudUpload color="primary" />
                              <Spacer value={2} />
                              {isDragActive ? (
                                <Typography variant="body1">
                                  <strong>Drop the files here ...</strong>
                                </Typography>
                              ) : (
                                <Box display="flex">
                                  <Typography variant="body2">
                                    <strong>
                                      Drag & drop files here, or click to select
                                      files
                                    </strong>
                                  </Typography>
                                  <Spacer value={0.2} />
                                  <Typography variant="body2" color="primary">
                                    less than 5 MB
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </div>
                        </Card>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}

        {/* AGENT REMARKS */}
        <AgentRemarks
          agentRemarks={agentRemarks}
          setAgentRemarks={setAgentRemarks}
          buttonStatus={isCurrentStageInUploadDocuments ? false : true}
        />

        {/* NEXT AND PREVIOUS BUTTONS */}
        <NavigationButtons
          onNextButtonClick={onNextButtonClick}
          handleClick={handleClick}
          userHasWriteAccess={userHasWriteAccess}
          currentStage={currentStage}
          isCurrentStageInUploadDocuments={isCurrentStageInUploadDocuments}
        />
      </Box>
    );
  }
};

export default UploadDocuments;
