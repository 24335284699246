import React, { useState } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";

const SearchClaimByClaimDetails = ({
  policyDetailsList,
  agentDetailList,
  formDataValues,
  tempFormData,
  handleDataChange,
}) => {
  // LOADING STATE FROM REDUX

  console.log("Search By Claim Details formDataValues ", formDataValues);
  console.log("Search By Claim Details agentDetailList ", agentDetailList);

  const stageList = useSelector((state) => state.configSlice.stages);
  const callOutComeList = useSelector(
    (state) => state.configSlice.callOutcomes
  );
  const recommendationList = useSelector(
    (state) => state.configSlice.recommendations
  );
  const officersList = agentDetailList;

  const automaticClaimList = [
    { id: "yes", name: "Yes" },
    { id: "no", name: "No" },
  ];

  const getValue = (key) => {
    const formData = formDataValues.find((item) => item.api_key === key);
    const formDataTemp = tempFormData.find((item) => item.api_key === key);
    if (formDataTemp !== null && formDataTemp !== undefined) {
      return formDataTemp.display_value;
    } else if (formData !== null && formData !== undefined) {
      return formData.display_value;
    } else {
      return "";
    }
  };

  const handlePolicyChange = (event) => {
    const { name, value } = event.target;
    const policyDetails = policyDetailsList.find(
      (policy) => policy.product_name + " (" + policy.platform + ")" === value
    );
    console.log("handlePolicyChange ", policyDetails);
    if (policyDetails !== null) {
      handleDataChange(
        name,
        policyDetails.product_id,
        "Policy",
        policyDetails.product_name + " (" + policyDetails.platform + ")"
      );
    }
  };

  const handleChange = (displayName, displayValue) => (event, key) => {
    const { name, value } = event.target;
    console.log("handleChange  name ", name);
    console.log("handleChange  value ", value);
    console.log("handleChange  key ", key?.props?.id);
    const apiValue = key?.props?.id ? key.props?.id : value;
    // const apiValue = value;

    if (displayValue !== "") {
      handleDataChange(name, apiValue, displayName, displayValue);
    } else {
      handleDataChange(name, apiValue, displayName, value);
    }
  };

  const handleClaimStatusChange =
    (displayName, displayValue) => (event, key) => {
      const { name, value } = event.target;
      console.log("handleChange  name ", name);
      console.log("handleChange  value ", value);
      console.log("handleChange  key ", key?.props?.id);
      // const apiValue = key?.props?.id ? key.props?.id : value;
      const apiValue = value;

      if (displayValue !== "") {
        handleDataChange(name, apiValue, displayName, displayValue);
      } else {
        handleDataChange(name, apiValue, displayName, value);
      }
    };

  return (
    <>
      {/* Form Fields */}
      <Grid
        item
        xs={12}
        md={9}
        container
        spacing={0}
        style={{ padding: "16px" }}
      >
        <Grid container spacing={2} sx={{ pt: 3, pl: 2 }}>
          {/* First Row */}
          <Grid item xs={12} md={4}>
            <TextField
              name="claim_id"
              size="small"
              label="Claim Id"
              value={getValue("claim_id")}
              onChange={handleChange("Claim Id", "")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              name="msisdn"
              type="number"
              size="small"
              label="MSISDN"
              value={getValue("msisdn")}
              onChange={handleChange("MSISDN", "")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="search-current-stage">Current Stage</InputLabel>
              <Select
                labelId="search-current-stage"
                name="stage"
                value={getValue("stage")}
                // onChange={handleChange("Stage", "")}
                onChange={handleClaimStatusChange("Stage", "")}
                label="Current Stage"
              >
                {stageList.map((item) => (
                  <MenuItem key={item.id} value={item.name} id={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Second Row */}
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="search-policy">Policy</InputLabel>
              <Select
                labelId="search-policy"
                name="policy"
                value={getValue("policy")}
                onChange={handlePolicyChange}
                label="Policy"
              >
                {policyDetailsList.map((item) => (
                  <MenuItem
                    key={item.product_id}
                    value={item.product_name + " (" + item.platform + ")"}
                  >
                    <span>
                      {item.product_name}
                      <strong> ({item.platform})</strong>
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="search-call-outcome">Call Outcome</InputLabel>
              <Select
                labelId="search-call-outcome"
                name="call_outcome"
                value={getValue("call_outcome")}
                onChange={handleChange("Call Outcome", "")}
                label="Call Outcome"
              >
                {callOutComeList.map((item) => (
                  <MenuItem key={item.id} value={item.name} id={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="search-automatic-claim">
                Automatic claim
              </InputLabel>
              <Select
                labelId="search-automatic-claim"
                name="automatic_claim"
                value={getValue("automatic_claim")}
                onChange={handleChange("Automatic Claim", "")}
                label="Automatic claim"
              >
                {automaticClaimList.map((item) => (
                  <MenuItem key={item.id} value={item.name} id={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

          {/* Third Row */}
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="search-recommendation">Recommendation</InputLabel>
              <Select
                labelId="search-recommendation"
                name="recommendation"
                value={getValue("recommendation")}
                onChange={handleChange("Recommendation", "")}
                label="Recommendation"
              >
                {recommendationList.map((item) => (
                  <MenuItem key={item.id} value={item.name} id={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="search-officers">Officers</InputLabel>
              <Select
                labelId="search-officers"
                name="officer"
                value={getValue("officer")}
                onChange={handleChange("Officers", "")}
                label="Officers"
              >
                {officersList !== null &&
                  officersList.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.user.name}
                      id={item.user.id}
                    >
                      {item.user.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchClaimByClaimDetails;
