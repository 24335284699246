import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const fetchUploadedDocumentAPIService = createAsyncThunk(
  "getUploadDocumentSlice/fetchUploadedDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const { claimId } = data;
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/document`,
      });
      console.log("fetchUploadedDocumentAPIService success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const documentAcceptAPIService = createAsyncThunk(
  "document/acceptAPIService",
  async (data, { rejectWithValue }) => {
    const { claimId, documentId } = data;
    console.log("claimId", claimId);
    console.log("documentId", documentId);

    try {
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/document/${documentId}/accept`,
      });
      console.log("documentAcceptAPIService success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const documentRejectAPIService = createAsyncThunk(
  "document/rejectAPIService",
  async (data, { rejectWithValue }) => {
    const { claimId, documentId } = data;
    console.log("claimId", claimId);
    console.log("documentId", documentId);

    try {
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/document/${documentId}/reject`,
      });
      console.log("documentRejectAPIService success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const documentViewAPIService = createAsyncThunk(
  "document/documentViewAPIService",
  async (data, { rejectWithValue }) => {
    try {
      const { claimId, documentId, stage } = data;
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/${stage}/${documentId}/view`,
        // responseType: "blob",
      });
      console.log("documentViewAPIService Service success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
