import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const getCallLogsApiService = createAsyncThunk(
  "getCallLogs/ApiService",
  async (data, { rejectWithValue }) => {
    console.log("getCallLogsApiService", data);
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${data}/calllog`,
      });
      console.log("getCallLogsApiService success", response);
      if (response.status === 200) {
        return { status: response.status, data: response.data };
      } else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCallLogsApiService = createAsyncThunk(
  "addCallLogs/ApiService",
  async (data, { rejectWithValue }) => {
    console.log("addCallLogsApiService", data);
    try {
      const { claimId, requestBody } = data;

      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/calllog`,
        data: requestBody,
      });
      console.log("addCallLogsApiService success", response);
      if (response.status === 200) {
        return { status: response.status, data: response.data };
      } else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
