import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { MESSAGES } from "./message-constants";
import Spacer from "./spacer";
import { Download } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { getClaimsFormConfigAPIService } from "services/api/getconfig-api-service";
import { useDispatch } from "react-redux";
import CommonBackDrop from "./backdrop.component";
const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DownloadClaimFormDialog({ openDialog, onClose }) {
  const dispatch = useDispatch();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [open, setOpen] = React.useState(openDialog);
  const [documentList, setDocumentList] = React.useState([]);

  console.log("DownloadClaimFormDialog ", openDialog);

  React.useEffect(() => {
    if (open) {
      setBackdropOpen(true);
      dispatch(getClaimsFormConfigAPIService("")).then((responseData) => {
        if (responseData.payload.status === 200) {
          setBackdropOpen(false);
          console.log(
            "download claim form response ",
            responseData.payload.data
          );
          const documentArr = [];
          responseData?.payload?.data?.map((rList, index) => {
            let objectData = {
              id: rList.id,
              name: rList.name,
              description: rList.description,
              url: rList.url,
              type: rList.type,
              notification: rList.notification,
            };
            documentArr.push(objectData);
          });
          setDocumentList(documentArr);
        } else {
          setBackdropOpen(false);
        }
      });
    }
  }, [open]);

  const buttonClickHandler = (documentUrl) => {
    console.log("Download clicked for: ", documentUrl);
    window.open(documentUrl, "_blank");
  };

  const onCloseDialog = () => {
    setOpen(false);
    onClose();
  };

  return (
    <React.Fragment>
      {/* LOADER */}
      <CommonBackDrop backdropOpen={backdropOpen} />
      {!backdropOpen && documentList && documentList.length > 0 && (
        <BootstrapDialog
          onClose={onCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            align="center"
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
          >
            Download Claim Form
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            {documentList.length > 0 &&
              documentList.map((document, index) => (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography color="primary" variant="body1">
                      {document.name}
                    </Typography>
                    <Tooltip title="Download">
                      <IconButton
                        aria-label="download"
                        size="large"
                        color="primary"
                        onClick={() => {
                          buttonClickHandler(document.url);
                        }}
                      >
                        <Download />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography gutterBottom variant="body1">
                    {document.description}
                  </Typography>
                </>
              ))}
          </DialogContent>
        </BootstrapDialog>
      )}
    </React.Fragment>
  );
}
