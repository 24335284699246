import { authReducer } from "store/auth/auth-slice";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import searchReducer from "./search-claim/searchclaim-slice";
import customerReducer from "./customer/customer-slice";
import subscriptionsReducer from "./subscriptions/subscriptions-slice";
import searchClaimByIdReducer from "./search-claim/searchclaim-by-claim-id-slice";
import beneficiaryDetailsReducer from "./beneficiary-details/beneficiary-details-slice";
import paymentHistoryReducer from "./payment-history/payment-history-slice";
import getPolicyDetailsReducer from "./policy/getpolicy-details-slice";
import searchClaimGetAgentDetails from "./search-claim/searchclaim-get-agent-details";
import assignClaimToAgentSlice from "./search-claim/searchclaim-assign-claim";
import uploadDocumentReducer from "./upload-document/upload-document-slice";
import communicationReducer from "./communication/communication-slice";
import getuploadDocumentReducer from "./upload-document/getupload-document-slice";
import getClaimsHistoryReducer from "./claims-history/claims-history-slice";
import getPreSignedDocumentURLReducer from "./upload-document/get-presigned-document-url-slice";
import claimDetailsReducer from "./claim-details/getclaim-details-slice";
import decisionReducer from "./decision/getdecision-details-slice";
import configReducer from "./config/config-slice";
import payoutReducer from "./payout/payout-slice";
import changeClaimStatusReducer from "./claim-status/change-claim-status-slice";
import claimClosureReducer from "./closure/claim-closure-slice";

const rootReducer = combineReducers({
  authSlice: authReducer,
  configSlice: configReducer,
  searchClaimSlice: searchReducer,
  customerSlice: customerReducer,
  subscriptionsSlice: subscriptionsReducer,
  searchClaimByIdSlice: searchClaimByIdReducer,
  beneficiaryDetailsSlice: beneficiaryDetailsReducer,
  paymentHistorySlice: paymentHistoryReducer,
  getPolicyDetailsSlice: getPolicyDetailsReducer,
  getAgentDetailSlice: searchClaimGetAgentDetails,
  assignClaimSlice: assignClaimToAgentSlice,
  uploadDocumentSlice: uploadDocumentReducer,
  communicationSlice: communicationReducer,
  getUploadDocumentSlice: getuploadDocumentReducer,
  getClaimsHistorySlice: getClaimsHistoryReducer,
  claimDetailsSlice: claimDetailsReducer,
  decisionSlice: decisionReducer,
  payoutSlice: payoutReducer,
  changeClaimStatusSlice: changeClaimStatusReducer,
  claimClosureSlice: claimClosureReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["authSlice"],
};

const persistedReducers = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
