export const MESSAGES = {
  CLAIM_FILING_STAGE_SUCCESS:
    "Claim Filing completed. Moving to Upload Documents stage.",
  UPLOAD_DOCUMENT_STAGE_SUCCESS:
    "Uploading Documents completed. Moving to Verification stage.",
  VERIFICATION_STAGE_SUCCESS:
    "Verification completed. Moving to Decision stage.",
  DECISION_STAGE_SUCCESS: "Decision completed. Moving to Communication stage.",
  COMMUNICATION_STAGE_SUCCESS:
    "Communication completed. Moving to Payout stage.",
  PAYOUT_STAGE_SUCCESS: "Payout completed. Moving to Closure stage.",
  CLOSURE_STAGE_SUCCESS: "An error occurred. Please try again later.",
  SAVED_SUCCESFULLY: "Saved Succesfuly",
  SAVE_FAILED: "Failed to save details pls try again later",
  ERROR_PLS_TRY_AGAIN: "Something went wrong pls try again later",
  MESSAGE_SUCCESS: "Message sent succesfully",
  ERROR_FETCHING: "Error fetching details",
  UPLOAD_SUCCESS: "Documents uploaded succesfully",
  UPLOAD_FAILED: "Failed to upload document pls try again later",
  DOCUMENT_ACCPET_SUCCESS: "Document Accepted succesfully",
  DOCUMENT_REJECT_SUCCESS: "Document Rejected succesfully",

  LOGS_ADDED_SUCCESS: "Added logs succesfully.",
  LOGS_ADDED_ERROR: "Error adding logs",

  //KH CLAIM FORM DOCUMENTS
  KH_CLAIM_DOCUMENT_ONE:
    "https://s3.ap-south-1.amazonaws.com/assets.milvik.in/cambodia/CRF_Claim_Request_Form.pdf",
  KH_CLAIM_DOCUMENT_TWO:
    "https://s3.ap-south-1.amazonaws.com/assets.milvik.in/cambodia/CRF_Claim_Request_Form_Bima_Health.pdf",

  //GH CLAIM FORM DOCUMENTS
  GH_CLAIM_DOCUMENT_ONE:
    "https://assets-gh.milvikafrica.io/claim/forms/AT_Hospital_Support_Claims_Form.docx",
  GH_CLAIM_DOCUMENT_TWO:
    "https://assets-gh.milvikafrica.io/claim/forms/B_HEALTH_Claims_Form.docx",
  GH_CLAIM_DOCUMENT_THREE:
    "https://assets-gh.milvikafrica.io/claim/forms/B-LIFE_Accidental_Admission_Claims_Form.doc",
  GH_CLAIM_DOCUMENT_FOUR:
    "https://assets-gh.milvikafrica.io/claim/forms/BIMA_Life_Claim_Form.docx",

  CLAIM_STAGE_FILING_ID: "claim_filing",
  CLAIM_STAGE_UPLOAD_DOC_ID: "document_collection",
  CLAIM_STAGE_VERIFY_DOC_ID: "verification",
  CLAIM_STAGE_DECISION_ID: "decision",
  CLAIM_STAGE_COMMUNICATION_ID: "communication",
  CLAIM_STAGE_PAYOUT_ID: "payout",
  CLAIM_STAGE_CLOSURE_ID: "closure",
  CLAIM_STAGE_CLOSED_ID: "closed",

  CLAIM_STAGE_FILING: "Claim Filing",
  CLAIM_STAGE_UPLOAD_DOC: "Document Collection",
  CLAIM_STAGE_VERIFY_DOC: "Verification",
  CLAIM_STAGE_DECISION: "Decision",
  CLAIM_STAGE_COMMUNICATION: "Communication",
  CLAIM_STAGE_PAYOUT: "Payout",
  CLAIM_STAGE_CLOSURE: "Closure",
  CLAIM_STAGE_CLOSED: "Closed",
};
