import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Checkbox,
  Chip,
  Grid,
  Tooltip,
  ListItemText,
} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import {
  CLAIMS_ASSIGN_ACCESS,
  CLAIMS_DOWNLOAD_ACCESS,
  CLAIMS_FILTER_ACCESS,
  CLAIMS_SEARCH_ACCESS,
} from "ui/components/utils/role-constants";
import useRolesAndPrivileges from "hooks/useRolesAndPermissions";

import FilterListIcon from "@mui/icons-material/FilterList";
import "rsuite/dist/rsuite.min.css";
import {
  DropDownContainer,
  StyledDropdown,
} from "./searchclaim-nested-dropdown-container";
import { ButtonToolbar, Dropdown } from "rsuite";
import { CategoryList } from "./searchclaim-constants";
import { SearchClaimFilterDialog } from "./searchclaim-filter-dialog.component";
import {
  assignClaimToAgent,
  fetchAgentDetails,
} from "services/api/searchclaims-api-service";
import { useDispatch } from "react-redux";
import CommonBackDrop from "ui/components/utils/backdrop.component";

const categories = CategoryList;

const SearchClaimHeader = ({
  formDataValues,
  handleFormDataValueChange,
  selectedColumns,
  handleColumnChange,
  columns,
  onRefresh,
  selectedClaimID,
  hanldeDownload,
  pageSize,
}) => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [formData, setFormData] = useState(formDataValues);
  const [agentDetailList, setAgentDetailList] = useState(null);

  const roleAndPrivilageMap = useRolesAndPrivileges();

  const searchClaimFilterAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIMS_FILTER_ACCESS
  );
  const searchClaimAssignAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIMS_ASSIGN_ACCESS
  );
  const searchClaimDownloadAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIMS_DOWNLOAD_ACCESS
  );

  const [userHasFilterAccess, setUserHasFilterAccess] = useState(false);
  const [userHasAssignAccess, setUserHasAssignAccess] = useState(false);
  const [userHasDownloadAccess, setUserHasDownloadAccess] = useState(false);

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    console.log("searchClaimFilterAccessMap ", searchClaimFilterAccessMap);

    if (searchClaimFilterAccessMap.size > 0) {
      if (searchClaimFilterAccessMap.get("READ") === true) {
        setUserHasFilterAccess(true);
      }
    }
    if (searchClaimAssignAccessMap.size > 0) {
      if (searchClaimAssignAccessMap.get("WRITE") === true) {
        setUserHasAssignAccess(true);
      }
    }
    if (searchClaimDownloadAccessMap.size > 0) {
      if (searchClaimDownloadAccessMap.get("EXPORT") === true) {
        setUserHasDownloadAccess(true);
      }
    }
  }, [
    searchClaimFilterAccessMap,
    searchClaimAssignAccessMap,
    searchClaimDownloadAccessMap,
  ]);

  // Fetch Agent data from API
  const fetchAgentData = async () => {
    try {
      const response = await dispatch(fetchAgentDetails());
      console.log("fetchAgentData: ", response);
      if (response.payload.status === 200) {
        console.log("fetchAgentData: payload ", response.payload.data);
        setAgentDetailList(response.payload.data);
      }
      console.log("fetchAgentData response ", agentDetailList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Assign claim to agent API
  const assignClaimToAgentData = async (data) => {
    try {
      console.log("assignClaimToAgentData: ", data);
      const response = await dispatch(assignClaimToAgent(data));
      const responsePayload = response.payload;
      setBackdropOpen(false);
      onRefresh(false);
      console.log("assignClaimToAgentData response ", responsePayload);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch data when component mounts or when currentPage/pageSize/formData changes
  useEffect(() => {
    setFormData(formDataValues);
    console.log("Get Agent formDataValues ", formDataValues, formData);
  }, [formDataValues]);

  // Fetch data when component mounts or when currentPage/pageSize/formData changes
  useEffect(() => {
    // setFormData(formDataValues);
    console.log("Get Agent API call ", formDataValues, formData);
    fetchAgentData();
  }, []);

  // Filter dialog open/close/clear
  const handleDialogOpen = () => {
    console.log("filter dialog click");
    setDialogOpen(true);
  };
  const handleDialogDismiss = () => {
    console.log("dismiss dialog");
    setDialogOpen(false);
  };
  const handleDialogClear = () => {
    console.log("clear dialog");
    setFormData([]);
    handleFormDataValueChange([]);
    setDialogOpen(false);
  };

  const handleClick = () => {
    console.info(`You clicked Download`);
    // hanldeDownload();
  };

  const handleMenuItemClick = (event, index) => {
    console.info(`You clicked download page items`);
    setSelectedIndex(index);
    setOpen(false);
    hanldeDownload(false);
  };
  const handleDownloadAllClick = (event, index) => {
    console.info(`You clicked download all`);
    setSelectedIndex(index);
    setOpen(false);
    hanldeDownload(true);
  };

  const handleActionClick = (eventKey, event) => {
    const [id, name] = eventKey.split("|");
    const assign_to = { id, name };
    const data = { assign_to, claim_ids: selectedClaimID };
    console.log("Selected item: eventKey ", eventKey);
    console.log("Selected item: selectedClaimID ", selectedClaimID);

    setBackdropOpen(true);
    assignClaimToAgentData(data);
  };

  const handleToggle = () => {
    console.info(`You clicked toggle`);
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Add filter items
  const addOrUpdateFilterItems = useCallback((newData) => {
    console.log("addOrUpdateFilterItems ", newData);
    setFormData((prevState) => {
      const newState = [...prevState];
      newData.forEach((newDataItem) => {
        const index = newState.findIndex(
          (item) => item.api_key === newDataItem.api_key
        );
        if (index !== -1) {
          newState[index] = { ...newState[index], ...newDataItem };
        } else {
          newState.push(newDataItem);
        }
      });
      handleFormDataValueChange(newState);
      return newState;
    });
  }, []);

  const deleteFilterItems = (keyToDelete) => {
    const indexToDelete = formData.findIndex(
      (item) => item.api_key === keyToDelete
    );
    if (indexToDelete !== -1) {
      const updatedFormData = [...formData];
      updatedFormData.splice(indexToDelete, 1);
      setFormData(updatedFormData);
      handleFormDataValueChange(updatedFormData);
    }
  };

  return (
    <>
      {/* Loader */}
      <CommonBackDrop backdropOpen={backdropOpen} />
      {/* Filter dialog */}
      <SearchClaimFilterDialog
        formData={formData}
        onAddOrUpdateDataChange={addOrUpdateFilterItems}
        dialogOpen={dialogOpen}
        onDialogDismiss={handleDialogDismiss}
        onDialogClear={handleDialogClear}
        categories={categories}
        title="Search Claims"
        agentDetailList={agentDetailList}
      />

      <Box sx={{ flexGrow: 1, pt: 2, pb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              {/* Refresh Button */}
              <Grid item>
                <Button
                  onClick={() => onRefresh(true)}
                  sx={{ mr: 2 }}
                  style={{ textTransform: "none" }}
                  variant="contained"
                >
                  Refresh
                </Button>
              </Grid>
              {/* Filter Button */}
              <Grid
                style={{
                  pointerEvents: userHasFilterAccess ? "auto" : "none",
                  opacity: userHasFilterAccess ? 1 : 0.5,
                }}
                item
              >
                <Tooltip title="Filter">
                  <FilterListIcon
                    sx={{ mr: 2 }}
                    color="primary"
                    fontSize="large"
                    onClick={handleDialogOpen}
                  />
                </Tooltip>
              </Grid>

              {/* Chip Section */}
              <Grid item>
                {formData !== null && formData.length > 0 && (
                  <>
                    {formData
                      .filter(
                        (item) =>
                          item.display_value !== null &&
                          item.display_value !== ""
                      )
                      .map((item) => (
                        <Chip
                          sx={{ mr: 1, mb: 1 }}
                          color="primary"
                          id={item.api_key}
                          key={item.api_key}
                          label={
                            <span>
                              <strong>{item.display_name} :</strong>{" "}
                              {item.display_value}
                            </span>
                          }
                          variant="outlined"
                          onDelete={() => deleteFilterItems(item.api_key)}
                        />
                      ))}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* Right-aligned items */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 160, marginBottom: 2 }}
                >
                  <InputLabel id="column-selector-label">
                    Add Columns
                  </InputLabel>

                  <Select
                    labelId="column-selector-label"
                    id="column-selector"
                    multiple
                    value={selectedColumns}
                    onChange={handleColumnChange}
                    renderValue={(selected) => "Select Columns"}
                    label="Select Columns"
                  >
                    {columns
                      .filter((col) => !col.default)
                      .map((col) => (
                        <MenuItem key={col.field} value={col.field}>
                          <Checkbox
                            checked={selectedColumns.indexOf(col.field) > -1}
                          />
                          <ListItemText primary={col.headerName} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <DropDownContainer>
                  <ButtonToolbar>
                    <StyledDropdown
                      disabled={
                        userHasAssignAccess &&
                        selectedClaimID !== null &&
                        selectedClaimID.length > 0
                          ? false
                          : true
                      }
                      title="Action"
                      onSelect={handleActionClick}
                    >
                      <Dropdown.Menu title="Assign">
                        {agentDetailList != null &&
                          agentDetailList.map((item) => (
                            <Dropdown.Item
                              // eventKey={item.user.id}
                              eventKey={`${item.user.id}|${item.user.name}`}
                            >
                              {item.user.name}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </StyledDropdown>
                  </ButtonToolbar>
                </DropDownContainer>
              </Grid>
              <Grid
                style={{
                  pointerEvents: userHasDownloadAccess ? "auto" : "none",
                  opacity: userHasDownloadAccess ? 1 : 0.5,
                }}
                item
              >
                <ButtonGroup
                  sx={{ mr: 2, mb: 2 }}
                  variant="contained"
                  ref={anchorRef}
                  aria-label="Button group with a nested menu"
                >
                  <Button
                    style={{ textTransform: "none" }}
                    onClick={handleClick}
                  >
                    Download
                  </Button>
                  <Button
                    size="small"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {/* {options.map((option, index) => ( */}
                            <MenuItem
                              key={"Download"}
                              // selected={index === selectedIndex}
                              onClick={(event) => handleMenuItemClick(event, 0)}
                            >
                              {"Download "} {pageSize}
                              {getPluralizedWord(pageSize, " Item", " Items")}
                            </MenuItem>
                            <MenuItem
                              key={"Download All"}
                              // selected={index === selectedIndex}
                              onClick={(event) =>
                                handleDownloadAllClick(event, 0)
                              }
                            >
                              Download All
                            </MenuItem>
                            {/* ))} */}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const getPluralizedWord = (count, singular, plural) => {
  return count === 1 ? singular : plural;
};

export default SearchClaimHeader;
