import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Spacer from "./spacer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuditApiService } from "services/api/audit-api-service";
import { presentClaimStage } from "store/claim-details/claim-details-selectors";
import { MESSAGES } from "./message-constants";
import { CircularProgress } from "@mui/material";

export default function VerticalLinearStepper({ stepperActiveStep }) {
  const params = useParams();
  const dispatch = useDispatch();

  const stageList = useSelector((state) => state.configSlice.stages);
  // const stageByOrderList = stageList.map((stage) => stage.name);

  // First filter
  const stageByOrderList = stageList
    .filter((item) => item.display_right_stepper === true)
    .map((item) => item.name);

  let claimId = params.claimId;
  const [loading, setLoading] = useState(true);
  const [auditDetails, setAuditDetails] = useState(null);
  const [auditDetailsError, setAuditDetailsError] = useState(null);
  const [displayAll, setDisplayAll] = useState({});

  const currentClaimStageData = useSelector(presentClaimStage);

  useEffect(() => {
    dispatch(fetchAuditApiService({ claimId })).then((data) => {
      if (data?.payload?.status === 200) {
        console.log(
          "fetchAuditApiService component response",
          data.payload.data
        );

        setAuditDetails(data?.payload?.data ?? null);
        setAuditDetailsError(200);
        setLoading(false);
      } else {
        setAuditDetailsError(400);
        setLoading(false);
      }
    });
  }, [dispatch, claimId, stepperActiveStep]);

  const handleDisplayAll = (statusKey) => {
    setDisplayAll((prev) => ({ ...prev, [statusKey]: !prev[statusKey] }));
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Stepper
        activeStep={getCurrentStagePosition(
          currentClaimStageData,
          stepperActiveStep
        )}
        orientation="vertical"
      >
        {auditDetails !== null &&
          stageByOrderList.map((statusKey) => (
            <Step key={statusKey} expanded={true}>
              <StepLabel>{statusKey}</StepLabel>
              <StepContent>
                <Box sx={{ mb: 0 }}>
                  {auditDetails[statusKey] &&
                  auditDetails[statusKey].length > 0 ? (
                    <>
                      {(displayAll[statusKey]
                        ? auditDetails[statusKey]
                        : auditDetails[statusKey].slice(0, 3)
                      ).map((item, index) => (
                        <Box key={index}>
                          <Typography variant="caption">
                            {" "}
                            <strong>{index + 1}.</strong> {item}
                          </Typography>
                          <Spacer value={1} />
                        </Box>
                      ))}
                      {auditDetails[statusKey].length > 3 && (
                        <Button
                          style={{ textTransform: "none" }}
                          variant="text"
                          size="small"
                          sx={{ m: 0, p: 0, minWidth: 0, minHeight: 0 }}
                          onClick={() => handleDisplayAll(statusKey)}
                        >
                          {displayAll[statusKey] ? "Show Less" : "Show More"}
                        </Button>
                      )}
                    </>
                  ) : (
                    <Typography variant="caption">No data available</Typography>
                  )}
                </Box>
              </StepContent>
            </Step>
          ))}
      </Stepper>
      {auditDetailsError !== null && auditDetailsError !== 200 && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography variant="body1">
            Error fetching details, please try again later
          </Typography>
        </Paper>
      )}
    </Box>
  );
}

function getCurrentStagePosition(currentClaimStageData, stepperActiveStep) {
  if (currentClaimStageData === MESSAGES.CLAIM_STAGE_FILING) {
    return 0;
  } else if (currentClaimStageData === MESSAGES.CLAIM_STAGE_UPLOAD_DOC) {
    return 1;
  } else if (currentClaimStageData === MESSAGES.CLAIM_STAGE_VERIFY_DOC) {
    return 2;
  } else if (currentClaimStageData === MESSAGES.CLAIM_STAGE_DECISION) {
    return 3;
  } else if (currentClaimStageData === MESSAGES.CLAIM_STAGE_COMMUNICATION) {
    return 4;
  } else if (currentClaimStageData === MESSAGES.CLAIM_STAGE_PAYOUT) {
    return 5;
  } else if (currentClaimStageData === MESSAGES.CLAIM_STAGE_CLOSURE) {
    return 6;
  } else if (currentClaimStageData === MESSAGES.CLAIM_STAGE_CLOSED) {
    return 6;
  } else {
    return stepperActiveStep;
  }
}
