import React, { useEffect } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Card,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";

const CommunicationDetailsCard = ({
  enable,
  title,
  defaultMetaDataList,
  optionsList,
  onSubmit,
  onDataChange,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      communications: defaultMetaDataList || [{ mode: "", contact: "" }],
    },
  });

  // Dynamically reinitialize form fields when defaultMetaDataList changes
  useEffect(() => {
    if (defaultMetaDataList) {
      reset({ communications: defaultMetaDataList });
    }
  }, [defaultMetaDataList, reset]);

  // useFieldArray hook for dynamic rows management
  const { fields, append, remove } = useFieldArray({
    control,
    name: "communications",
  });

  console.log("Communication Card: ", fields, enable, defaultMetaDataList);

  // Watch all form data (real-time)
  const watchedFields = watch("communications");

  // Trigger onDataChange when fields change
  useEffect(() => {
    if (onDataChange) {
      onDataChange(watchedFields);
    }
  }, [watchedFields, onDataChange]);

  const handleRemove = (index) => {
    remove(index);
    const updatedValues = getValues("communications");
    onDataChange(updatedValues); // Send updated data to parent
  };

  const handleSubmitButton = (data) => {
    const requestData = data.communications.map((item) => {
      const metaDataName = optionsList.find((opt) => opt.id === item.mode);
      return {
        id: item.mode,
        name: metaDataName?.name || "",
        value: item.contact,
      };
    });
    onSubmit(requestData);
  };

  return (
    <Card sx={{ pl: 2, pr: 2, pt: 1 }}>
      <form onSubmit={handleSubmit(handleSubmitButton)}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>
          <Tooltip title="Add MetaData">
            <IconButton
              sx={{ fontSize: 40 }}
              aria-label="delete"
              size="large"
              color="primary"
              disabled={!enable}
              onClick={() => append({ mode: "", contact: "" })} // Add new row
            >
              <AddCircleIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ mb: 1, pb: 1 }}>
          {fields.map((field, index) => {
            const selectedOption = watch(`communications.${index}.mode`);
            return (
              <Grid
                container
                spacing={2}
                key={field.id}
                sx={{ m: 0.5, p: 0.5 }}
              >
                {/* DROP DOWN */}
                <Grid item xs={12} md={5.5}>
                  <FormControl fullWidth disabled={!enable}>
                    <InputLabel id={`dynamic-select-label-${index}`}>
                      Select Mode
                    </InputLabel>
                    <Controller
                      name={`communications.${index}.mode`}
                      control={control}
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId={`dynamic-select-label-${index}`}
                          label="Select Mode"
                          error={!!errors?.communications?.[index]?.mode}
                        >
                          {optionsList.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors?.communications?.[index]?.mode && (
                      <Typography color="error" variant="caption">
                        {errors?.communications?.[index]?.mode?.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                {/* TEXTBOX */}
                <Grid item xs={12} md={5.5}>
                  <Controller
                    name={`communications.${index}.contact`}
                    control={control}
                    rules={{
                      required: selectedOption
                        ? `${
                            optionsList.find((opt) => opt.id === selectedOption)
                              ?.description
                          } is required`
                        : "Contact is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={
                          selectedOption
                            ? optionsList.find(
                                (opt) => opt.id === selectedOption
                              )?.description || ""
                            : ""
                        }
                        variant="outlined"
                        fullWidth
                        disabled={!enable}
                        error={!!errors?.communications?.[index]?.contact}
                        helperText={
                          errors?.communications?.[index]?.contact?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      size="large"
                      disabled={!enable}
                      onClick={() => handleRemove(index)}
                      sx={{ "&:hover": { color: "red" } }}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </form>
    </Card>
  );
};

export default CommunicationDetailsCard;
