import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const fetchBeneficiaryDetails = createAsyncThunk(
  "beneficiaryDetailsSlice/fetchBeneficiaryDetails",
  async (data, { rejectWithValue }) => {
    const {subscription_id, platform_name, incident_date} = data;
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/search/beneficiary/${subscription_id}/${platform_name}/${incident_date}`
      });
      console.log("Search Beneficiary Response", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);