import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { fetchBeneficiaryDetails as getBeneficiaryDetailsAPI } from "services/api/beneficiarydetails-api-service";
import { Box, CircularProgress } from "@mui/material";
import { ShimmerTable } from "shimmer-effects-react";
import { FormatDateUtil } from "utils/formatdate";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  color: theme.palette.primary,
}));

const BeneficiaryDetails = ({ subscription_id, platform_name, incident_date }) => {
  const dispatch = useDispatch();

  console.log("BeneficiaryDetails >>>>>>>>>>>>>>>>>>>>>> ");
  console.log(subscription_id);
  console.log(platform_name);
  console.log(incident_date);

  const beneficiariesList = useSelector(
    (state) => state.beneficiaryDetailsSlice.beneficiariesList
  );
  const beneficiariesListLoading = useSelector(
    (state) => state.beneficiaryDetailsSlice.loading
  );
  const beneficiariesListError = useSelector(
    (state) => state.beneficiaryDetailsSlice.error
  );

  useEffect(() => {
    if (subscription_id !== null && platform_name !== null && incident_date !== null) {
      // GET SUBSCRIPTION DETAILS
      dispatch(getBeneficiaryDetailsAPI({ subscription_id, platform_name, incident_date }));
    }
  }, [subscription_id, platform_name, incident_date]);

  return (
    <>
      {beneficiariesListLoading && (
        <ShimmerTable
          loading={true}
          mode="light"
          row={1}
          col={5}
          border={1}
          borderColor={"#cbd5e1"}
          rounded={1}
          rowGap={16}
          colPadding={[10, 5, 10, 5]}
        >
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <tbody>
              {[...Array(5)].map((_, rowIndex) => (
                <tr key={rowIndex} style={{ height: "40px" }}>
                  {[...Array(1)].map((_, colIndex) => (
                    <td
                      key={colIndex}
                      style={{
                        border: "1px solid #cbd5e1",
                        borderRadius: "0.25em",
                        padding: "10px 5px",
                        textAlign: "center",
                      }}
                    >
                      Col {colIndex}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </ShimmerTable>
      )}
      {!beneficiariesListLoading && beneficiariesList && (
        <TableContainer component={Paper}>
          <Table
            stickyHeader
            sx={{ minWidth: 700 }}
            aria-label="customized table"
          >
            <StyledTableHeader>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="left">Relationship</StyledTableCell>
                <StyledTableCell align="left">Gender</StyledTableCell>
                <StyledTableCell align="left">DOB</StyledTableCell>
                <StyledTableCell align="left">Age</StyledTableCell>
                <StyledTableCell align="left">Phone No.</StyledTableCell>
              </TableRow>
            </StyledTableHeader>
            <TableBody>
              {beneficiariesList.map((beneficiary) => (
                <StyledTableRow key={beneficiary.name}>
                  <StyledTableCell component="th" scope="row">
                    {beneficiary.customer_name
                      ? beneficiary.customer_name
                      : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {beneficiary.relation ? beneficiary.relation : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {beneficiary.gender ? beneficiary.gender : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {beneficiary.dob ? beneficiary.dob : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {beneficiary.age ? beneficiary.age : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {beneficiary.alt_msisdn ? beneficiary.alt_msisdn : "NA"}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default BeneficiaryDetails;