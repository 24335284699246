import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const fetchSearchClaimList = createAsyncThunk(
  "searchClaimSlice/fetchSearchClaimList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/search`,
        data,
      });
      console.log("Search Service fetchSearchClaimList success", response);
      return response.data;
    } catch (error) {
      console.log("Search Service fetchSearchClaimList error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchClaimDetailsByID = createAsyncThunk(
  "searchClaimByIdSlice/fetchClaimDetailsByID",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${data}`,
      });
      console.log("Search Service fetchClaimDetailsByID success", response);
      return response.data;
    } catch (error) {
      console.log("Search Service fetchClaimDetailsByID error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchAgentDetails = createAsyncThunk(
  "getAgentDetailSlice/fetchAgentDetails",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/search/users/agents`,
      });
      console.log("Search Service fetchAgentDetails success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
      // return response.data;
    } catch (error) {
      console.log("Search Service fetchAgentDetails error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const assignClaimToAgent = createAsyncThunk(
  "assignClaimSlice/assignClaimToAgent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/assign`,
        data,
      });
      console.log("Search Service assignClaimToAgent success", response);
      return response.data;
    } catch (error) {
      console.log("Search Service assignClaimToAgent error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
