import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { NavigateBefore, Save, Edit } from "@mui/icons-material";
import { submitDecisionDetails } from "services/api/getdecision-details-api-service";
import { useDispatch, useSelector } from "react-redux";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import { getClaimDetailsAPI } from "services/api/getclaimdetails-api-service";
import { useParams } from "react-router-dom";
import { MESSAGES } from "../../../components/utils/message-constants";
import Spacer from "ui/components/utils/spacer";
import AgentRemarks from "./agent-remarks";
import { CLAIM_DECISION_PAGEACCESS } from "ui/components/utils/role-constants";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import PageAccessDenied from "ui/components/page-access-denied/page-access-denied.component";
import ClaimNotReached from "ui/components/utils/claim-not-reached.component";

const ClaimsDecision = ({
  onNextButtonClick,
  displayAlert,
  roleAndPrivilageMap,
  showLoadingBackdrop,
}) => {
  const dispatch = useDispatch();
  const [backdropOpen, setBackdropOpen] = useState(false);

  const [recommendation, setRecommendation] = useState("");
  const [recommendationPayout, setRecommendationPayout] = useState("");
  const [approvedPayoutAmt, setApprovedPayoutAmt] = useState("");

  const [decision, setDecision] = useState({ id: "", name: "" });
  const [rejectedReason, setRejectedReason] = useState({ id: "", name: "" });
  const [pendingReason, setPendingReason] = useState({ id: "", name: "" });

  const [approvedComments, setApprovedComments] = useState("");
  const [rejectedComments, setRejectedComments] = useState("");
  const [pendingComments, setPendingComments] = useState("");

  const [decisionError, setDecisionError] = useState(false);

  const [approvedPayoutError, setApprovedPayoutError] = useState(false);
  const [approvedCommentsError, setApprovedCommentsError] = useState(false);
  const [rejectedReasonError, setRejectedReasonError] = useState(false);
  const [rejectedCommentsError, setRejectedCommentsError] = useState(false);
  const [pendingReasonError, setPendingReasonError] = useState(false);
  const [pendingCommentsError, setPendingCommentsError] = useState(false);

  const [agentRemarks, setAgentRemarks] = useState("");

  const [currentStage, setCurrentStage] = useState(null);
  const [isCurrentStageInDecision, setIsCurrentStageInDecision] =
    useState(false);
  const [decisionDataExists, setDecisionDataExists] = useState(false);
  const [editDecisionDetailsInProgress, setEditDecisionDetailsInProgress] =
    useState(false);

  const decisionStageAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIM_DECISION_PAGEACCESS
  );

  const [userHasViewAccess, setUserHasViewAccess] = useState(false);
  const [userHasListAccess, setUserHasListAccess] = useState(false);
  const [userHasEditAccess, setUserHasEditAccess] = useState(false);
  const [userHasWriteAccess, setUserHasWriteAccess] = useState(false);
  const [userHasImportAccess, setUserHasImportAccess] = useState(false);

  const params = useParams();
  const claimId = params.claimId;

  console.log("Claim id  ", params.claimId);

  // CONFIG STATE
  const decisionList = useSelector((state) => state.configSlice.decisions);
  const rejectionList = useSelector(
    (state) => state.configSlice.decisionRejectedReasons
  );
  const pendingQueryList = useSelector(
    (state) => state.configSlice.decisionPendingReasons
  );
  // CLAIM STATE
  const claimDetails = useSelector(
    (state) => state.claimDetailsSlice.claimDetails
  );
  const claimDetailsLoading = useSelector(
    (state) => state.claimDetailsSlice.loading
  );
  const claimDetailsError = useSelector(
    (state) => state.claimDetailsSlice.error
  );

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    console.log("decisionStageAccessMap ", decisionStageAccessMap);

    if (decisionStageAccessMap.size > 0) {
      if (decisionStageAccessMap.get("READ") === true) {
        setUserHasViewAccess(true);
      }
      if (decisionStageAccessMap.get("LIST") === true) {
        setUserHasListAccess(true);
      }
      if (decisionStageAccessMap.get("EDIT") === true) {
        setUserHasEditAccess(true);
      }
      if (decisionStageAccessMap.get("WRITE") === true) {
        setUserHasWriteAccess(true);
      }
      if (decisionStageAccessMap.get("EDIT") === true) {
        setUserHasImportAccess(true);
      }
    }
  }, [decisionStageAccessMap]);

  useEffect(() => {
    setBackdropOpen(true);
    dispatch(getClaimDetailsAPI({ claimId }));
  }, [dispatch, claimId]);

  useEffect(() => {
    if (claimDetails) {
      setBackdropOpen(false);
      setEditDecisionDetailsInProgress(false);

      console.log("claimDetails.decision ", claimDetails);
      if (claimDetails.decision !== undefined) {
        setDecisionDataExists(true);
      } else {
        setDecisionDataExists(false);
      }

      setCurrentStage(claimDetails.claim?.stage.name ?? null);

      if (claimDetails.claim?.stage.name === "Decision") {
        setIsCurrentStageInDecision(true);
      } else {
        setIsCurrentStageInDecision(false);
      }

      const verificationDetails = claimDetails.verification || {};
      setRecommendation(verificationDetails.recommendation || "");
      setRecommendationPayout(verificationDetails.recommended_payout || "0");

      const decisionDetails = claimDetails.decision || {};
      setDecision(decisionDetails.decision_status || "");
      setApprovedPayoutAmt(decisionDetails?.approved?.payout || "");
      setApprovedComments(decisionDetails?.approved?.remarks || "");
      setRejectedReason(decisionDetails?.reject?.reason || "");
      setRejectedComments(decisionDetails?.reject?.remarks || "");
      setPendingReason(decisionDetails?.pending?.reason || "");
      setPendingComments(decisionDetails?.pending?.remarks || "");
      setAgentRemarks(decisionDetails.remarks || "");
    }
  }, [claimDetails]);

  console.log("decisionList ", decisionList);
  console.log("rejectionList ", rejectionList);

  const handleNextClick = (claimStage) => (e) => {
    e.preventDefault();
    var requestData = null;
    console.log("handleNextClick decision ", decision);
    console.log("handleNextClick rejectedReason ", rejectedReason);
    console.log("handleNextClick pendingReason ", pendingReason);

    if (decision.id === "" || decision.id === undefined) {
      setDecisionError(true);
    } else if (decision.id === "approved") {
      if (approvedPayoutAmt === null || approvedPayoutAmt === "") {
        setDecisionError(false);
        setApprovedPayoutError(true);
        setApprovedCommentsError(true);
        return;
      } else if (approvedComments === null || approvedComments === "") {
        setApprovedPayoutError(false);
        setApprovedCommentsError(true);
        return;
      } else {
        setApprovedPayoutError(false);
        setApprovedCommentsError(false);
        requestData = {
          decision_status: decision,
          approved: {
            remarks: approvedComments,
            payout: approvedPayoutAmt,
          },
          remarks: agentRemarks,
        };
      }
    } else if (decision.id === "rejected") {
      if (rejectedReason.id === "" || rejectedReason.id === undefined) {
        setRejectedReasonError(true);
        setRejectedCommentsError(true);
        return;
      } else if (rejectedComments === null || rejectedComments === "") {
        setRejectedReasonError(false);
        setRejectedCommentsError(true);
        return;
      } else {
        setRejectedReasonError(false);
        setRejectedCommentsError(false);
        requestData = {
          decision_status: decision,
          reject: {
            reason: rejectedReason,
            remarks: rejectedComments,
          },
          remarks: agentRemarks,
        };
      }
    } else if (decision.id === "pending") {
      if (pendingReason.id === "" || pendingReason.id === undefined) {
        setPendingReasonError(true);
        setPendingCommentsError(true);
        return;
      } else if (pendingComments === null || pendingComments === "") {
        setPendingReasonError(false);
        setPendingCommentsError(true);
        return;
      } else {
        setPendingReasonError(false);
        setPendingCommentsError(false);
        requestData = {
          decision_status: decision,
          pending: {
            reason: pendingReason,
            remarks: pendingComments,
          },
          remarks: agentRemarks,
        };
      }
    }

    if (requestData !== null) {
      submitDecisionData(requestData, claimStage);
    }
  };

  // GET UPLOADED DOCUMENTS API CALL
  const submitDecisionData = async (requestData, claimStage) => {
    try {
      console.log("submitDecisionData: ", claimId);
      console.log("submitDecisionData: ", requestData);

      setBackdropOpen(true);

      const data = { claimId, requestData };
      const response = await dispatch(submitDecisionDetails(data));
      const responsePayload = response.payload;
      console.log("getDecisionDetailsData response ", responsePayload);

      if (responsePayload.status === 200) {
        setBackdropOpen(false);
        setEditDecisionDetailsInProgress(false);
        setDecisionDataExists(true);
        console.log("submitDecisionData response ", responsePayload.data);
        if (claimStage !== -1) {
          onNextButtonClick(claimStage, "next", {
            id: MESSAGES.CLAIM_STAGE_COMMUNICATION_ID,
            name: MESSAGES.CLAIM_STAGE_COMMUNICATION,
          });
        } else {
          displayAlert("success", MESSAGES.SAVED_SUCCESFULLY);
        }
      } else {
        displayAlert("error", MESSAGES.SAVE_FAILED);
      }
    } catch (error) {
      setBackdropOpen(false);
      console.error("Error fetching data:", error);
      displayAlert("error", MESSAGES.SAVE_FAILED);
    }
  };

  // SHOW LOADERS FOR API CALL
  useEffect(() => {
    if (claimDetailsLoading) {
      setBackdropOpen(true);
    } else {
      setBackdropOpen(false);
    }
  }, [claimDetailsLoading]);

  if (
    params.claimId !== null &&
    params.claimId !== undefined &&
    !backdropOpen &&
    !claimDetailsLoading &&
    !userHasViewAccess
  ) {
    return <PageAccessDenied />;
  }

  if (
    params.claimId === null ||
    params.claimId === undefined ||
    (!claimDetailsLoading &&
      currentStage !== null &&
      (currentStage === MESSAGES.CLAIM_STAGE_FILING ||
        currentStage === MESSAGES.CLAIM_STAGE_UPLOAD_DOC ||
        currentStage === MESSAGES.CLAIM_STAGE_VERIFY_DOC))
  ) {
    return <ClaimNotReached />;
  }

  if (claimDetailsLoading) {
    return <CommonBackDrop backdropOpen={backdropOpen} />;
  }

  if (!claimDetailsLoading) {
    return (
      <>
        {/* LOADER */}
        <CommonBackDrop backdropOpen={backdropOpen} />
        <Box>
          <Card id="claimantinfo--main__card" sx={{ m: 4, p: 4 }}>
            <Box display="flex">
              <Typography variant="h6" component="div">
                Details of Decision
              </Typography>
              <Spacer value={1} />
              {userHasEditAccess &&
                isCurrentStageInDecision &&
                decisionDataExists &&
                !editDecisionDetailsInProgress && (
                  <Tooltip title="Edit Verify Document Details">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={(e) => {
                        setEditDecisionDetailsInProgress(true);
                        setDecisionDataExists(false);
                      }}
                    >
                      <Edit fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
            </Box>
            <Box sx={{ m: 2, p: 2 }}>
              <Grid container spacing={4}>
                <>
                  <Grid item xs={6}>
                    <TextField
                      id="claimantinfo--primaryno__textfield"
                      label="Recommendation"
                      name="approved-payout"
                      variant="outlined"
                      disabled
                      fullWidth
                      value={recommendation.name ?? ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="claimantinfo--primaryno__textfield"
                      label="Recommended Payout"
                      name="approved-payout"
                      variant="outlined"
                      disabled
                      value={recommendationPayout}
                      fullWidth
                    />
                  </Grid>
                </>

                <Grid item xs={12}>
                  <FormControl
                    // disabled={decisionDataExists ? true : false}
                    error={decisionError}
                    disabled={
                      isCurrentStageInDecision
                        ? decisionDataExists
                          ? true
                          : false
                        : true
                    }
                    fullWidth
                  >
                    <InputLabel id="claimantinfo--relationship__inputlabel">
                      Decision *
                    </InputLabel>
                    <Select
                      onChange={(e, key) => {
                        const data = {
                          id: key.props.id,
                          name: key.props.children,
                        };
                        setDecision(data);
                      }}
                      labelId="claimantinfo--relationship__selectlabel"
                      id="claimantinfo--relationship__select"
                      name="claimantRelationship"
                      label="Decision"
                      value={decision?.id || ""}
                    >
                      {decisionList.map((item) => (
                        <MenuItem key={item.id} value={item.id} id={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {decisionError ? "Select Decision" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {decision.id === "approved" && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        disabled={decisionDataExists ? true : false}
                        id="claimantinfo--primaryno__textfield"
                        label="Approved Payout *"
                        name="approved-payout"
                        variant="outlined"
                        fullWidth
                        value={approvedPayoutAmt}
                        onChange={(e) => {
                          setApprovedPayoutAmt(e.target.value);
                        }}
                        error={approvedPayoutError}
                        helperText={
                          approvedPayoutError
                            ? "Enter Approved Payout Amount"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        disabled={decisionDataExists ? true : false}
                        id="claimantinfo--primaryno__textfield"
                        label="Comments *"
                        name="approved-payout"
                        variant="outlined"
                        value={approvedComments}
                        onChange={(e) => {
                          setApprovedComments(e.target.value);
                        }}
                        fullWidth
                        error={approvedCommentsError}
                        helperText={
                          approvedCommentsError ? "Enter Comments" : ""
                        }
                      />
                    </Grid>
                  </>
                )}
                {decision.id === "rejected" && (
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        disabled={decisionDataExists ? true : false}
                        fullWidth
                        error={rejectedReasonError}
                      >
                        <InputLabel id="claimantinfo--relationship__inputlabel">
                          Reason for Rejection*
                        </InputLabel>
                        <Select
                          onChange={(e, key) => {
                            const data = {
                              id: key.props.id,
                              name: key.props.children,
                            };
                            setRejectedReason(data);
                          }}
                          labelId="claimantinfo--relationship__selectlabel"
                          id="claimantinfo--relationship__select"
                          name="claimantRelationship"
                          label="Reason for Rejection"
                          value={rejectedReason?.id || ""}
                        >
                          {rejectionList.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              id={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {rejectedReasonError ? "Select Rejected Reason" : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        disabled={decisionDataExists ? true : false}
                        id="claimantinfo--primaryno__textfield"
                        label="Details for Rejection*"
                        name="approved-payout"
                        variant="outlined"
                        fullWidth
                        value={rejectedComments}
                        onChange={(e) => {
                          setRejectedComments(e.target.value);
                        }}
                        error={rejectedCommentsError}
                        helperText={
                          rejectedCommentsError ? "Enter Comments" : ""
                        }
                      />
                    </Grid>
                  </>
                )}
                {decision.id === "pending" && (
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        disabled={decisionDataExists ? true : false}
                        fullWidth
                        error={pendingReasonError}
                      >
                        <InputLabel id="claimantinfo--relationship__inputlabel">
                          Reason for Pending*
                        </InputLabel>
                        <Select
                          onChange={(e, key) => {
                            const data = {
                              id: key.props.id,
                              name: key.props.children,
                            };
                            setPendingReason(data);
                          }}
                          labelId="claimantinfo--relationship__selectlabel"
                          id="claimantinfo--relationship__select"
                          name="claimantRelationship"
                          label="Reason for Pending"
                          value={pendingReason?.id || ""}
                        >
                          {pendingQueryList.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              id={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {pendingReasonError ? "Select Pending Reason" : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        disabled={decisionDataExists ? true : false}
                        id="claimantinfo--primaryno__textfield"
                        label="Comments *"
                        name="approved-payout"
                        variant="outlined"
                        fullWidth
                        value={pendingComments}
                        onChange={(e) => {
                          setPendingComments(e.target.value);
                        }}
                        error={pendingCommentsError}
                        helperText={
                          pendingCommentsError ? "Enter Comments" : ""
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              {editDecisionDetailsInProgress && (
                <Grid container spacing={10} sx={{ pt: 4 }}>
                  <Grid item xs={6} textAlign="right">
                    <Button
                      variant="outlined"
                      size="big"
                      sx={{ p: 1, m: 1, minWidth: 200 }}
                      onClick={handleNextClick(-1)}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      size="big"
                      sx={{ p: 1, m: 1, minWidth: 200 }}
                      onClick={(e) => {
                        setEditDecisionDetailsInProgress(false);
                        setDecisionDataExists(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Card>

          {/* AGENT REMARKS */}
          <Box sx={{ m: 4 }}>
            <AgentRemarks
              agentRemarks={agentRemarks}
              setAgentRemarks={setAgentRemarks}
              buttonStatus={isCurrentStageInDecision ? false : true}
            />
          </Box>

          {/* NEXT AND PREVIOUS BUTTONS */}
          <Grid container spacing={4}>
            <Grid item xs={4} textAlign="right">
              <Button
                variant="contained"
                size="big"
                sx={{ p: 2, m: 4, minWidth: 200 }}
                onClick={() => onNextButtonClick(2, "previous")}
                startIcon={<NavigateBefore />}
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Button
                variant="contained"
                size="big"
                sx={{ p: 2, m: 4, minWidth: 200 }}
                onClick={handleNextClick(-1)}
                disabled={
                  currentStage === MESSAGES.CLAIM_STAGE_CLOSED ||
                  currentStage === MESSAGES.CLAIM_STAGE_CLOSURE
                    ? true
                    : isCurrentStageInDecision && userHasWriteAccess
                    ? false
                    : true
                }
                startIcon={<Save />}
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={4} textAlign="left">
              <Button
                variant="contained"
                size="big"
                disabled={
                  currentStage === MESSAGES.CLAIM_STAGE_CLOSED ||
                  currentStage === MESSAGES.CLAIM_STAGE_CLOSURE
                    ? true
                    : isCurrentStageInDecision && userHasWriteAccess
                    ? false
                    : true
                }
                sx={{ p: 2, m: 4, minWidth: 200 }}
                onClick={handleNextClick(4, "next")}
                endIcon={<NavigateNextIcon />}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
};

export default ClaimsDecision;
