import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const changeClaimStatus = createAsyncThunk(
  "changeClaimStatusSlice/changeClaimStatus",
  async (data, { rejectWithValue }) => {
    const { claimId, requestBody } = data;
    try {
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/stage`,
        data: requestBody,
      });
      console.log("changeClaimStatus Response", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
