export function SubscriptionDetailsMapper(claimData){
    const formattedSubscriptionData = {};
    formattedSubscriptionData["confirmed_date"] = claimData.policy.confirmed_date;
    formattedSubscriptionData["cover_amount"] = "";
    formattedSubscriptionData["cover_end_date"] = "";
    formattedSubscriptionData["cover_start_date"] = "";
    formattedSubscriptionData["current_policy_status"] = claimData.policy.current_status;
    formattedSubscriptionData["current_policy_status_code"] = "";
    formattedSubscriptionData["customer_id"] = claimData.customer.info.id;
    formattedSubscriptionData["incident_last_deduction_date"] = claimData.policy.last_payment_date;
    formattedSubscriptionData["incident_last_paid_premium"] = claimData.policy.last_payment_date;
    formattedSubscriptionData["incident_policy_status"] = claimData.policy.incident_status;
    formattedSubscriptionData["incident_policy_status_code"] = "";
    formattedSubscriptionData["payment_channel"] = claimData.policy.payment_channel;
    formattedSubscriptionData["platform_name"] = claimData.claim.platform;
    formattedSubscriptionData["product_id"] = claimData.policy.id;
    formattedSubscriptionData["product_name"] = claimData.policy.name;
    formattedSubscriptionData["subscription_id"] = claimData.policy.subs_id;
    formattedSubscriptionData["variant_name"] = "";
    formattedSubscriptionData["beneficiary_max_age"] = "";
    formattedSubscriptionData["beneficiary_min_age"] = "";
    formattedSubscriptionData["customer_max_age"] = "";
    formattedSubscriptionData["customer_min_age"] = "";
    formattedSubscriptionData["incident_month_paid_premium"] = "";
    formattedSubscriptionData["product_code"] = claimData.policy.code;
    formattedSubscriptionData["monthly_product_premium"] = "";
    formattedSubscriptionData["critical_illness_days_waiting"] = "";
    formattedSubscriptionData["maternity_waiting"] = "";
    formattedSubscriptionData["life_days_waiting"] = "";
    formattedSubscriptionData["hp_days_waiting"] = "";
    
    return formattedSubscriptionData;
}