import React from 'react';
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Checkbox,
    IconButton,
    Typography,
  } from "@mui/material";
  import { Favorite, FavoriteBorder, MoreVert, Share } from "@mui/icons-material";
import styled from 'styled-components';
import { Box, CardActionArea, Paper, Stack } from '@mui/material';

const SignInInfoCard = ({img, title, desc}) => {
  return (
    <div>
    <Card sx={{ maxWidth: 345 }}>
    <CardActionArea>
      <CardMedia
        component="img"
        height="200"
        image={require(`../../../../assets/img/${img}.jpeg`)}
        alt={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {desc}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card></div>
  )
}

export default SignInInfoCard;