import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "ui/layout/home/home.component";
import SignIn from "ui/layout/signin/signin.component";
import PageNotFound from "ui/layout/page-not-found/pagenotfound.component";
import ProtectedRoutes from "hoc/protected-routes";
import SearchCustomer from "ui/layout/create-claim/search-customer.components";
import CreateClaim from "ui/layout/create-claim/create-claim.component";
import SearchClaim from "ui/layout/search-claim/searchclaim.component";
import Communication from "ui/layout/communication/communication.component";
import FAQ from "ui/layout/faq/faq.component";
import Dashboard from "ui/layout/dashboard/dashboard.component";
import LoginRoute from "hoc/login-route";
import UserProfile from "ui/layout/profile/user-profile-component";

const AppRoutes = createBrowserRouter([
  {
    path: "/signin",
    element: (
      <LoginRoute>
        <SignIn />
      </LoginRoute>
    ),
    errorElement: <PageNotFound />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/",
        element: <Home />,
        children: [
          {
            path: "/",
            element: <Dashboard />,
          },
          {
            path: "/search",
            element: <SearchCustomer />,
          },
          {
            path: "/create",
            element: <CreateClaim />,
          },
          {
            path: "/claim/:claimId",
            element: <CreateClaim />,
          },
          {
            path: "/claims",
            element: <SearchClaim />,
          },
          {
            path: "/profile",
            element: <UserProfile />,
          },
          {
            path: "/communication",
            element: <Communication />,
          },
          {
            path: "/faq",
            element: <FAQ />,
          },
        ],
      },
    ],
    errorElement: <PageNotFound />,
  },
]);

export default AppRoutes;
