import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import { useDispatch } from "react-redux";
import { getFAQConfigAPIService } from "services/api/getconfig-api-service";

export default function FAQ() {
  const dispatch = useDispatch();
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [faqList, setFaqList] = React.useState([]);

  React.useEffect(() => {
    setBackdropOpen(true);
    dispatch(getFAQConfigAPIService("")).then((responseData) => {
      if (responseData.payload.status === 200) {
        setBackdropOpen(false);
        console.log("faq response ", responseData.payload.data);
        const faqArr = [];
        responseData?.payload?.data?.map((rList, index) => {
          let objectData = {
            id: rList.id,
            question: rList.question,
            answer: rList.answer,
          };
          faqArr.push(objectData);
        });
        setFaqList(faqArr);
      } else {
        setBackdropOpen(false);
      }
    });
  }, []);

  return (
    <>
      {/* LOADER */}
      <CommonBackDrop backdropOpen={backdropOpen} />

      <Typography
        align="center"
        variant={"h5"}
        sx={{ mb: 2, mt: 5 }}
        color="primary"
      >
        FAQ's
      </Typography>

      {!backdropOpen &&
        faqList &&
        faqList.length > 0 &&
        faqList.map((faq, index) => (
          <Box sx={{ p: 2, ml: 2, mr: 2 }}>
            <div>
              <Accordion
                sx={{
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Smooth shadow
                  borderRadius: "8px",
                  overflow: "hidden",
                  transition: "all 0.3s",
                  "&:hover": {
                    transform: "translateY(-2px)",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    color: "primary.main",
                    fontSize: "1rem",
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                      transform: "rotate(90deg)",
                    },
                  }}
                >
                  {faq.question}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    lineHeight: "1.6",
                  }}
                >
                  {faq.answer}
                </AccordionDetails>
              </Accordion>
            </div>
          </Box>
        ))}
    </>
  );
}
