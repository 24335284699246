import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const fetchClaimsHistory = createAsyncThunk(
  "getClaimsHistorySlice/fetchClaimsHistory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/search`,
        data
      });
      console.log("Claims History Response", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);