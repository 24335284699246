import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const sendPaymentDetails = createAsyncThunk(
  "payoutSlice/sendPaymentDetails",
  async (data, { rejectWithValue }) => {
    console.log("sendPaymentDetails: apiService", data);
    try {
      const { claimId, paymentRequestBody } = data;
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/payment`,
        data: paymentRequestBody,
      });
      console.log("sendPaymentDetails Service success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
