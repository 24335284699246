import React from "react";
import { Grid, TextField } from "@mui/material";

const SearchClaimByCustomerDetails = ({
  formDataValues,
  tempFormData,
  handleDataChange,
}) => {
  const getValue = (key) => {
    const formData = formDataValues.find((item) => item.api_key === key);
    const formDataTemp = tempFormData.find((item) => item.api_key === key);
    if (formDataTemp !== null && formDataTemp !== undefined) {
      return formDataTemp.display_value;
    } else if (formData !== null && formData !== undefined) {
      return formData.display_value;
    } else {
      return "";
    }
  };

  const handleChange = (displayName, displayValue) => (event) => {
    const { name, value } = event.target;
    console.log("handleChange  name ", name);
    console.log("handleChange  value ", value);
    if (displayValue !== "") {
      handleDataChange(name, value, displayName, displayValue);
    } else {
      handleDataChange(name, value, displayName, value);
    }
  };

  return (
    <>
      <Grid
        item
        xs={12}
        md={9}
        container
        spacing={2}
        style={{ padding: "16px" }}
      >
        <Grid container spacing={2} sx={{ pt: 3, pl: 2 }}>
          {/* First Row */}
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              name="customer_name"
              size="small"
              label="Customer Name"
              value={getValue("customer_name")}
              onChange={handleChange("Customer Name", "")}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              name="nic"
              size="small"
              label="Govt ID"
              value={getValue("nic")}
              onChange={handleChange("NIC", "")}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchClaimByCustomerDetails;
