import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const fetchPayoutDetails = createAsyncThunk(
  "payoutSlice/fetchPayoutDetails",
  async (data, { rejectWithValue }) => {
    console.log("fetchPayoutDetails: apiService", data);
    try {
      const { claimId } = data;
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/payout`,
      });
      console.log("fetchPayoutDetails Service success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitPayoutDetails = createAsyncThunk(
  "payoutSlice/submitPayoutDetails",
  async (data, { rejectWithValue }) => {
    console.log("submitPayoutDetails: apiService", data);
    try {
      const { claimId, payoutRequestBody } = data;
      const response = await ApiCall({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}/payout`,
        data: payoutRequestBody,
      });
      console.log("submitPayoutDetails Service success", response);
      if (response.status == 200)
        return { status: response.status, data: response.data };
      else return rejectWithValue(response?.data || response.message);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
