import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const getClaimDetailsAPI = createAsyncThunk(
  "claimDetailsSlice/getClaimDetailsAPI",
  async (data, { rejectWithValue }) => {
    const { claimId } = data;
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/claim/${claimId}`,
      });
      console.log("GetClaimDetailsAPI Service success", response);
      return response.data;
    } catch (error) {
      console.log("GetClaimDetailsAPI Service error", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
