import {
  Box,
  Button,
  FormControl,
  NativeSelect,
  Typography,
} from "@mui/material";
// import { calculatePagination } from "ui/components/utils/utils";

const SearchClaimPagination = ({
  totalRows,
  pageSize,
  currentPage,
  totalPages,
  handlePageSizeChange,
  handlePageChange,
  startIndex,
  endIndex,
}) => {
  return (
    <>
      {totalRows !== null && totalRows !== undefined ? (
        <>
          <Box
            mt={2}
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" color="black" mx={2}>
                Showing Items {startIndex + 1} to {endIndex + 1} of {totalRows}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" mr={3}>
                  Page Size:
                </Typography>
                <FormControl variant="outlined" size="small">
                  <NativeSelect
                    value={pageSize}
                    onChange={handlePageSizeChange}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </NativeSelect>
                </FormControl>
              </Box>
              <Typography variant="subtitle2" mx={2}>
                Page {currentPage + 1} of {totalPages}
              </Typography>
              <Button
                onClick={() => handlePageChange(0)}
                disabled={currentPage === 0}
              >
                First
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 0}
              >
                Previous
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= totalPages - 1}
              >
                Next
              </Button>
              <Button
                onClick={() => handlePageChange(totalPages - 1)}
                disabled={currentPage >= totalPages - 1}
              >
                Last
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" mr={3}>
              Page Size:
            </Typography>
            <FormControl variant="outlined" size="small">
              <NativeSelect value={pageSize} onChange={handlePageSizeChange}>
                <option value={1}>1</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </NativeSelect>
            </FormControl>
          </Box>
          <Typography variant="subtitle2" mx={2}>
            Page {currentPage + 1} of {totalPages}
          </Typography>
          <Button
            onClick={() => handlePageChange(0)}
            disabled={currentPage === 0}
          >
            First
          </Button>
          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
          >
            Previous
          </Button>
          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= totalPages - 1}
          >
            Next
          </Button>
          <Button
            onClick={() => handlePageChange(totalPages - 1)}
            disabled={currentPage >= totalPages - 1}
          >
            Last
          </Button>
        </Box>
      )}
    </>
  );
};

export default SearchClaimPagination;
