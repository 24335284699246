import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCommunicationTemplate,
  getCommunicationDetails,
  submitCommunicationDetails,
} from "../../services/api/communication-api-service"; // Import the thunk

const communicationSlice = createSlice({
  name: "communicationSlice",
  initialState: {
    getCommunicationData: null,
    submitCommunicationData: null,
    smsTemplateList: null,
    emailTemplateList: null,
    whatsAppTemplateList: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchCommunicationTemplates
      .addCase(fetchCommunicationTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCommunicationTemplate.fulfilled, (state, action) => {
        state.loading = false;
        console.log("communication slice action", action);
        const { commChannel, payload } = action.meta.arg;
        console.log("communication slice commChannel", commChannel);
        switch (commChannel) {
          case "sms":
            console.log("communication slice commChannel in SMS ");
            state.smsTemplateList = payload;
            break;
          case "email":
            state.emailTemplateList = payload;
            break;
          case "whatsapp":
            state.whatsAppTemplateList = payload;
            break;
          default:
            break;
        }
      })
      .addCase(fetchCommunicationTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })

      //getCommunication details
      .addCase(getCommunicationDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCommunicationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.getCommunicationData = action.payload;
      })
      .addCase(getCommunicationDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })

      //submit communication details
      .addCase(submitCommunicationDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitCommunicationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.submitCommunicationData = action.payload;
      })
      .addCase(submitCommunicationDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default communicationSlice.reducer;
