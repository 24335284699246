import { useEffect, useState } from "react";

/*
LIST - Allow search for list of entities 
VIEW - Allow for viewing of an entity
EDIT - Allow for editing of an existing entity
WRITE - Allow for creation of a new entity
DELETE - Allow for deletion of a new entity
IMPORT - Allow import of an new entity
EXPORT - Allow export of an new entity
*/
export default function usePrivilageForResource(rolesMap, stageName) {
  const [privilageMapForResource, setprivilageMapForResource] = useState({});
  const finalPrivilageMap = new Map();

  useEffect(() => {
    if (rolesMap.size > 0 && stageName !== null) {
      // Create list of permission combinations
      var combinationList = getCombinationOfStages(stageName);

      // Build a map for privilage that exists for give stage
      for (let [key, value] of rolesMap) {
        const found = combinationList.some((item) => value.includes(item));
        if (found) {
          finalPrivilageMap.set(key, found);
        }
      }
      setprivilageMapForResource(finalPrivilageMap);
    }
  }, [rolesMap, stageName]);
  return privilageMapForResource;
}

/* BREAKS A STRING INTO MULTIPLE COMBINATIONS*/
function getCombinationOfStages(inputValue) {
  var delimiter = ".";
  let totalWords = inputValue.split(delimiter);
  let finalCombinations = [];

  for (var i = totalWords.length - 1; i >= 1; i--) {
    var tokens2 = inputValue.split(delimiter).slice(0, i);
    var result2 = tokens2.join(delimiter).concat(".*");
    finalCombinations.push(result2);
  }

  return finalCombinations;
}
