import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useRolesAndPrivileges() {
  const [roleAndPrivilageMap, setRoleAndPrivilageMap] = useState({});
  const rolesList = useSelector((state) => state.authSlice.roles);

  const rolesMap = new Map();

  useEffect(() => {
    // Build a map of action as key and list of resources as value
    for (var key in rolesList) {
      var valList = rolesList[key];
      valList.forEach((item) => {
        item.action.forEach((item2) => {
          if (!rolesMap.has(item2)) rolesMap.set(item2, []);
          const listOfResources = item.resource;
          for (var resource of listOfResources) {
            if (!rolesMap.get(item2).includes(resource)) {
              rolesMap.get(item2).push(resource);
            }
          }
        });
      });
    }
    setRoleAndPrivilageMap(rolesMap);
  }, []);

  return roleAndPrivilageMap;
}