import React from "react";
import "../../../styles/main.scss";
import { Box } from "@mui/material";
import Navbar from "../../components/navbar/narbar.component";
import SignInForm from "./components/signin-form.component";
import SignInInfo from "./components/signin-info.component";
import Footer from "./components/footer.component";

const SignIn = () => {
  return (
    <Box bgcolor={"background.default"} color={"text.primary"}>
      <Navbar />
      <SignInForm />
      <SignInInfo />
    </Box>
  );
};

export default SignIn;
