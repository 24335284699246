import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchClaimList } from "../../../services/api/searchclaims-api-service";
import "rsuite/dist/rsuite.min.css";
import {
  searchClaimFormData,
  searchClaimJSON,
} from "./components/searchclaim-constants";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-material.css";
import { saveAs } from "file-saver";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import SearchClaimHeader from "./components/searchclaim-header.component";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import SnackbarMessage from "ui/components/utils/alert-message.component";
import SearchClaimPagination from "./components/searchclaim-pagination.components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import { CLAIMS_SEARCH_ACCESS } from "ui/components/utils/role-constants";
import useRolesAndPrivileges from "hooks/useRolesAndPermissions";
import PageAccessDenied from "ui/components/page-access-denied/page-access-denied.component";
import { FormatDateUtil } from "utils/formatdate";
import apiClient from "services/DownloaderAPICall";
import { calculatePagination } from "ui/components/utils/utils";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SearchClaim = ({ formDataValues }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertData, setAlertData] = useState({ severity: "info", message: "" });
  const [backdropOpen, setBackdropOpen] = useState(true);
  const [userDetails, setUserDetails] = useState({ id: "", name: "" });

  const gridRef = useRef(null);
  const [selectedColumns, setSelectedColumns] = useState(
    searchClaimJSON.columns.filter((col) => !col.hide).map((col) => col.field)
  );
  const columns = searchClaimJSON.columns;
  const [rows, setRows] = useState([]);
  const [selectedClaimID, setSelectedClaimID] = useState(null); // AgGrid uses 0-based index for pages
  const [gridApi, setGridApi] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10); // Adjust page size as needed
  const [currentPage, setCurrentPage] = useState(0); // AgGrid uses 0-based index for pages

  // Initialize state with default values
  const [formData, setFormData] = useState(searchClaimFormData);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchClaimsLoading = useSelector(
    (state) => state.searchClaimSlice.loading
  );
  const searchClaimsError = useSelector(
    (state) => state.searchClaimSlice.error
  );

  console.log("fetchSearchData: loading", searchClaimsLoading);
  console.log("fetchSearchData: error", searchClaimsError);

  const location = useLocation();
  const { user_id, user_name } = location.state || {};

  const roleAndPrivilageMap = useRolesAndPrivileges();
  const searchClaimAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIMS_SEARCH_ACCESS
  );

  const [userHasViewAccess, setUserHasViewAccess] = useState(false);

  /* CHECK IF PAGE IS COMING FROM DASHBOARD AND CHECK FOR DEFAULT FILTERS */
  useEffect(() => {
    console.log("fetchSearchData: officer", user_id, user_name);

    if (
      user_id &&
      user_name &&
      user_id !== undefined &&
      user_name !== undefined
    ) {
      const updatedList = formData.map((item) =>
        item.api_key === "officer"
          ? {
              ...item,
              api_value: user_id,
              display_value: user_name,
            }
          : item
      );
      setFormData(updatedList);
      setUserDetails({ id: user_id, name: user_name });
    }
  }, [user_id, user_name]);

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    console.log("searchClaimAccessMap ", searchClaimAccessMap);

    if (searchClaimAccessMap.size > 0) {
      if (searchClaimAccessMap.get("READ") === true) {
        setUserHasViewAccess(true);
      }
    }
  }, [searchClaimAccessMap]);

  // Fetch data from API
  const fetchSearchData = useCallback(
    async (page, size, formData) => {
      try {
        console.log("fetchData: formData", formData);
        const requestDataMap = formData.reduce((acc, item) => {
          if (item.api_value !== "" && item.api_value !== null) {
            acc[item.api_key] = item.api_value;
          }
          return acc;
        }, {});

        console.log("fetchData: requestDataMap", requestDataMap);
        var data = { page, size };
        if (Object.keys(requestDataMap).length !== 0) {
          data = { page, size, ...requestDataMap };
        }
        console.log("fetchData: data", data);

        const response = await dispatch(fetchSearchClaimList(data)).then(
          (response) => {
            const searchList = response.payload;
            console.log("fetchData: inside searchList", searchList);
            const rows = searchList.content.map((item) => ({
              claim_unique_id: item.id,
              claim_id: item.claim.id,
              customer_name: item.customer?.info?.name,
              nic: item.claimant?.info?.nid,
              msisdn: item.customer?.info?.msisdn,
              claimant_name: item.claimant?.info?.name,
              //progress: 1,
              contact_no: item.claimant?.info?.msisdn,
              policy: item.policy?.name,
              claim_age: item.claimant?.info?.age,
              start_date: item.claim?.created_at
                ? FormatDateUtil.displayTimeStampAsDate(item.claim?.created_at)
                : "",
              stage: item.claim?.stage.name,
              decision: item.decision?.decision_status?.name,
              decision_payout: item.decision?.approved_payout,
              recommendation: item.verification?.recommendation?.name,
              automatic_claim: item.claim?.is_automatic ? "Yes" : "No",
              payout_date: item.payout?.payout_date
                ? FormatDateUtil.displayDate(item.payout?.payout_date)
                : "",
              followup_date:
                item.call_log?.[item.call_log.length - 1]?.followup_date ?? "",
              end_date: item.claim?.updated_at
                ? FormatDateUtil.displayTimeStampAsDate(item.claim?.updated_at)
                : "",
              call_outcome:
                item.call_log?.[item.call_log.length - 1]?.status ?? "",
              no_of_outbound_calls: item.call_log?.size,
              officers: item.claim?.assigned_to?.name,
            }));
            setRows(rows);
            setTotalRows(searchList.totalElements);
            console.error("Total emements :", searchList.totalElements);
          }
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [dispatch]
  );

  // Fetch data when component mounts or when currentPage/pageSize/formData changes
  useEffect(() => {
    const handler = setTimeout(() => {
      if (userDetails.id && userDetails.name) {
        console.log("Debounced API call");
        fetchSearchData(currentPage, pageSize, formData);
      } else {
        fetchSearchData(currentPage, pageSize, formData);
      }
    }, 100);

    return () => {
      clearTimeout(handler); // Cleanup the timeout if dependencies change
    };
  }, [fetchSearchData, formData, currentPage, pageSize, userDetails]);

  // Show loader when we call API
  useEffect(() => {
    if (searchClaimsLoading) {
      setBackdropOpen(true);
    } else {
      setBackdropOpen(false);
    }
  }, [searchClaimsLoading]);

  // Error handler
  useEffect(() => {
    if (searchClaimsError) {
      setAlertOpen(true);
      setAlertData((prevState) => ({
        ...prevState,
        severity: "error",
        message: "Something went wrong pls try again later",
      }));
    } else {
      setAlertOpen(false);
    }
  }, [searchClaimsError]);

  // Add filter items
  const handleFormDataValueChange = useCallback((newData) => {
    console.log("handleDataChange: in main  ", newData);
    setFormData(newData);
  }, []);

  const handleClaimIDClick = (claimId) => {
    console.log("handleClaimIDClick :", claimId);
    if (claimId !== "") {
      navigate(`/claim/${claimId}`);
    }
  };

  // Attach the handleClaimIDClick function to the onClaimIDClicked event of searchClaimJSON
  searchClaimJSON.columns[1].cellRendererParams.onClaimIDClicked =
    handleClaimIDClick;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleColumnChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColumns(value);
    if (gridApi) {
      const newColumns = columns.map((col) => ({
        ...col,
        hide: !value.includes(col.field),
      }));
      gridApi.setColumnDefs(newColumns);
    }
  };

  const onCloseHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < Math.ceil(totalRows / pageSize)) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    const newSize = event.target.value;
    setPageSize(Number(newSize));
    setCurrentPage(0); // Reset to first page
  };

  // const totalPages = Math.ceil(totalRows / pageSize);
  const { totalPages, startIndex, endIndex } = calculatePagination(
    totalRows,
    pageSize,
    currentPage
  );

  const refreshData = (reloadDefaultPage) => {
    console.log("refreshData", reloadDefaultPage);
    // Refresh with default pageSize
    if (reloadDefaultPage) {
      setCurrentPage(0);
      setPageSize(10);
    }
    // Refresh with selected pageSize
    else {
      setCurrentPage(currentPage);
      setPageSize(Number(pageSize));
    }

    fetchSearchData(currentPage, pageSize, formData); // Update the state to trigger re-fetch
  };

  const exportToExcel = async (downloadAll) => {
    setBackdropOpen(true);

    console.log("downloadAll ", downloadAll);

    if (downloadAll) {
      try {
        const response = await apiClient.post(
          `/claim/download`,
          {},
          {
            responseType: "blob",
          }
        );

        if (response.status === 200 && response.data) {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          console.log("Blob content:", await blob.text());
          saveAs(blob, `claims-report-${FormatDateUtil.init(new Date())}.xlsx`);

          setAlertOpen(true);
          setAlertData((prevState) => ({
            ...prevState,
            severity: "success",
            message: "Downloaded succesfully",
          }));
          setBackdropOpen(false);
        } else {
          setAlertOpen(true);
          setAlertData((prevState) => ({
            ...prevState,
            severity: "error",
            message: "Something went wrong pls try again later",
          }));
          setBackdropOpen(false);
        }
      } catch (error) {
        console.log("error", error);

        setAlertOpen(true);
        setAlertData((prevState) => ({
          ...prevState,
          severity: "error",
          message: "Something went wrong pls try again later",
        }));
        setBackdropOpen(false);
      }
    } else {
      console.log("Download : formData", formData);
      const requestDataMap = formData.reduce((acc, item) => {
        if (item.api_value !== "" && item.api_value !== null) {
          acc[item.api_key] = item.api_value;
        }
        return acc;
      }, {});

      console.log("Download : requestDataMap", requestDataMap);
      var data = { page: currentPage, size: pageSize };
      if (Object.keys(requestDataMap).length !== 0) {
        data = { page: currentPage, size: pageSize, ...requestDataMap };
      }
      console.log("Download: data", data);

      const response = await apiClient.post(`/claim/download`, data, {
        responseType: "blob",
      });

      console.log("Download : formData", response);

      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        console.log("Blob content:", await blob.text());
        saveAs(blob, `claims-report-${FormatDateUtil.init(new Date())}.xlsx`);

        setAlertOpen(true);
        setAlertData((prevState) => ({
          ...prevState,
          severity: "success",
          message: "Downloaded succesfully",
        }));
        setBackdropOpen(false);
      } else {
        setAlertOpen(true);
        setAlertData((prevState) => ({
          ...prevState,
          severity: "error",
          message: "Something went wrong pls try again later",
        }));
        setBackdropOpen(false);
      }
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: false,
      resizable: true,
      cellClass: "ag-left-align",
      cellStyle: () => ({
        alignItems: "left",
        justifyContent: "left",
        fontSize: `14px`,
      }),
    };
  }, []);

  const onSelectionChanged = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      const selectedRows = gridRef.current.api.getSelectedRows();
      console.log("Selected Rows:", selectedRows);

      var claimIDList = [];

      {
        selectedRows !== null &&
          selectedRows.map((item) => claimIDList.push(item.claim_unique_id));
      }
      console.log("Selected Rows: list", claimIDList);
      setSelectedClaimID(claimIDList);
    }
  }, []);

  if (!backdropOpen && !userHasViewAccess) {
    return <PageAccessDenied />;
  }

  return (
    <div>
      <Box minHeight="95vh" sx={{ bgcolor: "#f2f6fc" }}>
        <div
          style={{
            width: "100%",
            paddingTop: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          {/* Loader */}
          <CommonBackDrop backdropOpen={backdropOpen} />

          {/* SnackBar */}
          {alertOpen && (
            <SnackbarMessage
              severity={alertData.severity}
              message={alertData.message}
              onOpen={alertOpen}
              onCloseHandler={onCloseHandler}
            />
          )}
          {/* Header */}
          <SearchClaimHeader
            formDataValues={formData}
            handleFormDataValueChange={handleFormDataValueChange}
            selectedColumns={selectedColumns}
            hanldeDownload={exportToExcel}
            handleColumnChange={handleColumnChange}
            columns={columns}
            onRefresh={refreshData}
            selectedClaimID={selectedClaimID}
            pageSize={pageSize}
          />

          {/* Pagination */}

          <SearchClaimPagination
            totalRows={totalRows}
            pageSize={pageSize}
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageSizeChange={handlePageSizeChange}
            handlePageChange={handlePageChange}
            startIndex={startIndex}
            endIndex={endIndex}
          />

          {/* Table */}
          <div
            className={"ag-theme-material"}
            // ref={gridRef}
            style={{ width: "100%", height: "100%" }}
          >
            <AgGridReact
              ref={gridRef}
              columnDefs={columns}
              rowData={rows}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              domLayout="autoHeight"
              rowHeight={50}
              rowSelection="multiple"
              onSelectionChanged={onSelectionChanged}
              suppressRowClickSelection={true}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default SearchClaim;
