import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const getSubscriptionDetailsAPI = createAsyncThunk(
  "subscriptionsSlice/getSubscriptionDetailsAPI",
  async (data, { rejectWithValue }) => {
    const {searchMsisdn, incidentDate} = data;
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/search/subscription/${searchMsisdn}/${incidentDate}`
      });
      console.log("Search Subscription Response", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);