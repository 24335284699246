import React from "react";
import { ShimmerCategoryItems } from "shimmer-effects-react";

const CustomerShimmer = () => {
  return (
    <div>
      <ShimmerCategoryItems loading={true} mode="light">
        <div style={{ display: "flex" }}>
          <img
            style={{ height: "100px", width: "100px" }}
            alt="shimmer-img"
            src="https://images.pexels.com/photos/33109/fall-autumn-red-season.jpg?cs=srgb&dl=pexels-pixabay-33109.jpg&fm=jpg"
          />
          <div style={{ paddingInline: "1em" }}>
            <p>Title</p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque
              ipsam voluptatibus numquam hic cum maiores rerum dolor
              repudiandae, soluta quas, sequi nemo sunt unde voluptates! Hic
              consequuntur molestiae nemo eligendi.
            </p>
            <button
              style={{
                backgroundColor: "gray",
                paddingInline: "20px",
                color: "white",
              }}
            >
              click
            </button>
          </div>
        </div>
      </ShimmerCategoryItems>
    </div>
  );
};

export default CustomerShimmer;
