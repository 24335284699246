import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCall from "services/ApiCall";

export const fetchPaymentHistoryDetails = createAsyncThunk(
  "paymentHistorySlice/fetchPaymentHistoryDetails",
  async (data, { rejectWithValue }) => {
    const {subscription_id, policy_id, platform_name, date_of_incident} = data;
    try {
      const response = await ApiCall({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/search/payment/${subscription_id}/${policy_id}/${platform_name}/${date_of_incident}`
      });
      console.log("Search Payment History Response", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);