import { createSlice } from "@reduxjs/toolkit";
import {
  getConfigAPIService,
  getCallLogConfigAPIService,
} from "../../services/api/getconfig-api-service"; // Import the thunk

const configSlice = createSlice({
  name: "configSlice",
  initialState: {
    marketCode: null,
    stages: [],
    incidentType: [],
    causeOfIncident: [],
    location: [],
    claimantType: [],
    claimantRelationship: [],
    gender: [],
    nationalIdType: [],
    communicationMode: [],
    documentType: [],
    documentChannel: [],
    callOutcomes: [],
    recommendations: [],
    decisions: [],
    decisionOutCome: [],
    decisionRejectedReasons: [],
    decisionPendingReasons: [],
    paymentModes: [],
    paymentBanks: [],
    metadata: [],
    loading: false,
    error: null,

    callType: [],
    callOutCome: [],
    loadingCallLog: false,
    errorCallLog: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConfigAPIService.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getConfigAPIService.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Config Slice payload ", action.payload);

        state.configList = action.payload;
        const response = action.payload.data[0];
        const { claim } = response || {};
        if (claim) {
          state.stages = claim.stages;
          state.incidentType = claim.incident_type;
          state.causeOfIncident = claim.cause_of_incident;
          state.location = claim.location;
          state.claimantType = claim.claimant_type;
          state.claimantRelationship = claim.claimant_relationship;
          state.gender = claim.gender;
          state.nationalIdType = claim.national_id_type;
          state.communicationMode = claim.communication_mode;
          state.documentType = claim.document_type;
          state.documentChannel = claim.document_channel;
          state.callOutcomes = claim.call_outcomes;
          state.recommendations = claim.recommendations;
          state.decisions = claim.decision;
          state.decisionOutCome = claim.decision_outcome;
          state.decisionRejectedReasons = claim.decision_rejected_reason;
          state.decisionPendingReasons = claim.decision_pending_reason;
          state.paymentModes = claim.payment_modes;
          state.paymentBanks = claim.payment_banks;
          state.metadata = claim.metadata;
        }
      })
      .addCase(getConfigAPIService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })

      // CALL LOG
      .addCase(getCallLogConfigAPIService.pending, (state) => {
        state.loadingCallLog = true;
        state.errorCallLog = null;
      })
      .addCase(getCallLogConfigAPIService.fulfilled, (state, action) => {
        state.loadingCallLog = false;
        console.log("Config call log Slice payload ", action.payload);

        state.configList = action.payload;
        const callTypeList = action.payload.data.call_type;
        if (callTypeList) {
          state.callType = callTypeList;
        }
        const callOutComeList = action.payload.data.call_outcome;
        if (callOutComeList) {
          state.callOutCome = callOutComeList;
        }
      })
      .addCase(getCallLogConfigAPIService.rejected, (state, action) => {
        state.loadingCallLog = false;
        state.errorCallLog = action.payload || action.error.message;
      });
  },
});

export default configSlice.reducer;
