import { Lock } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Spacer from "ui/components/utils/spacer";

const ClaimNotReached = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "400px",
        padding: 4,
      }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "50vh" }}
      >
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Lock fontSize="large" />
            <Spacer value={1} />
            {/* <Typography variant="h4">Page Access Denied</Typography> */}
          </div>
          <Spacer value={2} />
          <Typography variant="body1">
            Claim has not reached this stage yet
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClaimNotReached;
