import { Box, Button, Card, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { claimCloseService } from "services/api/claim-closure-api-service";
import CommonBackDrop from "ui/components/utils/backdrop.component";
import { useParams } from "react-router-dom";
import { MESSAGES } from "../../../components/utils/message-constants";
import { getClaimDetailsAPI } from "services/api/getclaimdetails-api-service";
import { CLAIMS_CLOSURE_PAGE_ACCESS } from "ui/components/utils/role-constants";
import usePrivilageForResource from "hooks/usePrivilagesForResource";
import PageAccessDenied from "ui/components/page-access-denied/page-access-denied.component";
import ClaimNotReached from "ui/components/utils/claim-not-reached.component";

const ClaimsClosure = ({
  onNextButtonClick,
  displayAlert,
  roleAndPrivilageMap,
  showLoadingBackdrop,
}) => {
  const dispatch = useDispatch();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [closeRemarks, setCloseRemarks] = useState("");
  const [currentStage, setCurrentStage] = useState(null);

  const [errorRemark, setErrorRemark] = useState(false);

  const [isClaimAlreadyClosed, setIsClaimAlreadyClosed] = useState(false);

  const closureStageAccessMap = usePrivilageForResource(
    roleAndPrivilageMap,
    CLAIMS_CLOSURE_PAGE_ACCESS
  );

  const [userHasViewAccess, setUserHasViewAccess] = useState(false);
  const [userHasListAccess, setUserHasListAccess] = useState(false);
  const [userHasEditAccess, setUserHasEditAccess] = useState(false);
  const [userHasWriteAccess, setUserHasWriteAccess] = useState(false);
  const [userHasImportAccess, setUserHasImportAccess] = useState(false);

  const params = useParams();
  const claimId = params.claimId;

  console.log("Claim id  ", params.claimId);

  // LOADING STATE FOR API CALLS
  const getClaimCloseLoading = useSelector(
    (state) => state.claimClosureSlice.loading
  );
  const claimDetailsLoading = useSelector(
    (state) => state.claimDetailsSlice.loading
  );

  // CLAIM STATE
  const claimDetails = useSelector(
    (state) => state.claimDetailsSlice.claimDetails
  );

  /* GET PRIVILAGES FOR USER FOR THIS PAGE */
  useEffect(() => {
    console.log("closureStageAccessMap ", closureStageAccessMap);

    if (closureStageAccessMap.size > 0) {
      if (closureStageAccessMap.get("READ") === true) {
        setUserHasViewAccess(true);
      }
      if (closureStageAccessMap.get("LIST") === true) {
        setUserHasListAccess(true);
      }
      if (closureStageAccessMap.get("EDIT") === true) {
        setUserHasEditAccess(true);
      }
      if (closureStageAccessMap.get("WRITE") === true) {
        setUserHasWriteAccess(true);
      }
      if (closureStageAccessMap.get("EDIT") === true) {
        setUserHasImportAccess(true);
      }
    }
  }, [closureStageAccessMap]);

  useEffect(() => {
    dispatch(getClaimDetailsAPI({ claimId }));
  }, [dispatch, claimId]);

  useEffect(() => {
    if (claimDetails) {
      setCurrentStage(claimDetails.claim?.stage.name ?? null);
      console.log("Closed stage -> claimDetails", claimDetails);
      if (claimDetails?.claim?.stage?.name === "Closed") {
        setIsClaimAlreadyClosed(true);
        setCloseRemarks(claimDetails?.closed?.remarks ?? "");
      }
    }
  }, [claimDetails]);

  const handleClick = (e) => {
    console.log("handleClick ", claimId);
    e.preventDefault();
    if (closeRemarks === "") {
      setErrorRemark(true);
      return;
    } else {
      setErrorRemark(false);
      var requestData = {
        remarks: closeRemarks,
      };
      submitClosureAPI(requestData);
    }
  };

  // SHOW LOADERS FOR API CALL
  useEffect(() => {
    if (claimDetailsLoading || getClaimCloseLoading) {
      setBackdropOpen(true);
    } else {
      setBackdropOpen(false);
    }
  }, [claimDetailsLoading, getClaimCloseLoading]);

  // CLosure API CALL
  const submitClosureAPI = async (requestData) => {
    try {
      const data = { claimId, requestData };
      const response = await dispatch(claimCloseService(data));
      const responsePayload = response.payload;
      console.log("submitClosureAPI response ", responsePayload);

      if (responsePayload.status === 200) {
        console.log("submitClosureAPI response ", responsePayload.data);
        displayAlert("success", MESSAGES.SAVED_SUCCESFULLY);
        setIsClaimAlreadyClosed(true);
      } else {
        displayAlert("error", MESSAGES.SAVE_FAILED);
      }
    } catch (error) {
      displayAlert("error", MESSAGES.SAVE_FAILED);
    }
  };

  if (
    params.claimId !== null &&
    params.claimId !== undefined &&
    !backdropOpen &&
    !claimDetailsLoading &&
    !userHasViewAccess
  ) {
    return <PageAccessDenied />;
  }
  if (
    params.claimId === null ||
    params.claimId === undefined ||
    (!claimDetailsLoading &&
      currentStage !== null &&
      (currentStage === MESSAGES.CLAIM_STAGE_FILING ||
        currentStage === MESSAGES.CLAIM_STAGE_UPLOAD_DOC ||
        currentStage === MESSAGES.CLAIM_STAGE_VERIFY_DOC ||
        currentStage === MESSAGES.CLAIM_STAGE_DECISION ||
        currentStage === MESSAGES.CLAIM_STAGE_COMMUNICATION ||
        currentStage === MESSAGES.CLAIM_STAGE_PAYOUT))
  ) {
    return <ClaimNotReached />;
  }

  if (claimDetailsLoading) {
    return <CommonBackDrop backdropOpen={backdropOpen} />;
  }

  if (!claimDetailsLoading) {
    return (
      <Box>
        {/* LOADER */}
        <CommonBackDrop backdropOpen={backdropOpen} />

        {/* CLOSING REMARKS */}
        <Card id="claimantinfo--main__card" sx={{ m: 4, p: 4 }}>
          <Typography variant="h6">Closing Remarks</Typography>
          <Box sx={{ m: 2, p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={isClaimAlreadyClosed ? true : false}
                  error={errorRemark}
                  id="agentnotes__textfield"
                  label="Closing Remarks *"
                  name="agentRemark"
                  multiline
                  rows={4}
                  value={closeRemarks}
                  onChange={(e) => {
                    setCloseRemarks(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </Card>

        {/* NEXT AND PREVIOUS BUTTONS */}

        {!isClaimAlreadyClosed && (
          <Grid container spacing={2}>
            <Grid item xs={6} textAlign="right">
              <Button
                variant="contained"
                size="big"
                disabled={userHasWriteAccess ? false : true}
                onClick={handleClick}
                sx={{ p: 2, m: 4, minWidth: 200 }}
                startIcon={<Save />}
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={6} textAlign="left">
              <Button
                onClick={handleClick}
                variant="contained"
                size="big"
                disabled={userHasWriteAccess ? false : true}
                sx={{ p: 2, m: 4, minWidth: 200 }}
                endIcon={<CheckCircleOutlineIcon />}
              >
                Finish
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
};

export default ClaimsClosure;
