import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";

const ColumnProgressRenderer = React.memo((props) => {
  const value = props.value;
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box width="100%" mr={1} mt={2}>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: 15,
            borderRadius: 5, // Adjust the border-radius for curved corners
            "& .MuiLinearProgress-bar": {
              borderRadius: 5, // Ensure the inner bar also has curved corners
            },
          }}
        />
      </Box>
      <Box width="100%" mr={1} mt={2}>
        <Typography variant="body2" color="primary">{`${value}%`}</Typography>
      </Box>
    </Box>
  );
});

export default ColumnProgressRenderer;
