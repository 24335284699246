import styleds from "styled-components";
import { Dropdown } from "rsuite";
import "rsuite/dist/rsuite.min.css";

export const StyledDropdown = styleds(Dropdown)`
  .rs-dropdown-toggle {
    background-color: #f2f6fc !important; /* Your custom background color */
    border: 1px solid #cecece;
    width: 150px !important; /* Custom width */
    font-size: 17px;
    font-family: 'Arial', sans-serif !important; /* Custom font family */
  }

  .rs-dropdown-item {
    font-size: 16px !important; /* Custom font size */
    min-width:120px
  }
`;

export const DropDownContainer = styleds.div`
  display: flex;
  justify-content: center;
`;
